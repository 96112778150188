import React, {useState} from 'react';
import './styles.css';
import locales_es from '../../locales/es';
import SignaturePad from "../signaturePad";
import APIService from "../../modules/apiService";
import Helpers from "../../modules/helpers";
import Spinner from "../spinner";
import NoSignature from "../../lotties/no-signature";

const SignatureSettings = (props) => {
  const api = new APIService();
  const helpers = new Helpers();


  const [signatureSrc, setEditSignature] = useState(false);
  const [signature, setSignature] = useState(props.userData.full_signature ?? '');

  const loadSignature = () => {
    api.getUserMe().then(res => {
      setSignature(res.data.user?.full_signature ?? '');
    }).catch(err => {
      props.showMainModal(locales_es.errorModal.title, helpers.getErrorMsg(err));
    })
  }

  const deleteProfessionalRegistration = () => {
    props.showMainModal('¿Desea eliminar su firma?', 'Esta acción no se puede deshacer.', null, [
      {
        label: 'Sí, eliminar',
        class: 'btn btn-danger btn-elevate btn-pill btn-sm',
        method: () => {
          api.deleteSignature().then(res => {
            setSignature(null);
            loadSignature();
            props.showMainModal(locales_es.successModal.title, res.message);
          }).catch(err => {
            props.showMainModal(locales_es.errorModal.title, helpers.getErrorMsg(err));
          })
        }
      },
      {
        label: 'No, no eliminar',
        class: 'btn btn-primary btn-elevate btn-pill btn-sm'
      }
    ]);
  }

  const onEndSignatureEdit = (base64) => {
    api.postSignatureImageBase64(base64).then(res => {
      props.showMainModal(locales_es.successModal.title, res.message);
      setSignature(base64);
    }).catch(err => {
      props.showMainModal(locales_es.errorModal.title, helpers.getErrorMsg(err));
    })
  }

  return (
    <>
      <div className="kt-section">
        <div className="kt-section__content  kt-section__content--border">
          <h4 class="kt-font-focus mb-4">Firma</h4>
          <a className="card cursor-pointer d-block" onClick={(e) => {
            e.preventDefault();
            setEditSignature(true);
          }}>
            <div className={`${signature ? 'signatureBgImageSrc' : 'signatureBgNoImageSrc'}`}
                 style={{backgroundImage: signature ? `url(${signature}` : null}}>
              {signature === null ? <Spinner /> : !signature &&
                <NoSignature />
              }
            </div>
            <div className="text-center">
              {!signature && <h4>{locales_es.noSignature}</h4>}
              <p className="mt-4">
                <div className="card-body text-center">
                  <button onClick={(e) => {
                    e.stopPropagation();
                    setEditSignature(true);
                  }}
                          className={`btn ${signature ? 'btn-secondary' : 'btn-focus'}`}>{signature ? 'Editar firma' : 'Crear firma'}</button>
                  {signature && <span onClick={(e) => {
                    e.stopPropagation();
                    deleteProfessionalRegistration();
                  }} className="kt-link kt-link--brand ml-3">Quitar</span>}
                </div>
              </p>
            </div>
          </a>

          {signatureSrc ? (
            <div className="signatureContainer">
              <SignaturePad onSave={onEndSignatureEdit} onTerminate={() => setEditSignature(false)}/>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}

export default SignatureSettings;

