import React, {Component} from 'react';
import locales_es from "../../locales/es";
import Form from "../form";
import xlsIcon from "../../assets/images/xls.svg";
import csvIcon from "../../assets/images/csv.svg";
import pdfIcon from "../../assets/images/pdf.svg";
import {
  EXP_APPOS_CSV,
  EXP_APPOS_PDF,
  EXP_APPOS_XLS,
  USER_TYPE_MEDIC,
  USER_TYPE_SECRETARY
} from "../../models/constants";
import Helpers from "../../modules/helpers";
import ApiService from "../../modules/apiService";
import DateTimeService from "../../modules/DateTimeService";
import Loading from "../loading";
import AuthService from "../../modules/authService";
import ConfigService from "../../modules/configService";
import Spinner from "../spinner";

export default class ModalExportAppointments extends Component {
  constructor(props) {
    super(props);

    this.api = new ApiService();
    this.helpers = new Helpers();
    this.dateTimeService = new DateTimeService();
    this.auth = new AuthService();
    this.configService = new ConfigService();

    const defaultStartDate = new Date();
    defaultStartDate.setMinutes(0);
    defaultStartDate.setHours(defaultStartDate.getHours() + 1);

    // Crear una nueva fecha 15 días antes de `defaultStartDate`
    const fromDate = new Date(defaultStartDate);
    fromDate.setDate(defaultStartDate.getDate() - 10);

    this.state = {
      loading: false,
      toDate: defaultStartDate,
      fromDate: fromDate,
      format: EXP_APPOS_XLS,
      medics: [],
      medicsIds: [],
    };
  }

  componentDidMount() {
    if (this.auth.getLocalUserType() === USER_TYPE_SECRETARY) {
      this.configService.getLocalClinicData().then(clinic => {
        const params = {
          clinic_id: clinic.id,
          show_disabled: true,
        };
        this.api.getMedics(params).then(res => {
          // this.setState({medics: res.data});
          const parsedMedics = res.data.map(medic => ({
            id: medic.id,
            value: medic.id,
            label: `${medic.prefix_name ? (medic.prefix_name + ' ') : ''}${medic.lastname} ${medic.name}`
          }));
          this.setState({
            medics: parsedMedics,
          });
        }).catch(err => {
          this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
        });
      }).catch(err => {
        this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
      });
    }
  }

  setLoading = (bool) => {
    this.setState({loading: bool});
  };

  handleChange(ev) {
    this.setState({owner_str: ev.target.value});
  }

  handleFormChange = state => ev => {
    this.setState({[state]: ev.target.value});
  };

  handleDateChange = state => value => {
    this.setState({[state]: value});
  };

  handleReactSelectChange = state => value => {
    this.setState({[state]: value});
  };

  seletedFormat = (format) => {
    this.setState({format: format});
  };

  onSubmit() {
    this.setLoading(true);
    if (this.auth.getLocalUserType() === USER_TYPE_SECRETARY) {
      if (!this.state.medicsIds.length) {
        this.setLoading(false);
        return this.props.showMainModal(locales_es.errorModal.title, locales_es.noMedicsError);
      }
    }
    this.api.getAppointmentsReports({
      from: this.dateTimeService.parseDateToAPIString(this.state.fromDate),
      to: this.dateTimeService.parseDateToAPIString(this.state.toDate),
      format: this.state.format,
      medics_ids: this.state.medicsIds.map(medic => medic.id),
    }).then(res => {
      this.setLoading(false);
      if (res.data?.url) {
        window.open(res.data.url, '_blank');
        this.props.onConfirm && this.props.onConfirm();
      }
      this.props.showMainModal(
        locales_es.successModal.title,
        `${res.message}${res.data?.url ? '<br /><a href="' + res.data.url + '" target="_blank">Descarga archivo</a>' : ''}`
      );

    }).catch(err => {
      this.setLoading(false);
      this.props.showMainModal(locales_es.infoModal.title, this.helpers.getErrorMsg(err))
    });
  }

  render() {
    const {loading} = this.state;
    const {onAbort} = this.props;

    const dateInputs = [
      {
        label: locales_es.fromDate,
        placeholder: locales_es.fromDate,
        id: 1,
        state: 'fromDate',
        value: this.state.fromDate,
        type: 'date',
        required: true,
        wrapperCustomClassName: 'form-group col-md-6 float-left pr-md-0',
      },
      {
        label: locales_es.toDate,
        placeholder: locales_es.toDate,
        id: 2,
        state: 'toDate',
        value: this.state.toDate,
        type: 'date',
        required: true,
        wrapperCustomClassName: 'form-group col-md-6 float-left pr-md-0',
      }
    ];

    if (this.state.medics.length) {
      dateInputs.push({
        label: locales_es.medics,
        placeholder: locales_es.medics,
        id: 12,
        state: 'medicsIds',
        value: this.state.medicsIds,
        type: 'react-select',
        required: true,
        options: this.state.medics,
        isMulti: true,
        wrapperCustomClassName: 'form-group',
      });
    }


    return (
      <>
        {loading && <Loading/>}
        <div className="woopi-overlay">
          <div className="woopi-overlay-content">
            <div className="row justify-content-center">
              <div className="col-lg-9 text-center">
                <h4 className="m-3">Exportar Turnos</h4>
                <p>Este reporte te permite obtener todos los datos de tus turnos en Medicloud</p>
              </div>
            </div>

            {this.auth.getLocalUserType() === USER_TYPE_MEDIC || (this.auth.getLocalUserType() === USER_TYPE_SECRETARY && this.state.medics.length) ?
              <>
                <div className="row justify-content-center">
                  <Form
                    styles={"d-block"}
                    inputs={dateInputs}
                    handleChange={this.handleFormChange}
                    handleDateChange={this.handleDateChange}
                    handleReactSelectChange={this.handleReactSelectChange}
                  />
                </div>
                <div className="row justify-content-center">
                  <p>Formato de archivo a exportar</p>
                </div>
                <div className="row justify-content-center">
                  <ul className="nav nav-pills nav-tabs-btn nav-pills-btn-brand" role="tablist">
                    <li className="nav-item cursor-pointer">
                      <a onClick={() => this.seletedFormat(EXP_APPOS_XLS)}
                         className={`nav-link ${this.state.format === EXP_APPOS_XLS ? 'active' : ''}`}>
                        <img className="w-100 m-2" src={xlsIcon} style={{maxWidth: '50px'}}/>
                        <span className="nav-link-title">Excel</span>
                      </a>
                    </li>
                    <li className="nav-item cursor-pointer">
                      <a onClick={() => this.seletedFormat(EXP_APPOS_PDF)}
                         className={`nav-link ${this.state.format === EXP_APPOS_PDF ? 'active' : ''}`}>
                        <img className="w-100 m-2" src={pdfIcon} style={{maxWidth: '50px'}}/>
                        <span className="nav-link-title">PDF</span>
                      </a>
                    </li>
                    <li className="nav-item cursor-pointer">
                      <a onClick={() => this.seletedFormat(EXP_APPOS_CSV)}
                         className={`nav-link ${this.state.format === EXP_APPOS_CSV ? 'active' : ''}`}>
                        <img className="w-100 m-2" src={csvIcon} style={{maxWidth: '50px'}}/>
                        <span className="nav-link-title">CSV</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="row justify-content-center mt-3">
                  <div className="col-lg-9 text-center">
                    <button
                      className="btn btn-primary btn-pill m-1"
                      onClick={(e) => {
                        e.preventDefault();
                        this.onSubmit();
                      }}
                    >
                      {locales_es.export}
                    </button>
                  </div>
                </div>
              </>
              : <Spinner/>}
            <div className="row justify-content-center mt-3">
              <div className="col-lg-9 text-center">
                <button
                  className="btn btn-warning btn-pill m-1"
                  onClick={(e) => {
                    e.preventDefault();
                    onAbort && onAbort();
                  }}
                >
                  {locales_es.cancel}
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
