import AuthService from "./authService";
import {USER_TYPE_SECRETARY} from "../models/constants";


class TabsStorage {
  constructor() {
    this.localStorageKey = 'tabData';
    this.auth = new AuthService();
  }

  // Método para obtener datos del localStorage
  get() {
    const data = localStorage.getItem(this.localStorageKey);
    return data ? data : (this.auth.getLocalUserType() === USER_TYPE_SECRETARY ? 'medicalInsurances' : 'medicalRecord');
  }

  // Método para guardar datos en el localStorage
  set(data) {
    const validValues = ['medicalRecord', 'medicalStudies', 'medicalInsurances', 'medicalPrescriptions', 'medicalCertificates', 'appointments', 'chat'];
    if (validValues.includes(data)) {
      localStorage.setItem(this.localStorageKey, data);
    } else {
      console.error(`Valor no válido. Usa ${validValues}.`);
    }
  }
}

export default TabsStorage;
