import React, { useState, useEffect } from 'react';
import { Transition } from 'react-transition-group';

const duracion = 500; // Duración de la transición en milisegundos

const estilosPredeterminados = {
  transition: `opacity ${duracion}ms ease-in-out`,
  opacity: 0, // Asegúrate de que la opacidad inicial sea 0
};

const estilosTransicion = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

const TransitionImage = ({ src, className }) => {
  const [imagenActual, setImagenActual] = useState(src);
  const [cargando, setCargando] = useState(true);

  useEffect(() => {
    // Pre-cargar la nueva imagen
    const img = new Image();
    img.onload = () => {
      // Cuando la imagen está cargada, actualiza el estado y la imagen se mostrará
      setImagenActual(src);
      setCargando(false);
    };
    img.src = src;

    // Setear 'cargando' a true inmediatamente para asegurarse de que la opacidad sea 0
    setCargando(true);
  }, [src]);

  return (
    <Transition in={!cargando} timeout={duracion}>
      {state => (
        <img
          className={className}
          style={{
            ...estilosPredeterminados,
            ...estilosTransicion[state]
          }}
          src={imagenActual}
        />
      )}
    </Transition>
  );
};

export default TransitionImage;
