import React, {Component} from 'react';
import APIService from "../../modules/apiService";
import Helpers from "../../modules/helpers";
import locales_es from "../../locales/es";
import Loading from "../../components/loading";
import AuthService from "../../modules/authService";
import ConfigService from "../../modules/configService";
import {
  DEFAULT_TIME_ZONE, HREF_PAGE_MEDIC,
  HREF_PAGE_MY_INSURANCES,
  hrefRecovery,
  PARSE_TEL_INPUT_ONLY_COUNTRIES,
  USER_TYPE_PATIENT
} from "../../models/constants";
import Form from "../../components/form";
import DateTimeService from "../../modules/DateTimeService";
import TimezoneService from "../../modules/timezoneService";
import intlTelInput from "intl-tel-input";
import Spinner from "../../components/spinner";

export default class NewPatientPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      medicIdOrSlugname: props.match.params.medicId,
      medic: null,
      userType: null,
      clinicId: null,
      showWelcome: true,
      loading: false,
      name: '',
      lastname: '',
      email: '',
      email_repeat: '',
      password: '',
      password_confirmation: '',
      loginMode: false,
      user_type: USER_TYPE_PATIENT, /* ACTUALMENTE SOLO REGISTRA PACIENTES */
      identificationOptions: [],
      genderOptions: [],
      timezoneOptions: [
        { value: 0, label: locales_es.loading },
      ],
      timezone: DEFAULT_TIME_ZONE,
      medicalInsurancesOptions: [],
      medicalInsuranceId: '',
      medicalInsurancePlan: '',
      medicalInsuranceNumber: '',
      errors: [],
      formFlag: null,
    };

    this.api = new APIService();
    this.helpers = new Helpers();
    this.auth = new AuthService();
    this.configService = new ConfigService();
    this.dateTimeService = new DateTimeService();
    this.timezoneService = new TimezoneService();
  }

  componentDidMount() {
    let promise;
    if (isNaN(this.state.medicIdOrSlugname)) {
      promise = this.api.getMedicBySlugname;
    } else {
      promise = this.api.getMedicById;
    }
    promise(this.state.medicIdOrSlugname).then(res => {
      this.setState({
        medic: res.data
      });
    }).catch(err => {
      this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
      this.setState({
        medic: false
      });
    });

    this.configService.getLocalClinicData().then(clinic => {
      this.setState({
        clinicId: clinic.id || null
      })
    }).catch(err => {
      console.log(err)
    });
  }

  loadPatientFormData() {
    this.api.getIdentificationTypes().then(res => {
      this.setState({
        identificationOptions: res.data,
        identification_type_id: res.data[0].id
      })
    }).catch(err => {
      this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
    });
    this.api.getGenders().then(res => {
      this.setState({
        genderOptions: res.data,
        gender_id: res.data[0].id
      })
    }).catch(err => {
      this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
    });

    this.timezoneService.getRemoteParsedTimezones().then(res => {
      this.setState({
        timezoneOptions: res,
        timezone: res.filter(tz => tz.value === DEFAULT_TIME_ZONE)[0]
      })
    }).catch(err => {
      this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
    });

    this.api.getMedicalInsurances().then(res3 => {
      const medicalInsurancesOptions = res3.data.map(item => {
        item.label = item.name;
        item.value = item.id;
        return item;
      });
      const noMedicalInsuranceMock = {id: 0, label: locales_es.noMedicalInsurance, value: 0, is_none: true};
      medicalInsurancesOptions.unshift(noMedicalInsuranceMock);
      this.setState({
        medicalInsurancesOptions,
        medicalInsuranceId: noMedicalInsuranceMock,
      });
    }).catch(err => {
      this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
    });

    this.parseTelInputs();

    this.setState({showWelcome: false})
  }

  parseTelInputs() {
    // TODO Improve
    setTimeout(() => {
      const input = document.querySelector(".cellphoneInput");
      intlTelInput(input, {
        // any initialisation options go here
        initialCountry: 'AR',
        preferredCountries: PARSE_TEL_INPUT_ONLY_COUNTRIES,
      });
    }, 1500);
  }

  handleChange = state => ev => {
    this.setState({[state]: ev.target.value});
  };

  handleDateChange = state => value => {
    this.setState({[state]: value});
  };

  handleReactSelectChange = state => value => {
    this.setState({[state]: value});
  };

  validateLoginForm() {
    return !this.state.email || !this.state.password
      ? this.props.showMainModal('Error', 'Complete todos los campos del formulario')
      : true;
  }

  showForm(formFlag){
    this.setState({
      formFlag
    })
  }

  login() {
    if (this.validateLoginForm()) {
      this.setLoading(true);
      this.auth.login(this.state.email, this.state.password, this.state.clinicId)
        .then(() => {
          const userData = this.auth.getUserData();
          this.linkPatientToMedic(userData.user);
        }).catch(err => {
        console.log(err);
        this.props.showMainModal(locales_es.errorModal.title, err.message);
        this.setLoading(false);
      });
    }
  }

  linkPatientToMedic(user) {
    this.api.postLinkUserToMedic({
      medic_id: this.state.medic.id,
      patient_id: user.id,
    }).then(res => {
      this.props.showMainModal(locales_es.successModal.title, res.message);
      this.props.history.push(`${HREF_PAGE_MY_INSURANCES}?referer=${locales_es.newPatientURL}`);
    }).catch(err => {
      this.props.showMainModal(locales_es.infoModal.title, this.helpers.getErrorMsg(err));
      this.setLoading(false);
    })
  }

  setLoading(bool) {
    this.setState({
      loading: bool
    });
  }

  validateForm() {
    let errors = 0;
    if (!this.state.name
      || !this.state.lastname
      || !this.state.cellphone
      || !this.state.identification_type_id
      || !this.state.identification
      || !this.state.date_of_birth
      || !this.state.gender_id
    ) {
      errors += 1;
      this.props.showMainModal(locales_es.errorModal.title, locales_es.errorModal.completeAllFormFields);
    }

    if (!this.state.timezone) {
      errors += 1;
      this.props.showMainModal(locales_es.errorModal.title, locales_es.errorModal.checkTimeonze);
    }

    return !errors;
  }

  send() {
    if (this.validateForm()) {
      this.setLoading(true);

      const objData = JSON.parse(JSON.stringify(this.state));

      const itiFlag = document.querySelector('.iti__selected-flag');
      if (itiFlag) {
        const itiRegex = /\+\d+/gm;
        if (itiRegex.test(itiFlag.title)) {
          const prefix = itiFlag.title.match(itiRegex)[0];
          objData.cellphone = prefix + ' ' + objData.cellphone;
        }
      }

      objData.medic_id = this.state.medic.id;

      objData.date_of_birth = this.dateTimeService.parseStringDateToAPIStringDate(
        this.dateTimeService.parseDateToConventionalAPIString(this.state.date_of_birth)
      );

      objData.time_zone = this.state.timezone.value;

      if (this.state.medicalInsuranceId && !this.state.medicalInsuranceId.is_none) {
        objData.medical_insurance_id = this.state.medicalInsuranceId.id;
        objData.medical_insurance_plan = this.state.medicalInsurancePlan;
        objData.medical_insurance_number = this.state.medicalInsuranceNumber;
      }

      // Limpio el objeto
      delete objData.genderOptions;
      delete objData.identificationOptions;
      delete objData.medicalInsurancesOptions;
      delete objData.timezoneOptions;

      if (this.state.email) {
        this.api.patientWebForm(objData)
          .then((res) => {
            this.props.showMainModal(locales_es.successModal.title, res.message);
            this.auth.login(objData.email, objData.password, objData.clinicId).then(() => {
              this.props.history.push(`${HREF_PAGE_MY_INSURANCES}?referer=${locales_es.newPatientURL}`);
              this.setLoading(false);
            }).catch(err => {
              this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
              this.setLoading(false);
            });
          }).catch(err => {
          this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
          this.setLoading(false);
        });
      } else {
        this.api.patientWebForm(objData)
          .then((res) => {
            this.props.showMainModal(locales_es.successModal.title, res.message);
            this.props.history.push(`${HREF_PAGE_MEDIC}/${this.state.medicIdOrSlugname}`);
            this.setLoading(false);
          }).catch(err => {
          this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
          this.setLoading(false);
        });
      }

    }
  }

  removeFromErrors(string) {
    let errors = JSON.parse(JSON.stringify(this.state.errors));
    errors = errors.filter(item => item !== string);
    this.setState({
      errors,
    });
  }

  render() {
    const {loading, medic, showWelcome, errors} = this.state;

    const classNameError = this.props.classNameError;

    const inputsLogin = [
      {
        label: locales_es.email_address,
        placeholder: locales_es.email_address,
        id: '1',
        state: 'email',
        value: this.state.email,
        type: 'email',
        required: true
      },
      {
        label: locales_es.password,
        placeholder: locales_es.password,
        id: '2',
        state: 'password',
        value: this.state.password,
        type: 'password',
        required: true
      },
    ];

    const inputsRegister = [
      {
        label: locales_es.name,
        placeholder: locales_es.name,
        autoComplete: 'given-name',
        id: 1,
        state: 'name',
        value: this.state.name,
        type: 'text',
        required: true,
        wrapperCustomClassName: 'form-group col-md-6 float-left pl-md-0',
      },
      {
        label: locales_es.lastname,
        placeholder: locales_es.lastname,
        autoComplete: 'family-name',
        id: 2,
        state: 'lastname',
        value: this.state.lastname,
        type: 'text',
        required: true,
        wrapperCustomClassName: 'form-group col-md-6 float-left pr-md-0',
      },
      {
        label: locales_es.email_address,
        placeholder: locales_es.email_address,
        autoComplete: 'email',
        id: 3,
        state: 'email',
        value: this.state.email,
        type: 'email',
        required: false,
        wrapperCustomClassName: 'form-group col-md-6 float-left pl-md-0',
      },
      {
        label: locales_es.repeatEmail,
        placeholder: locales_es.repeatEmail,
        autoComplete: 'email',
        id: 4,
        state: 'email_repeat',
        value: this.state.email_repeat,
        type: 'email',
        required: false,
        wrapperCustomClassName: 'form-group col-md-6 float-left pr-md-0',
      },
      {
        label: locales_es.password,
        placeholder: locales_es.password,
        autoComplete: 'off',
        id: 5,
        state: 'password',
        value: this.state.password,
        type: 'password',
        required: false,
        wrapperCustomClassName: 'form-group col-md-6 float-left pl-md-0',
        advice: locales_es.passwordValidation
      },
      {
        label: locales_es.repeatPassword,
        placeholder: locales_es.repeatPassword,
        autoComplete: 'off',
        id: 6,
        state: 'password_confirmation',
        value: this.state.password_confirmation,
        type: 'password',
        required: false,
        wrapperCustomClassName: 'form-group col-md-6 float-left pr-md-0',
      },
      {
        label: locales_es.phone_number,
        placeholder: locales_es.phone_number_example,
        autocomplete: 'tel-national',
        id: 7,
        state: 'cellphone',
        value: this.state.cellphone,
        type: 'tel',
        required: true,
        wrapperCustomClassName: 'form-group col-12 col-md-4 float-left pl-md-0 clear',
        customClassName: 'cellphoneInput',
        children: <><p className="pt-1">Ej. Argentina: <strong>9</strong> 11 12345678</p></>
      },
      {
        label: locales_es.identificationType,
        placeholder: locales_es.identificationType,
        autoComplete: 'off',
        id: 8,
        state: 'identification_type_id',
        value: this.state.identification_type_id,
        type: 'select',
        required: true,
        options: this.state.identificationOptions,
        wrapperCustomClassName: 'form-group col-4 col-md-3 float-left pl-md-0',
      },
      {
        label: locales_es.identification,
        placeholder: locales_es.number,
        autoComplete: 'off',
        id: 9,
        state: 'identification',
        value: this.state.identification,
        type: 'text',
        required: true,
        wrapperCustomClassName: 'form-group col-8 col-md-5 float-left pr-md-0',
      },
      {
        label: locales_es.date_of_birth,
        placeholder: locales_es.date_of_birth_mask,
        autoComplete: 'off',
        id: 10,
        state: 'date_of_birth',
        value: this.state.date_of_birth,
        type: 'date',
        required: true,
        wrapperCustomClassName: 'form-group col-md-6 float-left pl-md-0 clear',
        maxDate: new window.Date().getTime(),
      },
      {
        label: locales_es.autoperceivedGender,
        placeholder: locales_es.autoperceivedGender,
        autoComplete: 'off',
        id: 11,
        state: 'gender_id',
        value: this.state.gender_id,
        type: 'select',
        required: true,
        options: this.state.genderOptions,
        wrapperCustomClassName: 'form-group col-md-6 float-left pr-md-0',
      },
      {
        label: locales_es.timezone,
        placeholder: locales_es.timezone,
        autoComplete: 'off',
        id: 12,
        state: 'timezone',
        value: this.state.timezone,
        type: 'react-select',
        required: true,
        options: this.state.timezoneOptions,
        wrapperCustomClassName: 'form-group col-md-6 float-left pl-md-0',
      },
      {
        label: locales_es.medicalInsurance,
        placeholder: locales_es.medicalInsurance,
        onBlur: () => this.removeFromErrors('medicalInsuranceId'),
        id: 9,
        state: 'medicalInsuranceId',
        value: this.state.medicalInsuranceId,
        type: 'react-select',
        required: true,
        options: this.state.medicalInsurancesOptions,
        wrapperCustomClassName: 'form-group col-12 col-md-4 float-left pl-md-0 clear',
        customClassName: (errors.includes('medicalInsuranceId') && classNameError),
      },
      {
        label: locales_es.medicalInsurancePlan,
        placeholder: locales_es.medicalInsurancePlan,
        onBlur: () => this.removeFromErrors('medicalInsurancePlan'),
        id: 10,
        state: 'medicalInsurancePlan',
        value: this.state.medicalInsurancePlan,
        required: !(this.state.medicalInsuranceId.is_none),
        wrapperCustomClassName: 'form-group col-12 col-md-4 float-left pl-md-0',
        customClassName: (errors.includes('medicalInsurancePlan') && classNameError),
      },
      {
        label: locales_es.medicalInsuranceNumber,
        placeholder: locales_es.medicalInsuranceNumber,
        onBlur: () => this.removeFromErrors('medicalInsuranceNumber'),
        id: 11,
        state: 'medicalInsuranceNumber',
        value: this.state.medicalInsuranceNumber,
        required: !(this.state.medicalInsuranceId.is_none),
        wrapperCustomClassName: 'form-group col-12 col-md-4 float-left pl-md-0 clearfix',
        customClassName: (errors.includes('medicalInsuranceNumber') && classNameError),
      },
    ];

    return (
      <>
        {loading && <Loading />}
        <div className={`kt-grid__item ${showWelcome ? 'h-100' : ''}`}>
          {
            medic == null ? <Loading/>
              : medic ?
                showWelcome ?
                  <div className={`kt-portlet kt-profile ${showWelcome ? 'h-100 justify-content-center' : ''}`}>
                    <div className={`kt-profile__content border-0`}>
                      <div className="row">
                        <div className="col-auto m-auto">
                          <h2 class="kt-font-info mb-4 text-center">{locales_es.patientDataModal.title}</h2>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-auto m-auto">
                          <div className="kt-profile__main" style={{border: "none"}}>
                            <div
                              className="kt-profile__main-pic medicloud-user-profile-avatar"
                              style={{backgroundImage: `url(${medic.full_profile_image})`}}/>
                            <div className="kt-profile__main-info">
                              <div
                                className="kt-profile__main-info-name">{medic.prefix_name} {medic.name} {medic.lastname}
                              </div>
                              <div
                                className="kt-profile__main-info-position">{medic.specialty_name}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-auto m-auto text-center p-5">
                          <h4>{medic.prefix_name} {medic.name} {medic.lastname} {locales_es.patientDataModal.content}</h4>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-auto m-auto">
                          <button onClick={() => this.loadPatientFormData()} type="button" className="btn btn-success btn-lg">{locales_es.patientDataModal.setWelcomeFalseButtonText}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  :
                  <div className="container mt-4 p-4">
                    <h2 className="text-center mb-4">{locales_es.hasAPatientUserAlready}</h2>
                  <div className="tab-content overflow-hidden">
                    <div className="tab-pane fade show active" id="kt_tabs_1_1" role="tabpanel">

                      <div className="row">
                        <div
                          className={`col`}>

                          <div className="kt-portlet kt-portlet--height-fluid">
                            <div
                              className="kt-portlet__body kt-portlet__body--fluid kt-portlet__body--fit">
                              <div className="kt-widget-2">
                                    <div class="kt-portlet__body">
                                      <div class="kt-section kt-section--first">
                                        <ul class="nav nav-pills nav-tabs-btn" role="tablist">
                                          <li className="nav-item">
                                            <a className="nav-link" data-toggle="tab" href="#tab-login"
                                               role="tab">
                                              <span class="nav-link-icon"><i class="flaticon2-user"></i></span>
                                              <span class="nav-link-title">Tengo usuario</span>
                                            </a>
                                          </li>
                                          <li className="nav-item">
                                            <a className="nav-link" data-toggle="tab" href="#tab-register" role="tab">
                                              <span class="nav-link-icon"><i class="flaticon2-user-1"></i></span>
                                              <span class="nav-link-title">Quiero registrarme</span>
                                            </a>
                                          </li>
                                        </ul>
                                        <div className="tab-content">
                                          <div className="tab-pane fade" id="tab-login">
                                            <h4 className={"text-center"}>Ya tengo un usuario: inicia sesión</h4>
                                            <Form
                                              style="kt-login-v2__form kt-form"
                                              inputs={inputsLogin}
                                              handleChange={this.handleChange}
                                              onSubmit={() => this.login()}
                                              onSubmitButtonText={locales_es.linkWithMedic}
                                              secondaryButtonText={locales_es.forgotYourPassword}
                                              onClickSecondaryButton={() => {
                                                window.location.href = `${hrefRecovery}?redirect=${window.location.pathname}`;
                                              }}
                                              buttonWrapperStyle={"text-center"}
                                            />
                                          </div>
                                          <div className="tab-pane fade" id="tab-register">
                                            <div class="form-group">
                                              <h4 className={"text-center"}>No tengo un usuario: dejale tus datos</h4>
                                              {this.state.medicalInsurancesOptions.length ? <Form
                                                  styles="kt-form"
                                                  inputs={inputsRegister}
                                                  handleChange={this.handleChange}
                                                  handleDateChange={this.handleDateChange}
                                                  handleReactSelectChange={this.handleReactSelectChange}
                                                  onSubmit={() => this.send()}
                                                  onSubmitButtonText={locales_es.registerDoctorsPatient}
                                                  showTerms={false}
                                                  wrapper={true}
                                                  buttonWrapperStyle={"text-center"}
                                                />
                                                : <Spinner />}
                                            </div>
                                          </div>
                                        </div>
                                        {/*<div
                                          class="kt-separator kt-separator--border-dashed kt-separator--space-lg kt-separator--portlet-fit"></div>*/}
                                      </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>

                    </div>
                  </div>
                  </div>
                :
                <div className="tcenter"> {locales_es.noMedicsAvailable} <br/><br/></div>
          }
        </div>
      </>
    )
  }
}
