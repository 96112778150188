import React, {useEffect, useRef} from "react";
import markerIcon from "../../images/icon-marker.svg";

export const GoogleMaps = ({mapConfig, locationMarker}) => {
  const ref = useRef();

  useEffect(() => {
    // Display the map
    if (ref.current && window.google) {
      const map = new window.google.maps.Map(ref.current, {
        center: mapConfig?.center,
        zoom: mapConfig?.zoom,
        streetViewControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
        // zoomControl: false,
        // maxZoom: 8,
        styles: [
          {
            "featureType": "administrative",
            "elementType": "geometry",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "poi",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "road",
            "elementType": "labels.icon",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "transit",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          }
        ],
      });

      if (locationMarker) {
        const userLocation = new google.maps.Marker({
          position: locationMarker,
          map,
          icon: {
            url: markerIcon,
            // This marker is 20 pixels wide by 32 pixels high.
            scaledSize: new google.maps.Size(50, 50),
            // The origin for this image is (0, 0).
            // origin: new google.maps.Point(0, 0),
            // The anchor for this image is the base of the flagpole at (0, 32).
            // anchor: new google.maps.Point(0, 32),
          },
        });

        const infowindow = new google.maps.InfoWindow({
          content: `<div class="infowindow"><div class="infowindow-header">Aquí está tu lugar de atención</div></div></div>`,
          ariaLabel: "Info",
        });

        userLocation.addListener("click", () => {
          infowindow.open({
            anchor: userLocation,
            map,
          });
        });

        map.setCenter(locationMarker);
      }
    }
  }, [ref, locationMarker]);

  return (<div
    ref={ref}
    className={`map ${locationMarker ? 'mt-3 mb-3' : 'd-none'}`}
  />);
};
