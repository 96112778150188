import React, {Component} from 'react';
import locales_es from "../../locales/es";
import Form from "../form";
import Spinner from "../spinner";
import AmountTypeConfig from "../amountTypeConfig";
import {PAYMENT_METHOD_AMOUNT_TYPE_PERCENT} from "../../models/constants";

export default class PriceModal extends Component {

    constructor(props) {
        super(props);

        const price = this.props.price && this.props.price !== true ? this.props.price : null;

        this.state = {
            before_payment_type: this.props.currentAmountType,
            before_payment_amount: this.props.currentAmount,
            amountTypes: this.props.amountTypes,
            enableBeforePayment: this.props.enableBeforePayment,
            currency_id: this.props.currencies[0].id,
            index: price ? price.index : null,
            id: price ? price.id : null,
            price: price ? price.price : '',
            title: price ? price.title : '',
            description: price ? price.description : '',
            currencies: this.props.currencies,
        }
    }

    handleChange = state => ev => {
        this.setState({[state]: ev.target.value});
    };

    render() {
        const {onSave, onCancel, amountTypes, enableBeforePayment} = this.props;

        const priceTemplate = [
            {
                label: locales_es.price,
                placeholder: locales_es.pricePlaceholder,
                id: 1,
                state: 'currency_id',
                value: this.state.currency_id,
                type: 'select',
                options: this.state.currencies,
                required: true,
                wrapperCustomClassName: 'form-group col-md-6 float-left pl-md-0',
            },
            {
                label: locales_es.price,
                placeholder: locales_es.pricePlaceholder,
                id: 2,
                state: 'price',
                value: this.state.price,
                type: 'number',
                min: 0,
                required: true,
                wrapperCustomClassName: 'form-group col-md-6 float-left pl-md-0',
            },
            {
                label: locales_es.priceTitle,
                placeholder: locales_es.priceTitlePlaceholder,
                id: 3,
                state: 'title',
                value: this.state.title,
                type: 'text',
                required: true,
                wrapperCustomClassName: 'form-group col-md-6 float-left pl-md-0',
            },
            {
                label: locales_es.priceDescription,
                placeholder: locales_es.priceDescriptionPlaceholder,
                id: 4,
                state: 'description',
                value: this.state.description,
                type: 'text',
                required: false,
                wrapperCustomClassName: 'form-group col-md-12 float-left pl-md-0',
            }
        ];

        const onChangeAmountTypeConfig = (amountType) => {
            this.setState({
                before_payment_type: amountType
            })
        };

        const onChangeBeforePaymentAmount = (amount) => {
            this.setState({
                before_payment_amount: Number(amount)
            })
        };

        const validateSave = () => {
            if (this.state.enableBeforePayment) {
                if (!this.state.before_payment_type) {
                    this.props.showMainModal(locales_es.infoModal.title, locales_es.beforePaymentTypeNotSet);
                    return;
                }
                if (!this.state.before_payment_amount) {
                    this.props.showMainModal(locales_es.infoModal.title, locales_es.beforePaymentAmountNotSet);
                    return;
                }
                onSave(this.state);
            } else {
                // Si no está configurado el cobro online, lo seteamos en 100% porcentual por si el médico lo enciende más adelante.
                this.setState({
                    before_payment_type: PAYMENT_METHOD_AMOUNT_TYPE_PERCENT,
                    before_payment_amount: 100,
                }, () => {
                    onSave(this.state);
                })
            }
        }

        return (
            <>
                <div className="woopi-overlay">
                    <div className="woopi-overlay-content">
                        <div className="row justify-content-center">
                            <div className="col-lg-9">
                                <h4 className="mt-3 mb-5 text-center">{locales_es.addPrice}</h4>

                                <Form
                                  inputs={priceTemplate}
                                  handleChange={this.handleChange}
                                  onSubmit={() => {
                                      validateSave()
                                  }}
                                  onSubmitButtonText={locales_es.save}
                                  onClickSecondaryButton={onCancel}
                                  secondaryButtonText={locales_es.cancel}
                                  buttonWrapperStyle="text-center"
                                >
                                    {amountTypes === null ? <Spinner/> :
                                      enableBeforePayment &&
                                      <AmountTypeConfig amountTypes={amountTypes}
                                                        currentAmountType={this.state.before_payment_type}
                                                        currentAmount={this.state.before_payment_amount}
                                                        onChangeBeforePaymentAmount={onChangeBeforePaymentAmount}
                                                        onChangeAmountTypeConfig={onChangeAmountTypeConfig}
                                      />
                                    }
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
