import React, {useEffect, useState} from 'react';
import locales_es from '../../locales/es';
import Helpers from "../../modules/helpers";
import APIService from "../../modules/apiService";
import Spinner from "../spinner";
import MedicineItem from "../medicineItem";
import MedicineStorage from "../medicineStorage";
import MedicineStorageService from "../../modules/MedicineStorageService";

const MedicinesSearch = ({showMainModal, onSelectMedicineOrPresentation, isInvalid, onBlur}) => {
  const api = new APIService();
  const helpers = new Helpers();
  const medicineStorageService = new MedicineStorageService();

  const [showMedicineStorage, setShowMedicineStorage] = useState(false);
  const [query, setQuery] = useState('')
  const [results, setResults] = useState(false)

  const onSearchSubmit = (ev) => {
    if (ev && ev.preventDefault) {
      ev.preventDefault();
    }
    const form = document.getElementById('searchFormMedicines');
    const _query = form.children[0].value;

    setQuery(_query);
  }

  const onSearchClear = (ev) => {
    if (ev && ev.preventDefault) {
      ev.preventDefault();
    }
    const form = document.getElementById('searchFormMedicines');
    form.children[0].value = '';
    setResults(false);
    setQuery('');
  }

  useEffect(() => {
    setTimeout(() => {
      const form = document.getElementById('searchFormMedicines');
      const _query = form.children[0].value;
      if (_query && query.length === _query.length) {
        setResults(null);
        api.searchMedicines({q: _query, reg_no: true}).then(res => {
          setResults(res.data);
        }).catch(err => {
          showMainModal(locales_es.errorModal.title, helpers.getErrorMsg(err));
        })
      } else {
        // setSearchResults([]);
      }
    }, 500); // 800
  }, [query])

  return (
    <div className="kt-portlet__body">
      <form onSubmit={(e) => onSearchSubmit(e)}
            className="kt-input-icon kt-input-icon--right" id="searchFormMedicines">
        <input className={`form-control ${isInvalid ? 'is-invalid' : ''}`}
               type="search"
               onChange={(e) => onSearchSubmit(e)}
               onFocus={() => setShowMedicineStorage(true)}
               onBlur={() => {
                 setTimeout(() => {
                   setShowMedicineStorage(false);
                 }, 500)
                 onBlur && onBlur();
               }}
               placeholder={locales_es.searchMedicines}/>
        {isInvalid ? null : query ?
          <span onClick={(e) => {
            onSearchClear(e);
            onSearchSubmit(e)
          }} className="kt-input-icon__icon kt-input-icon__icon--right">
                                        <span><i className="la la-close"/></span>
                                    </span>
          :
          <span onClick={(e) => onSearchSubmit(e)}
                className="kt-input-icon__icon kt-input-icon__icon--right">
                                    <span><i className="la la-search"/></span>
                                  </span>
        }
      </form>
      {showMedicineStorage && !query &&
        <div className="row">
          <MedicineStorage onSelectPresentation={(medicine, presentation) => {
            onSelectMedicineOrPresentation(medicine, presentation)
            onSearchClear();
          }}/>
        </div>
      }
      <div className="row">
        {results === false ?
          null : results === null ? <Spinner/> : results && results.length ?
            <div className="col pt-3">
              <div className="text-center p-2">
                <a onClick={(e) => {
                  onSearchClear(e);
                }} type="button" className="kt-link kt-font-bold">{locales_es.cleanSearchResults}</a>
              </div>
              {results.map(result => <MedicineItem medication={result}
                                                   onSelectPresentation={(medicine, presentation) => {
                                                     onSelectMedicineOrPresentation(medicine, presentation);
                                                     medicineStorageService.saveMedicine(medicine);
                                                     onSearchClear();
                                                   }}/>)}
            </div> :
            <div className="col text-center">{locales_es.noResults}</div>}
      </div>
    </div>
  );
};

export default MedicinesSearch;
