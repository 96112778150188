import React, {Component} from 'react';
import APIService from "../../modules/apiService";
import Helpers from "../../modules/helpers";
import Spinner from "../spinner";
import DateTimeService from "../../modules/DateTimeService";
import locales_es from "../../locales/es";
import Form from "../form";

export default class ModalInviteUser extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };

    this.api = new APIService();
    this.helpers = new Helpers();
    this.dateTimeService = new DateTimeService();
  }

  componentDidMount() {
    this.api.getPatient(this.props.patientId)
      .then(res => {
        this.setState({
          patientData: res.data,
        })
      })
      .catch(err => {
        this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
      });
  }

  setLoading(bool) {
    this.setState({
      loading: bool
    })
  }

  handleChange = state => ev => {
    this.setState({[state]: ev.target.value});
  };

  handleDateChange = state => value => {
    this.setState({[state]: value});
  };

  handleReactSelectChange = state => value => {
    this.setState({[state]: value});
  };

  validateForm() {
    let errors = 0;
    if (!this.state.email) {
      errors += 1;
      this.props.showMainModal(locales_es.errorModal.title, locales_es.errorModal.completeWithPatientUserEmail);
    }
    return !errors;
  }

  send() {
    if (this.validateForm()) {
      this.setLoading(true);

      const objData = JSON.parse(JSON.stringify(this.state.patientData));

      objData.email = this.state.email;

      this.api.putPatient(this.props.patientId, objData)
        .then(() => {
          this.props.onSubmit && this.props.onSubmit();
          this.setLoading(false);
        }).catch(err => {
        this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
        this.setLoading(false);
      });
    }
  }

  render() {
    const {loading, patientData} = this.state;
    const {onCancel} = this.props;

    const inputs = [
      {
        label: locales_es.email_address,
        placeholder: locales_es.email_address,
        autoComplete: 'email',
        id: 3,
        state: 'email',
        value: this.state.email,
        type: 'email',
        required: true,
        wrapperCustomClassName: 'form-group mt-3',
      }
    ]

    return (
      <>
        <div className="woopi-overlay">
          <div className="woopi-overlay-content container">
            <div className="row justify-content-center">
              <div className="col-lg-9 text-center">
                <h4 className="m-3">Invita a {patientData?.name ?? ''} {patientData?.lastname ?? ''} a registrarse</h4>
                <div>
                  <div>
                    <strong className="d-inline-block mb-3">¿Por qué es importante que tus pacientes tengan usuario en
                      la plataforma?</strong>
                    <span className="d-inline-block mt-2">Es necesario para las videoconsultas en la plataforma</span>
                    <span className="d-inline-block mt-2">Recibe notificaciones y recordatorios por WhatsApp y en su correo electrónico</span>
                    <span className="d-inline-block mt-2">Permite que te comuniques con tus pacientes a través de nuestro chat, centralizando el canal de contacto y evitando que compartas tus datos personales de contacto</span>
                    <span className="d-inline-block mt-2">Permite que los pacientes gestionen sus turnos en la plataforma</span>
                    <div>
                      {loading ? <Spinner/> :
                        <>
                          <Form
                            styles="kt-form"
                            inputs={inputs}
                            handleChange={this.handleChange}
                            handleDateChange={this.handleDateChange}
                            handleReactSelectChange={this.handleReactSelectChange}
                            onSubmit={() => this.send()}
                            onSubmitButtonText={locales_es.send}
                            onClickSecondaryButton={onCancel}
                            secondaryButtonText={locales_es.cancel}
                            showTerms={false}
                          />
                        </>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
