import React, {Component} from 'react';
import AuthService from "../../modules/authService";
import Spinner from "../../components/spinner";
import {
  hrefLogin, STATUS_FAILED,
  STATUS_SUCCESS,
  USER_TYPE_MEDIC,
  USER_TYPE_PATIENT,
  USER_TYPE_SECRETARY
} from "../../models/constants";
import DashboardPatient from "../../components/dashboardPatient";
import DashboardMedic from "../../components/dashboardMedic";
import DashboardSecretary from "../../components/dashboardSecretary";
import ModalAnimationResult from "../../components/modalAnimationResult";
import locales_es from "../../locales/es";
import ModalExportAppointments from "../../components/ModalExportAppointments";
// import WhatsAppButton from "../../components/whatsAppButton";

export default class DashboardPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      result: null,
      showExportModal: false,
    };
    this.auth = new AuthService();

    if (this.props.location &&
      this.props.location.search &&
      window.URLSearchParams &&
      new window.URLSearchParams(this.props.location.search).get("result")) {
      setTimeout(() => {
        this.setState({
          result: new window.URLSearchParams(this.props.location.search).get("result")
        })
      }, 3000);
    }
  }

  componentWillMount() {
    this.checkUserStatus();
  }

  async checkUserStatus() {
    const isLoggedIn = await this.auth.isLoggedUser();
    if (!isLoggedIn) {
      window.location.href = hrefLogin;
    }
  }

  showExportModal() {
    this.setState({showExportModal: true});
  }

  render() {
    const {result, showExportModal} = this.state;
    const userData = this.auth.getUserData();
    return (
      <>
        {/*{(this.auth.getLocalUserType() === USER_TYPE_SECRETARY || this.auth.getLocalUserType() === USER_TYPE_MEDIC) &&*/}
        {(this.auth.getLocalUserType() === USER_TYPE_SECRETARY) &&
          <div className="kt-portlet">
            <div className="kt-portlet__body">
              <div className="kt-portlet__content">
                <div className="row align-items-center">
                  <div className="col">
                    <i className="flaticon-graphic mr-2" style={{fontSize: '30px'}}></i>
                    <span style={{position: 'relative', bottom: '5px'}}> {locales_es.exportAppointmentsReportInfo}</span>
                  </div>
                  <div className="col-auto">
                    <button onClick={(ev) => {
                      ev.preventDefault();
                      this.showExportModal();
                    }} type="button" className="btn btn-brand">
                      {locales_es.export}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        {userData && userData.user ?
          userData.user.user_type === USER_TYPE_PATIENT &&
          <DashboardPatient history={this.props.history} showMainModal={this.props.showMainModal}
                            location={this.props.location}/> ||
          userData.user.user_type === USER_TYPE_SECRETARY &&
          <DashboardSecretary showMainModal={this.props.showMainModal}/> ||
          userData.user.user_type === USER_TYPE_MEDIC &&
          <DashboardMedic location={this.props.location} showMainModal={this.props.showMainModal}/>
          : <Spinner/>}

        {result && (result === STATUS_SUCCESS || result === STATUS_FAILED) ?
          <ModalAnimationResult
            acceptAction={() => this.setState({result: null})}
            result={result}
          />
          : null
        }
        {showExportModal && (
          <ModalExportAppointments
            showMainModal={this.props.showMainModal}
            onAbort={() => this.setState({showExportModal: false})}
            onConfirm={() => {
              this.setState({showExportModal: false});
            }} />
        )}
        {/*<WhatsAppButton showMainModal={this.props.showMainModal} />*/}
      </>
    )
  }
}
