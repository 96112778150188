import React, {Component} from 'react';
import "../../../node_modules/react-datepicker/dist/react-datepicker.css";
import {langCode} from '../../locales/es';
import DatePicker from "react-datepicker";
import {registerLocale} from "react-datepicker";
import es from 'date-fns/locale/es';
import IMask from 'imask';

registerLocale(langCode, es);

const mask = 'dd/MM/yyyy';
const maskWithTime = 'dd/MM/yyyy HH:mm';

export default class FormDatePicker extends Component {
  constructor(props) {
    super(props);

    this.datePickerRef = React.createRef();
    this.mask = null;
  }

  componentDidMount() {
    this.mask = IMask(this.datePickerRef.current.input, this.props.showTimeSelect ?
      {
        mask: Date,
        pattern: 'd/`m/`Y H:`M',
        blocks: {
          d: {
            mask: IMask.MaskedRange,
            from: 1,
            to: 31,
            maxLength: 2,
          },
          m: {
            mask: IMask.MaskedRange,
            from: 1,
            to: 12,
            maxLength: 2,
          },
          Y: {
            mask: IMask.MaskedRange,
            from: 1900,
            to: 9999,
          },
          H: {
            mask: IMask.MaskedRange,
            from: 0,
            to: 23,
            maxLength: 2,
          },
          M: {
            mask: IMask.MaskedRange,
            from: 0,
            to: 59,
            maxLength: 2,
          },
        },
        format: function (date) {
          let day = date.getDate().toString().padStart(2, '0');
          let month = (date.getMonth() + 1).toString().padStart(2, '0');
          let year = date.getFullYear();
          let hours = date.getHours().toString().padStart(2, '0');
          let minutes = date.getMinutes().toString().padStart(2, '0');

          return `${day}/${month}/${year} ${hours}:${minutes}`;
        },
        parse: function (str) {
          const [dateStr, timeStr] = str.split(' ');
          const [day, month, year] = dateStr.split('/');
          const [hours, minutes] = timeStr.split(':');
          return new Date(year, month - 1, day, hours, minutes);
        },
      } : {
      mask: Date,
      pattern: 'd/m/`Y',
      format: function (date) {
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();

        if (day < 10) {
          day = '0' + day;
        }
        if (month < 10) {
          month = '0' + month;
        }

        return [day, month, year].join('/');
      },
      parse: function (str) {
        const dayMonthYear = str.split('/');
        return new Date(
          dayMonthYear[2],
          dayMonthYear[1] - 1,
          dayMonthYear[0]
        );
      },
      // max: new Date()
    });
  }

  render() {
    const {id, minDate, maxDate, value, required, disabled, placeholder, autoComplete, handleChange, customClassName, wrapperCustomClassName, showTimeSelect} = this.props;
    const defaultClassName = 'form-control datePicker ';
    const className = customClassName ? (defaultClassName + customClassName) : defaultClassName;

    const defaultWrapperClassName = 'form-group ';
    const wrapperClassName = wrapperCustomClassName ? (defaultWrapperClassName + wrapperCustomClassName) : defaultWrapperClassName;

    // Establecer el rango de tiempo permitido
    const minTime = new Date();
    minTime.setHours(8, 0); // 08:00 AM
    const maxTime = new Date();
    maxTime.setHours(21, 0); // 20:00 PM

    return (
      <div className={wrapperClassName}>
        {this.props.label && <label>{this.props.label} {this.props.required ? '*' : '(Opcional)'}</label>}
        <div className="input-group date">
          <DatePicker
            className={className}
            key={id}
            id={id}
            locale={langCode}
            selected={value}
            defaultValue={value}
            onChange={handleChange}
            disabled={disabled}
            required={required}
            placeholderText={placeholder}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            // maxDate={new window.Date().getTime()}
            maxDate={maxDate}
            minDate={minDate}
            dateFormat={showTimeSelect ? maskWithTime : mask}
            showTimeSelect={showTimeSelect}
            minTime={showTimeSelect ? minTime : null}
            maxTime={showTimeSelect ? maxTime : null}
            autoComplete={autoComplete || "off"}
            ref={this.datePickerRef}
          />
        </div>
        {this.props.info && <span className="form-text text-muted">{this.props.info}</span>}
      </div>
    );
  }
}
