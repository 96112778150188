import React, {useEffect, useState} from 'react';
import locales_es from '../../locales/es';
import FormNewDatePicker from "../formNewDatePicker";
import './styles.css';

const DatePrescriptionHandler = ({ showMainModal, onChangeDates, singleDate }) => {
  const minDate = new Date();
  const maxDate = new Date().setFullYear(minDate.getFullYear() + 2);

  const [dates, setDates] = useState([minDate]);

  const handleDateChange = (index, date) => {
    if (date) {
      const newDates = [...dates];
      newDates[index] = date;
      setDates(newDates);
    }
  };

  const addDate = () => {
    if (dates.length < 12) {
      const lastDate = new Date(dates[dates.length - 1]);
      lastDate.setMonth(lastDate.getMonth() + 1);
      setDates([...dates, lastDate]);
    }
  };

  const removeLastDate = () => {
    if (dates.length > 1) {
      setDates(dates.slice(0, -1));
    }
  };

  const showInfo = () => {
    showMainModal(locales_es.infoModal.title, locales_es.prescriptionDates, { height: 320 });
  };

  useEffect(() => {
    onChangeDates && onChangeDates(dates);
    console.log('dates');
    console.log(dates);
  }, [dates])

  return (
    <div className="kt-portlet__body">
      <button onClick={showInfo} className="info-button text-info">
        <i className="flaticon2-information" />
      </button>
      <label>{locales_es.date}</label>
      {dates.map((date, index) => (
        <div key={index}>
          <FormNewDatePicker
            value={date}
            type="date"
            handleChange={(newDate) => handleDateChange(index, newDate)}
            autoComplete={false}
            required={true}
            minDate={minDate}
            maxDate={maxDate ?? null}
          />
        </div>
      ))}
      {dates.length > 1 && (
        <button className="btn btn-label-danger btn-pill m-2" onClick={removeLastDate}>{locales_es.removeDate}</button>
      )}
      {!singleDate && dates.length < 12 && (
        <button className="btn btn-label-success btn-pill m-2" onClick={addDate}>{locales_es.addAnotherDate}</button>
      )}
    </div>
  );
};

export default DatePrescriptionHandler;
