import React, {Component} from "react";
import APIService from "../../modules/apiService";
import Helpers from "../../modules/helpers";
import Spinner from "../../components/spinner";
import locales_es from "../../locales/es";
import AuthService from "../../modules/authService";
import {
  HREF_PAGE_DOCUMENT_VISOR,
  HREF_PAGE_HOME,
  HREF_PAGE_PATIENT,
  HREF_PAGE_CERTIFICATES,
  PRESCRIPTION_CERTIFICATE_TYPE,
  USER_TYPE_PATIENT,
} from "../../models/constants";
import DateTimeService from "../../modules/DateTimeService";
import Subheader from "../../components/subheader";
import ViewHelpers from "../../modules/viewHelpers";
import Lottie from "react-lottie";
import animationData from "../../lotties/no-prescriptions.json";
import pdfIcon from '../../assets/images/pdf.svg';
import {TablePaging} from "../../components/TablePaging";

export default class MyCertificatesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      certificates: null,
    };

    this.api = new APIService();
    this.helpers = new Helpers();
    this.auth = new AuthService();
    this.dateTimeService = new DateTimeService();
    this.viewHelpers = new ViewHelpers();
    this.perPageDefault = 5;
  }

  componentDidMount() {
    if (this.auth.getLocalUserType() !== USER_TYPE_PATIENT) {
      window.location.href = HREF_PAGE_HOME;
      return;
    }

    this.getMyCertificates();

    const root = document.getElementById('root');
    if (this.helpers.isMobile() && root) {
      root.addEventListener('scroll', () => this.handleScroll());
    }
  }

  componentWillUnmount() {
    const root = document.getElementById('root');
    if (this.helpers.isMobile() && root) {
      root.removeEventListener('scroll', () => this.handleScroll())
    }
  }

  handleScroll() {
    const root = document.getElementById('root');
    const { scrollTop, scrollHeight, clientHeight } = root;
    if ((scrollTop + clientHeight >= scrollHeight - 50) && this.state.patients !== null) {
      // you're at the bottom of the page
      if (!this.state.refreshing && this.state.pagingData && this.state.pagingData.next_page_url) {
        this.getMyCertificates(null, this.state.pagingData.next_page_url);
      }
    }
  }

  getMyCertificates(params, pageUrl) {
    this.setState({
      refreshing: true
    }, () => {
      this.api.getMyCertificatesOrPatientCertificates(params, pageUrl, this.props.patientId).then(res => {
        const pagingData = JSON.parse(JSON.stringify(res));
        delete pagingData.data;
        const _certificates = JSON.parse(JSON.stringify(this.state.certificates));
        this.setState({
          certificates: _certificates ? _certificates.concat(res.data) : res.data,
          apiPatients: _certificates ? _certificates.concat(res.data) : res.data,
          pagingData,
          refreshing: false,
        });
      }).catch(err => {
        this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
      });
    })
  }

  loadPrescription(certificate) {
    this.props.history.push(HREF_PAGE_DOCUMENT_VISOR, {
      documents: [{
        pdfSource: {uri: certificate.full_file_url}, certificateId: certificate.id,
      },],
      prescriptionType: PRESCRIPTION_CERTIFICATE_TYPE,
      emails: certificate.patient && certificate.patient?.user && certificate.patient?.user.email ? [certificate.patient?.user.email] : null,
    });
  }

  onChangeTablePaging = (params, pageUrl) => {
    this.setState({
      certificates: null
    }, () => {
      this.getMyCertificates(params, pageUrl);
    })
  }

  render() {
    const {certificates, refreshing} = this.state;

    const animationSize = 150;
    const animationOptions = {
      loop: true, autoplay: true, animationData: animationData, rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };

    return (<>
      <Subheader breadcrumbs={[{
        name: locales_es.otherPrescriptionsTitle, href: HREF_PAGE_CERTIFICATES
      }]}/>
      <>

        {certificates === null ? <Spinner/> : certificates && certificates.length ? <div className="row">
            <div className="col-12">
              {this.helpers.isMobile() ?
                <>
                  <div className="row">
                    {certificates.map((certificate, index) => {
                      return (<div key={`pcard-${index}`} className="col-xl-4 col-lg-6">
                        <div className="kt-portlet">
                          <div className="kt-widget kt-widget--general-2">
                            <div className="kt-portlet__body kt-portlet__body--fit">
                              <a href={`${HREF_PAGE_PATIENT}/${certificate.patient?.id}`} className="kt-widget__top">
                                <div className="kt-media kt-media--lg">
                                  <img src={certificate.patient?.full_profile_image} alt=""/>
                                </div>
                                <div className="kt-widget__wrapper">
                                  <div className="kt-widget__label">
                                    <span className="kt-link kt-link--success">{locales_es.createdCertificate}</span>
                                    <span className="kt-widget__title">
                                    {certificate.patient?.name} {certificate.patient?.lastname}
                                  </span>
                                  </div>
                                </div>
                              </a>
                              <div className="kt-widget__bottom d-block">
                                <div className="row mb-3">
                                  <div className="col">
                                    <div
                                      className="kt-widget__caption">{locales_es.creationDate}:
                                    </div>
                                    {certificate.created_at && <span className="kt-widget__value"
                                                                      dangerouslySetInnerHTML={{
                                                                        __html: this.dateTimeService.parseEventDate(certificate.created_at, true, 'full-string')
                                                                      }}/>}
                                  </div>
                                </div>
                                <div className="row mb-3">
                                  <div className="col-auto">
                                    <a onClick={(e) => {
                                      e.preventDefault();
                                      this.loadPrescription(certificate);
                                    }}>
                                      <img src={pdfIcon} style={{height: 40, border: 0,}}/>
                                    </a>
                                  </div>
                                  <div className="col">
                                    <div
                                      className="kt-widget__caption">{locales_es.prescriptionDate}:
                                    </div>
                                    {certificate.date && <span className="kt-widget__value"
                                                                dangerouslySetInnerHTML={{
                                                                  __html: this.dateTimeService.parseEventDate(this.dateTimeService.parseAPIStringToDate(certificate.date), true)
                                                                }}/>}
                                  </div>
                                </div>
                                <br/>
                                  <div className="kt-widget__actions clear">
                                    <a onClick={(e) => {
                                      e.preventDefault();
                                      this.loadPrescription(certificate);
                                    }}
                                       className="btn btn-success btn-sm btn-bold btn-upper text-white cursor-pointer m-1">{locales_es.seeCertificate}</a>
                                  </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>)
                    })}
                    {refreshing && <Spinner/>}
                  </div>
                </>
                :
                <>
                  <div className="row">
                    <div className="col">
                      <div className="kt-portlet kt-portlet--height-fluid overflow-auto">
                        <table className="woopi-table">
                          <thead>
                          <tr className="text-center">
                            <th>
                              <span>{locales_es.nameAndLastname}</span>
                            </th>
                            <th>
                              <span>{locales_es.creationDate}</span>
                            </th>
                            <th>
                              <span>{locales_es.certificateDate}</span>
                            </th>
                            <th>
                              <span>{locales_es.actions}</span></th>
                          </tr>
                          </thead>
                          <tbody className="kt-datatable__body ps ps--active-y">
                          {certificates.map((certificate, index) => {
                            return (
                              <tr key={`p-${index}`} className="">
                                <td>
                                  <a href={`${HREF_PAGE_PATIENT}/${certificate.patient?.id}`}>
                                    <div className="kt-user-card-v2">
                                      <div className="kt-user-card-v2__pic">
                                        <img src={certificate.patient?.full_profile_image} alt={locales_es.profileImage}/>
                                      </div>
                                      <div className="kt-user-card-v2__details">
                                        <span
                                          className="kt-user-card-v2__name">{certificate.patient?.lastname}, {certificate.patient?.name}</span>
                                        <span className="kt-user-card-v2__email">{locales_es.createdCertificate}</span>
                                      </div>
                                    </div>
                                  </a>
                                </td>
                                <td>
                                  {certificate.created_at && <span className="kt-widget__value"
                                                                    dangerouslySetInnerHTML={{
                                                                      __html: this.dateTimeService.parseEventDate(certificate.created_at, true, 'full-string')
                                                                    }}/>}
                                </td>
                                <td>
                                  <img src={pdfIcon} style={{height: 30, float: 'left', marginRight: 10}}/>
                                  {certificate.date ?
                                    <span className="kt-font-bold kt-widget__value"
                                          dangerouslySetInnerHTML={{
                                            __html: this.dateTimeService.parseEventDate(this.dateTimeService.parseAPIStringToDate(certificate.date), true)
                                          }}/>
                                    : locales_es.notDefined}
                                </td>
                                <td className="text-center">
                                  <a onClick={(e) => {
                                    e.preventDefault();
                                    this.loadPrescription(certificate);
                                  }}
                                     className="btn btn-success btn-sm btn-bold btn-upper text-white cursor-pointer">{locales_es.seeCertificate}</a>
                                </td>
                              </tr>)
                          })}
                          </tbody>
                        </table>
                        {this.state.pagingData && <TablePaging pagingData={this.state.pagingData}
                                                               query={this.state.query}
                                                               onChange={(params, pageUrl) => this.onChangeTablePaging(params, pageUrl)}/>}
                      </div>
                    </div>

                  </div>
                </>
              }

            </div>
          </div> :
          <div className="text-center">
            <Lottie
              options={animationOptions}
              height={animationSize}
              width={animationSize}
            />
            <h3>No tienes otras prescripciones aún.</h3>
          </div>}
      </>
    </>)
  }
}
