import React, {Component} from 'react';
import locales_es from "../../locales/es";
import Form from "../form";

export default class FormEdit extends Component {

  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() {
    this.props.inputs.map(input => {
      if (input.preData) {
        this.setState({
          [input.state]: input.preData
        })
      }
      if (input.type === 'date') {
        this.setState({
          [input.state]: new window.Date().getTime()
        })
      }
    })
  }

  handleChange = state => ev => {
    this.setState({[state]: ev.target.value});
  };

  handleDateChange = state => value => {
    this.setState({[state]: value.getTime()});
  };

  handleReactSelectChange = state => value => {
    this.setState({[state]: value});
  };

  render() {
    const inputs = JSON.parse(JSON.stringify(this.props.inputs.map(input => {
      input.value = this.state[input.state];
      return input;
    })));
    return (
      <>
        <Form
          styles="kt-form"
          inputs={inputs}
          handleChange={this.handleChange}
          handleDateChange={this.handleDateChange}
          handleReactSelectChange={this.handleReactSelectChange}
          onSubmit={() => this.props.onSubmit && this.props.onSubmit(this.state)}
          onSubmitButtonText={this.props.onSubmitButtonText ?? locales_es.send}
          secondaryButtonText={this.props.secondaryButtonText}
          onClickSecondaryButton={this.props.onClickSecondaryButton}
          showTerms={false}
          wrapper={true}
        />
      </>
    )
  }
}
