import React, {Component} from "react";
import APIService from "../../modules/apiService";
import Helpers from "../../modules/helpers";
import Spinner from "../../components/spinner";
import locales_es from "../../locales/es";
import AuthService from "../../modules/authService";
import {
  HREF_PAGE_ADD_PRESCRIPTION_PROFILE,
  HREF_PAGE_HOME,
  USER_TYPE_MEDIC,
} from "../../models/constants";
import DateTimeService from "../../modules/DateTimeService";
import ViewHelpers from "../../modules/viewHelpers";
import NoPrescriptionProfiles from "../../lotties/no-prescription-profiles";
import PrescriptionProfile from "../prescriptionProfile";

export default class PrescriptionProfiles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prescriptionProfiles: null,
    };

    this.api = new APIService();
    this.helpers = new Helpers();
    this.auth = new AuthService();
    this.dateTimeService = new DateTimeService();
    this.viewHelpers = new ViewHelpers();
    this.perPageDefault = 5;
  }

  componentDidMount() {
    if (this.auth.getLocalUserType() !== USER_TYPE_MEDIC) {
      window.location.href = HREF_PAGE_HOME;
      return;
    }

    this.getMyPrescriptionProfiles();
  }

  getMyPrescriptionProfiles() {
    this.api.getMyPrescriptionProfiles().then(res => {
      this.setState({
        prescriptionProfiles: res.data,
      })
    }).catch(err => {
      this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
    });
  }

  onAddPrescriptionProfile() {
    this.props.history.push(HREF_PAGE_ADD_PRESCRIPTION_PROFILE)
  }

  render() {
    const {prescriptionProfiles} = this.state;

    return (<>
      <div className="kt-section mb-5">
        <div className="kt-section__content  kt-section__content--border">
          <h4 class="kt-font-success mb-5">Perfiles de Receta</h4>
          {prescriptionProfiles === null ?
            <Spinner/> :
            prescriptionProfiles && prescriptionProfiles.length ?
              <>
                {prescriptionProfiles.map(profile => <PrescriptionProfile profile={profile}
                                                                          showMainModal={this.props.showMainModal}
                                                                          onRefresh={() => this.getMyPrescriptionProfiles()}/>)}
                <div className="row mt-5">
                  <div className="col text-center">
                    <a href="#" onClick={(ev) => {
                      ev.preventDefault();
                      this.onAddPrescriptionProfile();
                    }}
                       className="btn btn-brand btn-upper btn-bold kt-align-center">
                      <i className="fa fa-plus"/> {locales_es.addPrescriptionProfile}</a>
                  </div>
                </div>

              </>
              :
              <div className="text-center">
                <NoPrescriptionProfiles/>
                <h4>No tienes perfiles de receta</h4>
                <p className="mt-4">
                  <a href="#" onClick={(ev) => {
                    ev.preventDefault();
                    this.onAddPrescriptionProfile();
                  }}
                     className="btn btn-brand btn-upper btn-bold kt-align-center">{locales_es.addPrescriptionProfile}</a>
                </p>

              </div>
          }

        </div>
      </div>
    </>)
  }
}
