import React, {Component} from "react";
import APIService from "../../modules/apiService";
import Helpers from "../../modules/helpers";
import Spinner from "../../components/spinner";
import locales_es from "../../locales/es";
import AuthService from "../../modules/authService";
import {
  HREF_PAGE_ADD_PROFESSIONAL_REGISTRATION,
  HREF_PAGE_HOME,
  USER_TYPE_MEDIC,
} from "../../models/constants";
import DateTimeService from "../../modules/DateTimeService";
import ViewHelpers from "../../modules/viewHelpers";
import NoProfessionalRegistrations from "../../lotties/no-professional-registrations";

export default class ProfessionalRegistrations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      professional_registrations_jurisdictions: null,
      professional_registrations_types: null,
      professional_registrations: null,
    };

    this.api = new APIService();
    this.helpers = new Helpers();
    this.auth = new AuthService();
    this.dateTimeService = new DateTimeService();
    this.viewHelpers = new ViewHelpers();
    this.perPageDefault = 5;
  }

  componentDidMount() {
    if (this.auth.getLocalUserType() !== USER_TYPE_MEDIC) {
      window.location.href = HREF_PAGE_HOME;
      return;
    }

    this.getProfessionalRegistrationJurisdictions();
    this.getProfessionalRegistrationsTypes();
    this.getMyProfessionalRegistrations();
  }

  getProfessionalRegistrationJurisdictions() {
    this.api.getProfessionalRegistrationJurisdictions().then(res => {
      this.setState({
        professional_registrations_jurisdictions: res.data,
      })
    }).catch(err => {
      this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
    });
  }

  getProfessionalRegistrationsTypes() {
    this.api.getProfessionalRegistrationsTypes().then(res => {
      this.setState({
        professional_registrations_types: res.data,
      })
    }).catch(err => {
      this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
    });
  }

  getMyProfessionalRegistrations() {
    this.api.getMyProfessionalRegistrations().then(res => {
      this.setState({
        professional_registrations: res.data,
      })
    }).catch(err => {
      this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
    });
  }

  onAddNewProfessionalRegistration() {
    this.props.history.push(HREF_PAGE_ADD_PROFESSIONAL_REGISTRATION)
  }

  deleteProfessionalRegistration(registrationId) {
    this.props.showMainModal('¿Desea eliminar a esta matrícula?', 'Esta acción no se puede deshacer.', null, [
      {
        label: 'Sí, eliminar',
        class: 'btn btn-danger btn-elevate btn-pill btn-sm',
        method: () => {
          this.api.deleteProfessionalRegistration(registrationId).then(res => {
            this.getMyProfessionalRegistrations();
            this.props.showMainModal(locales_es.successModal.title, res.message);
          }).catch(err => {
            this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
          })
        }
      },
      {
        label: 'No, no eliminar',
        class: 'btn btn-primary btn-elevate btn-pill btn-sm'
      }
    ]);
  }

  render() {
    const {
      professional_registrations_jurisdictions,
      professional_registrations_types,
      professional_registrations
    } = this.state;

    const renderRegistration = (registration) => {
      const registerType = professional_registrations_types.filter(type => type.id === registration.professional_registration_type_id);
      const jurisdiction = professional_registrations_jurisdictions.filter(type => type.id === registration.professional_registration_jurisdiction_id);
      if (registerType && registerType.length && jurisdiction && jurisdiction.length) {
        const regType = registerType[0];
        const jur = jurisdiction[0];
        return <div className="kt-widget-17__item">
          <div className="kt-widget-17__product">
            <div className="kt-widget-17__thumb">
              <a className="woopi-kt-widget-17__thumb" onClick={(e) => {
                e.preventDefault();
                this.props.history.push(`${HREF_PAGE_ADD_PROFESSIONAL_REGISTRATION}/${registration.id}`)
              }
              }>
                <i className="flaticon-profile-1"/>
              </a>
            </div>
            <div className="kt-widget-17__product-desc">
              <div className="kt-widget-17__title">
                Tipo de Matrícula: {regType.name}
              </div>
              <div className="kt-widget-17__sku">
                Jurisdicción: {jur.name}
              </div>
              <div className="kt-widget-17__sku">
                Nº {registration.number}
              </div>
            </div>
            <div className="kt-widget-17__prices">
              <div className="kt-widget-17__unit">
                <button
                  onClick={() => this.props.history.push(`${HREF_PAGE_ADD_PROFESSIONAL_REGISTRATION}/${registration.id}`)}
                  type="button"
                  className="btn btn-warning btn-elevate btn-circle btn-icon m-1"><i class="la la-pencil"/>
                </button>
                <button onClick={() => this.deleteProfessionalRegistration(registration.id)} type="button"
                        className="btn btn-danger btn-elevate btn-circle btn-icon m-1"><i class="la la-trash"/>
                </button>
              </div>
            </div>
          </div>
        </div>
      }
    };

    return (<>
      <div className="kt-section">
        <div className="kt-section__content  kt-section__content--border">
          <h4 class="kt-font-info mb-5">Matrículas</h4>
          {professional_registrations === null && professional_registrations_types === null && professional_registrations_jurisdictions === null ?
            <Spinner/> :
            professional_registrations && professional_registrations.length && professional_registrations_types && professional_registrations_types.length && professional_registrations_jurisdictions && professional_registrations_jurisdictions.length ?
              <>
                {professional_registrations.map(registration => renderRegistration(registration))}
                <div className="row mt-5">
                  <div className="col text-center">
                    <a href="#" onClick={(ev) => {
                      ev.preventDefault();
                      this.onAddNewProfessionalRegistration();
                    }}
                       className="btn btn-brand btn-upper btn-bold kt-align-center">
                      <i className="fa fa-plus"/> {locales_es.addProfessionalRegistrationJurisdiction}</a>
                  </div>
                </div>

              </>
              :
              <div className="text-center">
                <NoProfessionalRegistrations />
                <h4>{locales_es.noProfessionalRegistrations}</h4>
                <p className="mt-4">
                  <a href="#" onClick={(ev) => {
                    ev.preventDefault();
                    this.onAddNewProfessionalRegistration();
                  }}
                     className="btn btn-info btn-upper btn-bold kt-align-center">{locales_es.addProfessionalRegistrationJurisdiction}</a>
                </p>

              </div>
          }

        </div>
      </div>
    </>)
  }
}
