import React, { Component } from 'react';
import APIService from "../../modules/apiService";
import Helpers from "../../modules/helpers";
import locales_es from "../../locales/es";
import Spinner from "../../components/spinner";
import InfiniteScroll from '../../components/InfiniteScroll/InfiniteScroll';
import Lottie from "react-lottie";
import animationData from "../../lotties/no-medical-records.json";
import DateTimeService from "../../modules/DateTimeService";
import FormEdit from "../formEdit";
import pdfIcon from "../../assets/images/pdf.svg";
import pngIcon from "../../assets/images/png.svg";
import jpgIcon from "../../assets/images/jpg.svg";

export default class MedicalStudies extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      medicalStudies: null,
      medicalStudiesNextPageUrl: null,
      medicalComplementaryStudiesTypes: null,
      complementaryStudyType: null,
    };

    this.api = new APIService();
    this.helpers = new Helpers();
    this.dateTimeService = new DateTimeService();
  }

  componentDidMount() {
    this.getComplementaryStudiesTypes().then(() => {
      this.getMedicalStudies();
    });
  }

  getComplementaryStudiesTypes() {
    return this.api.getComplementaryStudiesTypes().then(types => {
      const studyTypeId = types.data.filter(type => type.name.toLowerCase() === 'otros').map(type => {
        return {
          value: type.id,
          label: type.name,
        };
      });
      this.setState({
        complementaryStudyType: studyTypeId.length ? studyTypeId[0] : null,
        medicalComplementaryStudiesTypes: types.data.map(type => {
          return {
            value: type.id,
            label: type.name,
          };
        }),
      });
    }).catch(err => {
      this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
    });
  }

  getMedicalStudies(pageUrl = null) {
    return this.api.getMedicalStudiesByPatient(this.props.patientId, pageUrl).then(res => {
      this.setState({
        medicalStudies: this.state.medicalStudies && pageUrl ? this.state.medicalStudies.concat(res.data) : res.data,
        medicalStudiesPerPage: res.per_page,
        medicalStudiesNextPageUrl: res.next_page_url,
      });
    }).catch(err => {
      this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
    });
  }

  onAddMedicalStudy() {
    const inputs = [{
      label: locales_es.date,
      placeholder: locales_es.date,
      id: 10,
      state: 'medicalStudy__date',
      type: 'date',
      required: false,
      wrapperCustomClassName: 'form-group col-12 col-md-6 float-left',
      maxDate: new window.Date().getTime(),
    }, {
      label: locales_es.medicalStudyType,
      placeholder: locales_es.medicalStudyTypePlaceholder,
      id: 4,
      state: 'medicalStudy_complementary_studies_type_id',
      preData: this.state.complementaryStudyType,
      type: 'react-select',
      required: true,
      options: this.state.medicalComplementaryStudiesTypes,
      wrapperCustomClassName: 'form-group col-12 col-md-6 float-left',
    }, {
      label: locales_es.title,
      placeholder: locales_es.medicalStudyTitlePlaceholder,
      id: 1,
      state: 'medicalStudy_title',
      type: 'text',
      required: true,
      wrapperCustomClassName: 'form-group col-12 clear',
    }, {
      label: locales_es.comments,
      placeholder: locales_es.medicalStudyCommentPlaceholder,
      id: 2,
      state: 'medicalStudy_comment',
      type: 'textarea',
      required: true,
      wrapperCustomClassName: 'form-group col-12',
    }, {
      label: locales_es.file,
      placeholder: locales_es.medicalStudyFilePlaceholder,
      id: 'medicalStudy_file_id',
      state: 'medicalStudy_file',
      type: 'file',
      required: true,
      wrapperCustomClassName: 'form-group col-12',
    }];

    const customStyles = { height: 'auto', maxHeight: 640, overflow: 'auto', maxWidth: '90%' };

    const onSubmit = (inputs) => {
      const input = document.getElementById('medicalStudy_file_id');
      const files = input.files;
      const file = files[0];

      inputs.patient_id = this.props.patientId;
      inputs.date = this.dateTimeService.parseDateToAPIString(new Date(inputs.medicalStudy__date));
      inputs.title = inputs.medicalStudy_title;
      inputs.comment = inputs.medicalStudy_comment ?? '';
      inputs.file = inputs.medicalStudy_file;
      inputs.complementary_studies_type_id = inputs.medicalStudy_complementary_studies_type_id.value;

      this.setLoading(true);
      this.api.postMedicalStudy(file, inputs).then(() => {
        this.props.showMainModal(locales_es.successModal.title, locales_es.successModal.message);
        this.getMedicalStudies();
        this.setLoading(false);
      }).catch(err => {
        alert(this.helpers.getErrorMsg(err));
        this.setLoading(false);
      });
    };

    this.props.showMainModal(locales_es.addMedicalStudy, <FormEdit inputs={inputs} onSubmitButtonText={locales_es.send} onSubmit={onSubmit} />, customStyles, null);
  }

  onRemoveMedicalStudy(recordId) {
    this.props.showMainModal(locales_es.confirmActionModal.title, locales_es.confirmActionModal.subtitle, null, [{
      label: locales_es.confirmActionModal.accept, class: 'btn btn-danger', method: () => {
        this.api.deleteMedicalStudy(recordId).then(res => {
          this.props.showMainModal(locales_es.successModal.title, res.message);
          this.getMedicalStudies();
        }).catch(err => {
          this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
        });
      }
    }, {
      label: locales_es.confirmActionModal.cancel, class: 'btn btn-brand',
    }]);
  }

  setLoading(bool) {
    this.setState({ loading: bool });
  }

  getBadgeStyles(label) {
    const length = label.length;

    // Calculate hue based on length, cycle through 0-360
    const hue = (length * 30) % 360;

    // Calculate saturation and lightness dynamically based on length
    const saturation = Math.min(100, 50 + length * 3);
    const lightness = Math.min(100, 80 - length * 2);

    const backgroundColor = `hsl(${hue}, ${saturation}%, ${lightness}%)`;

    // Calculate text color: if lightness is high, use dark text, otherwise use light text
    const textColor = lightness > 50 ? '#000' : '#fff';

    return {
      backgroundColor,
      color: textColor,
    };
  }

  render() {
    const { loading, medicalStudies, medicalStudiesPerPage, medicalStudiesNextPageUrl } = this.state;

    const animationOptions = {
      loop: true, autoplay: true, animationData: animationData, rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    const animationSize = 150;

    return (
      <>
        {loading ? <Spinner /> : null}
        <div className="kt-portlet kt-portlet--height-fluid">
          <div className="kt-portlet__head">
            <div className="kt-portlet__head-label">
              <h3 className="kt-portlet__head-title">{locales_es.medicalStudies}</h3>
            </div>
            <a onClick={(ev) => {
              ev.preventDefault();
              this.onAddMedicalStudy();
            }} href="#" className="btn btn-label-brand btn-bold align-self-center">
              <i className="flaticon2-add-1" /> {locales_es.addMedicalStudy}
            </a>
          </div>
          <div className="kt-portlet__body">
            <div className="kt-scroll" data-scroll="true">
              {medicalStudies === null ? <Spinner /> : medicalStudies && medicalStudies.length ? (
                <InfiniteScroll
                  dataLength={medicalStudiesPerPage}
                  next={() => this.getMedicalStudies(medicalStudiesNextPageUrl)}
                  hasMore={Boolean(medicalStudiesNextPageUrl)}
                  loader={<Spinner />}
                  endMessage={<p className="text-center">
                    <b>{locales_es.noMoreRecords}</b>
                  </p>}
                >
                  <div className="row m-0">
                    {medicalStudies.map(record => {
                      const recordType = this.state.medicalComplementaryStudiesTypes.filter(type => type.value === record.complementary_studies_type_id);
                      const badgeStyles = recordType.length ? this.getBadgeStyles(recordType[0].label) : {};

                      return (
                        <a href={record.full_file} target="_blank" className="kt-widget-7 col-12 col-md-6 col-lg-4">
                          <div className="kt-widget-7__items">
                            <div className="kt-widget-7__item">
                              <div className="kt-widget-7__item-pic">
                                <img
                                  src={(record.ext === "jpg" || record.ext === "jpeg") ? jpgIcon : record.ext === 'png' ? pngIcon : pdfIcon}
                                  alt=""
                                />
                              </div>
                              <div className="kt-widget-7__item-info">
                                <div className="kt-widget-7__item-desc">
                                  {recordType && recordType.length ?
                                    <span className="badge badge-pill" style={badgeStyles}>{recordType[0].label}</span>
                                    : null}
                                </div>
                                <div className="kt-widget-7__item-title mt-2">
                                  {record.title}
                                  <a onClick={(ev) => {
                                    ev.preventDefault();
                                    this.onRemoveMedicalStudy(record.id);
                                  }} href="#" className="kt-font-danger p-2">
                                    <i className="flaticon2-trash"></i>
                                  </a>
                                </div>
                                <div className="kt-widget-7__item-desc">
                                  <span className="kt-timeline__item-datetime" dangerouslySetInnerHTML={{
                                    __html: this.dateTimeService.parseEventDate(this.dateTimeService.parseAPIStringToDate(record.date), true, 'day') + ' ' + this.dateTimeService.parseEventDate(this.dateTimeService.parseAPIStringToDate(record.date), false, 'month') + ' ' + this.dateTimeService.parseEventDate(this.dateTimeService.parseAPIStringToDate(record.date), true, 'year')
                                  }} /> - {record.comment}
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                      )
                    })}
                  </div>
                </InfiniteScroll>
              ) : (
                <div className="text-center">
                  <Lottie
                    options={animationOptions}
                    height={animationSize}
                    width={animationSize}
                  />
                  <p>{locales_es.medicalStudiesNotFoundYet}</p>
                  <p>
                    <a href="#" onClick={(ev) => {
                      ev.preventDefault();
                      this.onAddMedicalStudy();
                    }} className="btn btn-brand btn-upper btn-bold kt-align-center">
                      {locales_es.addMedicalStudy}
                    </a>
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
