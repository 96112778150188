import React, {useState, useCallback} from 'react';
import Cropper from 'react-easy-crop';
import getCroppedImg from '../cropImage/cropImage';
import './styles.css';

const AddPrescriptionProfileImages = (props) => {
  // const CONTAINER_HEIGHT = 300
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({x: 0, y: 0});
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(props.image || null);

  const onFileChange = async (e) => {
    const input = e.target;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      let imageDataUrl = await readFile(file);
      setImageSrc(imageDataUrl);

      // Resetea el valor del input file
      input.value = '';
    }
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageSrc,
        croppedAreaPixels
      );
      setCroppedImage(croppedImage);
      setImageSrc(null);
      props.onCropComplete && props.onCropComplete(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [imageSrc, croppedAreaPixels]);

  // Lee el archivo seleccionado y lo convierte a base64
  const readFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  };

  const selectImageFile = () => {
    document.getElementById("selectImageInputFile").click();
  }

  const removeImage = (e) => {
    e.stopPropagation();
    setImageSrc(null);
    setCroppedAreaPixels(null)
    setCroppedImage(null);
    props.onCropComplete && props.onCropComplete('');
  }

  const onFileDrop = (file) => {
    // Convierte el archivo en un URL y actualiza el estado
    const fileUrl = URL.createObjectURL(file);
    setImageSrc(fileUrl);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      onFileDrop(event.dataTransfer.files[0]);
    }
  };

  return (
    <>
      <div className="kt-portlet">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title pt-3 pb-3">Imagen de Cabecera del Consultorio o Centro
              Médico</h3>
          </div>
        </div>
        <div className="kt-portlet__body">
          <div className="kt-section">
            <div className="kt-section__info">Esta imagen se imprime en el encabezado de tus recetas,
              certificados y demás documentos que expidas desde la plataforma.
              <br /><br />
              Dimensiones óptimas: 510px x 100px
              <br /><br />
              <strong>
                !Te recomendamos usar
                <a href="https://www.canva.com/design/DAF6ugpvnD8/aWfeQVYH8js1vPEXp1IePA/view?utm_content=DAF6ugpvnD8&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview" target="_blank"> el siguiente template de Canva </a>
                para armar el tuyo y que quede perfecto!
              </strong>
            </div>
          </div>
          <div className="kt-section__content kt-section__content--border">
            <div className="card cursor-pointer" onClick={selectImageFile} onDrop={handleDrop}
                 onDragOver={(e) => e.preventDefault()}>
              <div className={`kt-bg-metal kt-padding-50 ${croppedImage ? 'cardBgImageSrc' : 'cardBgImage'}`}
                   style={{backgroundImage: croppedImage ? `url(${croppedImage}` : null}}/>
              <input id="selectImageInputFile" className="d-none" type="file" onChange={onFileChange}
                     accept="image/*"/>
              <div className="card-body">
                <h5 className="card-title">Encabezado de la receta</h5>
                <p className="card-text">Imagen para el encabezado de tus prescripciones.</p>
                <span
                  className={`btn ${croppedImage ? 'btn-secondary' : 'btn-primary'}`}>{croppedImage ? 'Cambiar imagen' : 'Subir imagen'}</span>
                {croppedImage && <span onClick={removeImage} className="kt-link kt-link--brand ml-3">Quitar</span>}
              </div>
            </div>
          </div>
        </div>
      </div>

      {imageSrc ? (
        <div className="cropContainer">
          <Cropper
            image={imageSrc}
            crop={crop}
            zoom={zoom}
            cropSize={{width: 504, height: 100}}
            aspect={undefined}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
            /*onMediaLoaded={(mediaSize) => {
              // Adapt zoom based on media size to fit max height
              setZoom(CONTAINER_HEIGHT / mediaSize.naturalHeight)
            }}*/
          />
          <div className="controls row">
            <div className="sliderContainer col-lg-6 justify-content-center m-1">
              <span className="d-flex justify-content-center align-items-center btn btn-light btn-hover-brand btn-pill">
                <span className="m-1">Zoom</span>
                <input
                  type="range"
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  aria-labelledby="Zoom"
                  onChange={(e) => setZoom(e.target.valueAsNumber)}
                />
              </span>
            </div>
            <div className="col-lg-6 justify-content-center text-center m-1">
              <button className="btn btn-primary m-1" onClick={showCroppedImage}>Recortar imagen</button>
              <button className="btn btn-warning m-1" onClick={() => setImageSrc(null)}>Cancelar</button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default AddPrescriptionProfileImages;

