import React, { Component } from 'react';
import IMask from 'imask';

export default class CuitInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maskedValue: props.value || '',
    };
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    this.initMask();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.maskedInput.value = this.props.value;
    }
  }

  initMask() {
    if (this.inputRef.current) {
      const maskOptions = {
        mask: '00-00000000-0',
        lazy: true,
      };

      this.maskedInput = IMask(this.inputRef.current, maskOptions);
      this.maskedInput.on('accept', this.handleMaskAccept);
    }
  }

  handleMaskAccept = () => {
    const maskedValue = this.maskedInput.value;
    this.setState({ maskedValue });
    if (this.props.handleChange) {
      this.props.handleChange({ target: { value: maskedValue } });
    }
  };

  handleBlur = (event) => {
    const value = event.target.value;
    if (value.length > 0 && value !== '__-________-_') {
      if (value.length !== 13) {
        alert('El CUIT debe tener el formato 00-00000000-0 y contener 13 caracteres');
      } else if (value.includes('_')) {
        alert('El CUIT no puede contener guiones bajos (_).');
      }
    }
    if (this.props.onBlur) {
      this.props.onBlur(event);
    }
  };

  render() {
    const {
      wrapperCustomClassName,
      id,
      placeholder,
      autoComplete,
      required,
      disabled,
      customClassName,
      label,
      info,
      children,
    } = this.props;
    const { maskedValue } = this.state;
    const defaultClassName = 'form-control ';
    const className = customClassName ? (defaultClassName + customClassName) : defaultClassName;
    const defaultWrapperClassName = 'form-group ';
    const wrapperClassName = wrapperCustomClassName ? (defaultWrapperClassName + wrapperCustomClassName) : defaultWrapperClassName;

    return (
      <div className={wrapperClassName}>
        <label>{label} {required ? '*' : '(Opcional)'}</label><br />
        <input
          className={className}
          type="text"
          value={maskedValue}
          placeholder={placeholder}
          autoComplete={autoComplete}
          key={id}
          id={id}
          required={required}
          disabled={disabled}
          onBlur={this.handleBlur}
          ref={this.inputRef}
        />
        {children}
        {info && <span className="form-text text-muted">{info}</span>}
      </div>
    );
  }
}
