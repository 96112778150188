import React, {Component} from 'react';
import locales_es from "../../locales/es";
import {
    HREF_PAGE_MEDIC_EDIT_NON_WORKING_DAYS,
    HREF_PAGE_MEDIC_EDIT_TIMETABLES, HREF_PAGE_MEDICS,
    USER_TYPE_SECRETARY
} from "../../models/constants";
import APIService from "../../modules/apiService";
import Helpers from "../../modules/helpers";
import ConfigService from "../../modules/configService";
import Modal from "../../components/modal";

export default class MedicAgenda extends Component {

    constructor(props) {
        super(props);

        this.state = {
            modalVisible: false,
            disabled: this.props.medic.disabled,
        };

        this.api = new APIService();
        this.configService = new ConfigService();
        this.helpers = new Helpers();
    }

    unassingMedic() {
        const confirm = window.confirm(locales_es.confirmUnassignMedic);

        if (confirm) {
            this.configService.getLocalClinicData().then(clinic => {
                const objData = {
                    medic_id: this.props.medic.id,
                    clinic_id: clinic.id
                };
                this.api.unassignMedic(objData).then(() => {
                    // this.props.showMainModal(locales_es.successModal.title, res.message);
                    this.setState({
                        modalVisible: true
                    });
                }).catch(err => {
                    this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
                });
            });
        }
    }

    deactivateMedic() {
        const confirm = window.confirm(this.state.disabled ? locales_es.confirmActivateMedic :locales_es.confirmDeactivateMedic);

        if (confirm) {
            const objData = {
                disabled: !this.props.medic.disabled,
            };
            this.api.deactivateMedic(this.props.medic.id, objData).then(() => {
                // this.props.showMainModal(locales_es.successModal.title, res.message);
                this.setState({
                    modalVisible: true,
                    disabled: objData.disabled,
                });
            }).catch(err => {
                this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
            });
        }
    }

    goBack() {
        window.location.href = HREF_PAGE_MEDICS;
        // this.props.history.replace(HREF_PAGE_MEDICS);
    }

    render() {
        const {medic, userType, hideEditAppointments} = this.props;

        /*const href = userType === USER_TYPE_SECRETARY
            ? `${HREF_PAGE_MEDIC_EDIT_TIMETABLES}/${medic.id}`
            : `${HREF_PAGE_DASHBOARD}`;
        const linkText = userType === USER_TYPE_SECRETARY
            ? locales_es.editTimetables
            : locales_es.administrateAppointments;*/
        const href = `${HREF_PAGE_MEDIC_EDIT_TIMETABLES}/${medic.id}`;
        const linkText = locales_es.editTimetables;
        return (
            <>
                <div className="text-center justify-content-center p-3">
                {hideEditAppointments ? null :
                            <a href={href}
                               className="btn m-1 btn-brand btn-upper btn-bold kt-align-center">{linkText}</a>
                }
                        <a href={`${HREF_PAGE_MEDIC_EDIT_NON_WORKING_DAYS}/${medic.id}`}
                           className="btn m-1 btn-info btn-upper btn-bold kt-align-center">{locales_es.editNonWorkingDays}</a>
                {userType === USER_TYPE_SECRETARY &&
                <>
                            <br />
                            <button onClick={() => this.deactivateMedic()}
                                    className="btn m-1 btn-warning btn-upper btn-bold kt-align-center">{this.state.disabled ? locales_es.activateMedic : locales_es.deactivateMedic}</button>
                            <button onClick={() => this.unassingMedic()}
                                    className="btn m-1 btn-danger btn-upper btn-bold kt-align-center">{locales_es.unassignMedic}</button>
                </>
                }
                </div>

                <Modal onClose={() => this.setState({modalVisible: false})}
                       modalId="unassignMedic"
                       title={locales_es.successModal.title}
                       hideCloseButton={true}
                       visible={this.state.modalVisible}
                       actions={[
                           {
                               title: locales_es.accept,
                               onClick: () => this.goBack(),
                               className: 'btn btn-outline-info',
                           }
                       ]}
                >
                    Datos guardados correctamente
                </Modal>
            </>

        )
    }
}
