class CalendarView {
  constructor() {
    this.localStorageKey = 'calendarData';
  }

  // Método para obtener datos del localStorage
  get() {
    const data = localStorage.getItem(this.localStorageKey);
    return data ? data : 'timeGridWeek';
  }

  // Método para guardar datos en el localStorage
  set(data) {
    const validValues = ['timeGridWeek', 'dayGridWeek', 'dayGridMonth', 'timeGridDay'];
    if (validValues.includes(data)) {
      localStorage.setItem(this.localStorageKey, data);
    } else {
      console.error('Valor no válido. Usa "timeGridWeek", "dayGridWeek", "dayGridMonth" o "timeGridDay".');
    }
  }
}

export default CalendarView;
