import React, {Component} from 'react';
import locales_es from "../../locales/es";
import Spinner from "../spinner";
import Button from "./button";
import APIService from "../../modules/apiService";
import Helpers from "../../modules/helpers";

export default class AppointmentScheduled extends Component {

  constructor(props) {
    super(props);

    this.state = {
      bankTransferData: null,
    };

    this.api = new APIService();
    this.helpers = new Helpers();
  }

  componentDidMount() {
    console.log(this.props);
  }

  render() {
    const {paymentMethods, onContinue, onBankTransferClick, onExternalCheckoutClick} = this.props;

    return (
      <>
        <div className="woopi-overlay">
          <div className="woopi-overlay-content">
            <div className="row justify-content-center">
              <div className="col-lg-9 text-center">
                <h4 className="m-4">{locales_es.appointmentScheduled.title}</h4>
                <div className="kt-timeline__item kt-timeline__item--warning">
                  <span className="btn btn-warning btn-elevate btn-circle btn-icon mr-3">
                    <i className="flaticon-signs-2"></i>
                  </span><span
                  className="kt-timeline__item-datetime"><strong>{locales_es.important}: </strong>{locales_es.appointmentScheduled.text1}</span>
                  <div className="kt-timeline__item-text">
                    <br/><strong>{locales_es.appointmentScheduled.text2}</strong>.
                    <br/>
                    <br/>{locales_es.appointmentScheduled.text3}:
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center mt-3">
              <div className="col-lg-9 text-center">
                {paymentMethods ? paymentMethods.length ?
                  paymentMethods.map((pm, index) => <Button key={'button-' + index} paymentMethod={pm} onContinue={onContinue}
                                                   onBankTransferClick={onBankTransferClick}
                                                   onExternalCheckoutClick={onExternalCheckoutClick}>{locales_es.gatewayMethodsList[pm.tag].label_patient}</Button>)
                  : <div>{locales_es.genericErrorContactSiteAdministrator}</div>
                  : <Spinner/>
                }
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
