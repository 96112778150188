import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {HREF_PAGE_ADD_PRESCRIPTION_PROFILE} from "../../models/constants";
import APIService from "../../modules/apiService";
import Helpers from "../../modules/helpers";
import locales_es from "../../locales/es";
import Loading from "../loading";

const PrescriptionProfile = ({profile, showMainModal, onRefresh, readOnly}) => {
  const history = useHistory();
  const api = new APIService();
  const helpers = new Helpers();
  const [loading, setLoading] = useState(false);

  const testProfile = (id) => {
    setLoading(true);
    api.getPrescriptionTestProfile(id).then(res => {
      // window.open(res.data.url, '_blank');
      showMainModal(locales_es.successModal.title, locales_es.successTestProfileSubtitle, null, [{
        label: locales_es.seePrescription,
        method: () => window.open(res.data.url, '_blank')
      }]);
      setLoading(false);
    }).catch(err => {
      showMainModal(locales_es.errorModal.title, helpers.getErrorMsg(err), {height: '500px'});
      setLoading(false);
    })
  };

  const deletePrescriptionProfile = (id) => {
    showMainModal('¿Desea eliminar este perfil de receta?', 'Esta acción no se puede deshacer.', null, [{
      label: 'Sí, eliminar', class: 'btn btn-danger btn-elevate btn-pill btn-sm', method: () => {
        api.deletePrescriptionProfile(id).then(res => {
          onRefresh && onRefresh();
          showMainModal(locales_es.successModal.title, res.message);
        }).catch(err => {
          showMainModal(locales_es.errorModal.title, helpers.getErrorMsg(err));
        })
      }
    }, {
      label: 'No, no eliminar', class: 'btn btn-primary btn-elevate btn-pill btn-sm'
    }]);
  };

  return (<>
      {loading && <Loading text="Creando prueba..." />}
      <div className="kt-widget-17 pt-5">
        <div className="kt-widget-17__item">
          <div className="kt-widget-17__product">
            <div className="kt-widget-17__thumb">
              <a className="woopi-kt-widget-17__thumb" onClick={(e) => {
                e.preventDefault();
                history.push(`${HREF_PAGE_ADD_PRESCRIPTION_PROFILE}/${profile.id}`);
              }}>
                {profile.full_logo_image ?
                  <img src={profile.full_logo_image} className="kt-widget-17__image" alt="" title=""/> :
                  <i className="flaticon2-image-file"/>}
              </a>
            </div>
            <div className={`kt-widget-17__product-desc ${readOnly ? 'col-5' : ''}`}>
              <div className="kt-widget-17__title">
                {profile.name}
              </div>
              <div className="kt-widget-17__sku">
                Especialidad: {profile.profession}
              </div>
              <div className="kt-widget-17__sku">
                Dirección: {profile.address}
              </div>
            </div>
            {!readOnly && <div className="kt-widget-17__prices">
              <div className="kt-widget-17__unit">
                <button
                  onClick={() => testProfile(profile.id)}
                  type="button"
                  className={`btn btn-accent btn-elevate ${helpers.isMobile() ? 'btn-circle btn-icon' : 'btn-pill'} m-1`}>
                  <i className="la la-clipboard p-0"/><span
                  className="d-none d-md-inline text-white"> Probar Receta</span>
                </button>
                <button
                  onClick={() => history.push(`${HREF_PAGE_ADD_PRESCRIPTION_PROFILE}/${profile.id}`)}
                  type="button"
                  className="btn btn-warning btn-elevate btn-circle btn-icon m-1"><i className="la la-pencil"/>
                </button>
                <button onClick={() => deletePrescriptionProfile(profile.id)} type="button"
                        className="btn btn-danger btn-elevate btn-circle btn-icon m-1"><i className="la la-trash"/>
                </button>
              </div>
            </div>}
          </div>
        </div>
      </div>
    </>
  );
};

export default PrescriptionProfile;
