import React from 'react';
const LS_KEY = 'prescriptionProfileId';

class PrescriptionProfileComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prescriptionProfileId: localStorage.getItem(LS_KEY) || ''
    };

  }

  setProfileId = (newId) => {
    localStorage.setItem(LS_KEY, newId);
    this.setState({ prescriptionProfileId: newId });
  };

  getProfileId = () => {
    return this.state.prescriptionProfileId;
  };
}

export default PrescriptionProfileComponent;
