import locales_es from "../locales/es";
import {USER_TYPE_PATIENT} from "../models/constants";
import moment from "moment-timezone";
import AuthService from "./authService";

class ViewHelpers {

	constructor() {
		this.auth = new AuthService();
	}

	// mode: 'patient' (USER_TYPE_PATIENT)
	getTimetablePricesText(timetable, mode) {
		let prices = '';

		if(timetable.prices && timetable.prices.length) {
			timetable.prices.map((p, i) => {
				prices += `${locales_es.currency_sign}${p.price} (${p.currency_id})`;
				if ((i + 1) < timetable.prices.length) {
					prices += ' - ';
				}
			})
		} else if (mode === USER_TYPE_PATIENT) {
			prices += `${locales_es.consult}`;
		} else {
			prices += `${locales_es.currency_sign}${locales_es.notDefined}`;
		}
		return prices
	}

	renderAge(dateString, onlyAge) {
		const today = moment();
		// const birthDate = new Date(dateString);
		// const birthDate = this.jsCoreDateCreator(dateString);
		const birthDate = moment(dateString, "YYYY-MM-DDThh:mm:ssZ");
		// let age = today.getFullYear() - birthDate.getFullYear();
		let years = today.diff(birthDate, "year");
		birthDate.add(years, "years");

		let months = today.diff(birthDate, "months");
		birthDate.add(months, "months");

		let days = today.diff(birthDate, "days");

		return onlyAge ? (
			<>
					{years ? years : ""} {years <= 0 && months > 0 ? months : ""}{" "}
					{years <= 0 && months > 0 && months === 1
						? locales_es.month
						: years <= 0 && months > 1
							? locales_es.months
							: ""}{" "}
					{years <= 0 && days > 0 ? days : ""}{" "}
					{years <= 0 && days > 0 && days === 1
						? locales_es.day
						: years <= 0 && days > 1
							? locales_es.days
							: ""}
			</>
		) : years || months ? (
			<>
					{years ? years : ""}{" "}
					{years > 1 ? locales_es.years : years > 0 ? locales_es.year : ""}{" "}
					{months > 0 ? months : ""}{" "}
					{months > 0 && months === 1
						? locales_es.month
						: months > 1
							? locales_es.months
							: ""}{" "}
					{days > 0 ? days : ""}{" "}
					{days > 0 && days === 1
						? locales_es.day
						: days > 1
							? locales_es.days
							: ""}
			</>
		) : null;
	}

	getSelectedIdentificationTypeName(identificationOptions, identificationId, shortMode) {
		const filtered = identificationOptions.filter(id => id.id === identificationId);
		const text = filtered && filtered.length ? `${filtered[0].name}` : locales_es.identificationType
		return shortMode ? text : `${locales_es.numberOf} ${text}`
	}

	async isUserAllowed(userTypeAllowed) {
		const isLoggedIn = await this.auth.isLoggedUser();
		const remote = await this.auth.getRemoteUserData();
		return !(!isLoggedIn || (isLoggedIn && remote && remote.data?.user?.user_type !== userTypeAllowed));
	}

	formatText(text) {
		return text.replace(/\n/g, '<br />');
	}

	userDataHasData(userData, key) {
		return userData[key] ? userData[key] : (userData.user?.[key] ? userData.user[key] : null);
	}
}

export default ViewHelpers;
