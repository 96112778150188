import React, {useEffect, useState} from 'react';
import locales_es from '../../../../locales/es';

export default function CForm({
                                onUpdateState,
                                cardNumberRef,
                                planRef,
                                onCardInputFocus,
                                onCardInputBlur,
                                children
                              }) {
  const [cardNumber, setCardNumber] = useState('');

  const handleFormChange = (event) => {
    const {name, value} = event.target;
    onUpdateState(name, value);
  };

  const onCardNumberChange = (event) => {
    let {value, name} = event.target;

    const updatedCardNumber = value.replace(/\D/g, '').trimRight();
    setCardNumber(updatedCardNumber);
    onUpdateState(name, updatedCardNumber);
  };

  const checkPreloadedInputs = () => {
    if (cardNumberRef.current.value) {
      setCardNumber(cardNumberRef.current.value);
    }
  }

  useEffect(() => {
    const handler =() => {
      checkPreloadedInputs();
    }
    document.addEventListener('checkPreloadedInputs', handler);
    return () => {
      document.removeEventListener('checkPreloadedInputs', handler);
    };
  }, [])

  return (
    <>
      <div className="card-form__inner">
        <div className="card-list">{children}</div>
        <div className="card-input">
          <label htmlFor="cardNumber" className="card-input__label">
            {locales_es.medicalInsuranceNumber}
          </label>
          <input
            type="tel"
            name="cardNumber"
            className="card-input__input"
            autoComplete="off"
            onChange={onCardNumberChange}
            maxLength={20}
            ref={cardNumberRef}
            onFocus={(e) => {
              onCardInputFocus(e, 'cardNumber');
              checkPreloadedInputs();
            }}
            onBlur={onCardInputBlur}
            value={cardNumber}
          />
        </div>

        <div className="card-input">
          <label htmlFor="cardName" className="card-input__label">
            {locales_es.medicalInsurancePlan}
          </label>
          <input
            type="text"
            className="card-input__input"
            autoComplete="off"
            name="plan"
            onChange={handleFormChange}
            ref={planRef}
            onFocus={(e) => {
              onCardInputFocus(e, 'plan');
              checkPreloadedInputs();
            }}
            onBlur={onCardInputBlur}
          />
        </div>

      </div>
    </>
  );
}
