import React from 'react';
import Lottie from "react-lottie";
import animationData from "./insurances.json";

function NoProfessionalRegistrations() {
  const animationSize = 200;
  const animationOptions = {
    loop: true, autoplay: true, animationData: animationData, rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  return (
    <Lottie
      options={animationOptions}
      height={animationSize}
      width={animationSize}
    />
  );
}

export default NoProfessionalRegistrations;
