import React, {Component} from 'react';
import locales_es from "../../locales/es";
import {
  PAYMENT_METHOD_AMOUNT_TYPE_FIXED,
  PAYMENT_METHOD_AMOUNT_TYPE_PERCENT, PAYMENT_METHOD_MERCADO_PAGO_ARS,
  PAYMENT_METHOD_PAYPAL_USD
} from "../../models/constants";
import paypalLogo from '../../images/paypal_usd.png';
import mercadopagoLogo from '../../images/mercadopago_ars.webp';

export default class ExternalCheckoutData extends Component {

  render() {
    const {selectedGateway, selectedAppointment, onClickCancel} = this.props;

    const calculatePercent = () => {
      return ((Number(selectedAppointment.before_payment_amount).toFixed(2) * 100) / Number(selectedAppointment.consultation_price).toFixed(2));
    }

    const onAccept = () => {
      if (selectedGateway === PAYMENT_METHOD_PAYPAL_USD && selectedAppointment.init_point_before_payment_paypal) {
        window.location.href = selectedAppointment.init_point_before_payment_paypal;
        return;
      }
      if (selectedGateway === PAYMENT_METHOD_MERCADO_PAGO_ARS && selectedAppointment.init_point_before_payment) {
        window.location.href = selectedAppointment.init_point_before_payment;
        return;
      }
      this.props.showMainModal(locales_es.errorModal.title, locales_es.genericNoGatewayConnected)
    }

    return (
      <>
        <div className="woopi-overlay">
          <div className="woopi-overlay-content">
            {selectedGateway === PAYMENT_METHOD_PAYPAL_USD ?
              <div className="row justify-content-center">
                <div className="col text-center">
                  <img className="w-25 m-3" src={paypalLogo}/>
                </div>
              </div>
              : null
            }
            {selectedGateway === PAYMENT_METHOD_MERCADO_PAGO_ARS ?
              <div className="row justify-content-center">
                <div className="col text-center">
                  <img className="w-25 m-3" src={mercadopagoLogo}/>
                </div>
              </div>
              : null
            }
            <div className="row justify-content-center">
              <div className="col-lg-9 text-center">
                <div className="kt-timeline__item-text">
                  <br/><strong>{locales_es.gatewayMethodsList[selectedGateway].subtitle}</strong>
                  <br/>
                  <br/>
                  <div className="m-2">
                    {selectedAppointment.before_payment_type === PAYMENT_METHOD_AMOUNT_TYPE_FIXED ?
                      `Debes pagar $${selectedAppointment.before_payment_amount}, en concepto de seña o pago parcial o total del turno.` : ''}
                    {selectedAppointment.before_payment_type === PAYMENT_METHOD_AMOUNT_TYPE_PERCENT ?
                      `Debes pagar $${selectedAppointment.before_payment_amount}, que es el ${calculatePercent()}% del valor del turno, en concepto de seña o pago parcial o total del turno.` : ''}
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center mt-3">
              <div className="col text-center">
                <button type="button" className={'btn btn-elevate btn-pill m-1 btn-success'}
                        onClick={onAccept}
                >{locales_es.continue}
                </button>
              </div>
              <div className="col text-center">
                <button type="button" className={'btn btn-elevate btn-pill m-1 btn-danger'}
                        onClick={onClickCancel}
                >{locales_es.cancel}
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
