import React from 'react';
import {
    CURRENCY_ID_ARS,
    CURRENCY_ID_USD,
    PAYMENT_METHOD_BANK_TRANSFER_ARS, PAYMENT_METHOD_BANK_TRANSFER_USD,
    PAYMENT_METHOD_MANUAL_ARS,
    PAYMENT_METHOD_MANUAL_USD, PAYMENT_METHOD_MERCADO_PAGO_ARS, PAYMENT_METHOD_PAYPAL_USD
} from "../../models/constants";

export default function Button({ children, onContinue, paymentMethod, onBankTransferClick, onExternalCheckoutClick }) {

    let buttonClassName = 'btn-focus';
    let iconClassName = 'flaticon2-calendar-5';
    let onClick = onContinue;

    switch (paymentMethod.tag) {
        case PAYMENT_METHOD_MANUAL_ARS:
        case PAYMENT_METHOD_MANUAL_USD:
            buttonClassName = 'btn-brand';
            iconClassName = 'la la-calendar-check-o';
            break;
        case PAYMENT_METHOD_BANK_TRANSFER_ARS:
            buttonClassName = 'btn-success';
            iconClassName = 'la la-bank';
            onClick = () => onBankTransferClick(CURRENCY_ID_ARS);
            break;
        case PAYMENT_METHOD_BANK_TRANSFER_USD:
            buttonClassName = 'btn-success';
            iconClassName = 'la la-bank';
            onClick = () => onBankTransferClick(CURRENCY_ID_USD);
            break;
        case PAYMENT_METHOD_MERCADO_PAGO_ARS:
            buttonClassName = 'btn-info';
            iconClassName = 'la la-credit-card';
            onClick = () => onExternalCheckoutClick(paymentMethod.tag);
            break;
        case PAYMENT_METHOD_PAYPAL_USD:
            buttonClassName = 'btn-info';
            iconClassName = 'la la-credit-card';
            onClick = () => onExternalCheckoutClick(paymentMethod.tag);
            break;
    }

    return (
        <button type="button" className={'btn btn-elevate btn-pill m-1 ' + buttonClassName}
            onClick={onClick}
        ><i className={iconClassName}></i> {children}
        </button>
    );
}
