import React, {Component} from 'react';
import locales_es from "../../locales/es";
import Lottie from "react-lottie";
import animationData from "../../lotties/upgrade.json";

export default class ModalPlanSuggestion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  setLoading = (bool) => {
    this.setState({loading: bool});
  };

  render() {
    const {title, message, plans, onAbort, onConfirm} = this.props;

    const animationSize = 150;

    const animationOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };

    return (
      <div className="woopi-overlay">
        <div className="woopi-overlay-content">
          <div className="row">
            <div className="col text-center">
              <Lottie
                options={animationOptions}
                height={animationSize}
                width={animationSize}
              />
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-9 text-center">
              <h4 className="m-3">{title}</h4>
              <p>{message}</p>
            </div>
          </div>

          <div className="row justify-content-center mt-3">
            <div className="col-lg-9 text-center">
              {plans && plans.length ? plans.map((plan) => (
                  <div className="row justify-center justify-content-center">
                    <button
                      key={plan.tag}
                      className={`btn ${'btn-brand'} btn-pill m-1 cursor-pointer`}
                      onClick={() => {
                        this.setLoading(true);
                        onConfirm && onConfirm(plan);
                      }}
                      disabled={this.state.loading}
                    >
                      {locales_es.subscribePlan} {plan.toUpperCase()}
                    </button>
                  </div>
                ))
                :
                <div className="row justify-center justify-content-center">
                  <button
                    className={`btn ${'btn-brand'} btn-pill m-1 cursor-pointer`}
                    onClick={() => {
                      this.setLoading(true);
                      onConfirm && onConfirm(null);
                    }}
                    disabled={this.state.loading}
                  >
                    {locales_es.subscribePlan}
                  </button>
                </div>
              }

              <button
                className="btn btn-secondary btn-pill m-1"
                onClick={(e) => {
                  e.preventDefault();
                  onAbort && onAbort();
                }}
              >
                {locales_es.cancel}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
