import React from 'react';
import {HREF_PAGE_ADD_CLINIC} from "../../models/constants";

const NoAttentionPlaces = ({ medicId }) => {
  return (
    <div className="kt-portlet p-3">
      <div className="alert alert-warning fade show mt-3" role="alert">
        <div className="alert-icon"><i className="flaticon-warning"></i></div>
        <div className="alert-text">No tienes lugares de atención. Ingresa al menos uno para configurar tus turnos.</div>
      </div>
      <div className="text-center">
        <a href={`${HREF_PAGE_ADD_CLINIC}/${medicId}?redirect=${window.location.pathname}`}
           className="btn btn-brand btn-sm btn-bold btn-upper">+ Añadir Clínica</a>
      </div>
    </div>
  );
};

export default NoAttentionPlaces;
