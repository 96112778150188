import React, {Component} from "react";
import locales_es from "../../locales/es";
import DateTimeService from "../../modules/DateTimeService";
import APIService from "../../modules/apiService";
import Helpers from "../../modules/helpers";
import {
  DEFAULT_TIME_ZONE,
  HREF_PAGE_MEDIC,
  USER_TYPE_PATIENT,
} from "../../models/constants";
import ConfigService from "../../modules/configService";
import './styles.css';
import Loading from "../loading";
import ViewHelpers from "../../modules/viewHelpers";
import {DateTime, Settings} from "luxon";

export default class TimetablesList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modalVisible: false,
    };

    this.dateTimeService = new DateTimeService();
    this.api = new APIService();
    this.helpers = new Helpers();
    this.configService = new ConfigService();
    this.viewHelpers = new ViewHelpers();
  }

  componentDidMount() {
    Settings.defaultLocale = 'es';
    /*this.configService.getLocalClinicData().then(res => {
      this.setState({
        clinicAddress: res.address,
        // clinicWebUrl: res.web_url,
      })
    }).catch(err => {
      console.error(err);
    })*/
  }

  getClinicAddress(clinicId) {
    this.setLoading(true);
    this.api.getClinicById(clinicId).then(res => {
      if (res && res.data && res.data.address) {
        window.open(`https://google.com/maps/place/${res.data.address}`);
      } else {
        this.props.showMainModal(locales_es.infoModal.title, 'No se ingresaron detalles del lugar de atención');
      }
      this.setLoading(false);
    }).catch(err => {
      this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
      this.setLoading(false);
    });
  }

  setLoading(bool) {
    this.setState({
      loading: bool
    })
  }

  renderLimitedTime(timetable) {
    const startDate = timetable.start_date;
    const endDate = timetable.end_date;

    let str = '';

    if (startDate && endDate && this.dateTimeService.diffTwoDates(new Date(startDate), new Date(endDate)).days <= 1) {
      str += `${locales_es.singleTimetable}: ${this.dateTimeService.parseDateToConventionalAPIString(new Date(startDate))}`;
    } else {
      if (startDate && this.dateTimeService.getTimeRemaining(startDate, true).days > 0) {
        str += ` ${locales_es.start}: ${this.dateTimeService.parseDateToConventionalAPIString(new Date(startDate))}`;
      }
      if (endDate) {
        str += ` ${locales_es.end}: ${this.dateTimeService.parseDateToConventionalAPIString(new Date(endDate))}`;
      }
    }
    return str;
  }

  getClinicName(timetable) {
    const clinic = this.props.clinics?.find(c => c.timetable_id === timetable.timetable_id);
    return clinic ? clinic.name : locales_es.yourClinic;
  }

  render() {

    const {timetables, onViewTimetable} = this.props;
    const {loading} = this.state;
    return (
      <>
        {loading && <Loading/>}
        <div className="table-responsive d-none d-md-block">
          <table className="table">
            <thead>
            <tr>
              <th className="d-none d-sm-table-cell">#</th>
              <th>{locales_es.day}</th>
              {/*<th>{locales_es.consultingPlace}</th>*/}
              <th className="d-none d-sm-table-cell">{locales_es.appointmentType}</th>
              <th className="d-none d-sm-table-cell">{locales_es.appointmentDuration}</th>
              <th className="d-none d-sm-table-cell">{locales_es.startDate}</th>
              <th className="d-none d-sm-table-cell">{locales_es.endDate}</th>
              <th scope="col">{locales_es.actions}</th>
            </tr>
            </thead>
            <tbody>
            {timetables.map((timetable, index) => {
              const limitedTime = this.renderLimitedTime(timetable);
              const startDate = timetable.start_date ? DateTime.fromISO(timetable.start_date, {zone: (timetable.time_zone ?? DEFAULT_TIME_ZONE)}) : null;
              const endDate = timetable.end_date ? DateTime.fromISO(timetable.end_date, {zone: (timetable.time_zone ?? DEFAULT_TIME_ZONE)}) : null;
              return (
                <tr key={'appointment-' + index} data-row="0" className={`kt-datatable__row ${timetable.enabled ? '' : 'disabled'}`}>
                  <td
                    className="kt-datatable__cell--center kt-datatable__cell kt-datatable__cell--check d-none d-sm-table-cell"
                    data-hj-allow>
                    {timetable.id}
                  </td>
                  <td className="kt-datatable__cell" data-hj-allow>
                    <div>{locales_es.daysOfTheWeek[Object.keys(locales_es.daysOfTheWeek)[timetable.day]]}
                      &nbsp;{timetable.start} - {timetable.end} / {locales_es.price}: <u>{this.viewHelpers.getTimetablePricesText(timetable, USER_TYPE_PATIENT)}</u>
                      {timetable.start_date || timetable.end_date ? limitedTime && <>
                        <br/><i>{limitedTime}</i></> : null}
                    </div>
                    <div className="mt-1">{locales_es.timezone}: {timetable.time_zone}</div>
                  </td>
                  {/*<td className="kt-datatable__cell">
                    {this.getClinicName(timetable)}
                  </td>*/}
                  <td className="kt-datatable__cell d-none d-sm-table-cell">
                    <strong>{locales_es.appointmentTypeName[timetable.type_id]}</strong>
                  </td>
                  <td className="kt-datatable__cell d-none d-sm-table-cell">
                    {timetable.duration} {locales_es.minutes}
                  </td>
                  <td className="kt-datatable__cell">
                    {startDate ?
                      <>
                        <span>{startDate.toFormat('d LLLL yyyy')}</span>&nbsp;
                        <span>{startDate.toFormat('HH:mm')}hs.</span>
                      </>
                      :
                      <span>-</span>
                    }
                  </td>
                  <td className="kt-datatable__cell">
                    {endDate ?
                      <>
                        <span>{endDate.toFormat('d LLLL yyyy')}</span>&nbsp;
                        <span>{endDate.toFormat('HH:mm')}hs.</span>
                      </>
                      :
                      <span>-</span>
                    }
                  </td>
                  <td className="kt-datatable__cell">
                    <button onClick={() => onViewTimetable(timetable.id)} type="button"
                            className="btn btn-sm btn-elevate btn-pill btn-outline-info m-1">
                      {locales_es.view}
                    </button>
                  </td>
                </tr>
              )
            })
            }
            </tbody>
          </table>

        </div>

        <div className="col-sm-12 mb-30 d-md-none">
          {timetables.map((timetable, index) => {
            const limitedTime = this.renderLimitedTime(timetable);
            const startDate = timetable.start_date ? DateTime.fromISO(timetable.start_date, {zone: (timetable.time_zone ?? DEFAULT_TIME_ZONE)}) : null;
            const endDate = timetable.end_date ? DateTime.fromISO(timetable.end_date, {zone: (timetable.time_zone ?? DEFAULT_TIME_ZONE)}) : null;
            return (
              <div key={'timetable-' + index} data-row="0" className="row mb-3 p-3 woopi-mobile-card">
                <div className="woopi-mobile-card-header">
                  {/*<div className="col-12"
                                        data-hj-allow>
                                        #{timetable.id}
                                    </div>*/}
                  <div className="col-12 kt-widget-15">
                    <div className="kt-widget-15__body">
                      <div className="kt-widget-15__author">
                        <div className="kt-widget-15__photo">
                          <img src={timetable.medic?.full_profile_image}/>
                        </div>
                        <div className="kt-widget-15__detail">
                          <a
                            href={`${HREF_PAGE_MEDIC}/${timetable.medic?.id}`}>{timetable.medic?.prefix_name} {timetable.medic?.name} {timetable.medic?.lastname}</a>
                          <div className="kt-widget-15__desc">
                            {timetable.medic?.specialty_name}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12" data-hj-allow>
                    <div>{locales_es.daysOfTheWeek[Object.keys(locales_es.daysOfTheWeek)[timetable.day]]}
                      &nbsp;{timetable.start} - {timetable.end} / {locales_es.price}: <u>{this.viewHelpers.getTimetablePricesText(timetable, USER_TYPE_PATIENT)}</u>
                      {timetable.start_date || timetable.end_date ? limitedTime && <>
                        <br/><i>{limitedTime}</i></> : null}
                    </div>
                    <div className="mt-1">{locales_es.timezone}: {timetable.time_zone}</div>
                  </div>
                </div>
                <div className="col-12 mt-2 mb-2">
                  <strong>{locales_es.appointmentTypeName[timetable.type_id]}</strong>
                </div>
                <div className="col-12">
                  <strong>{locales_es.appointmentDuration}:</strong> {timetable.duration} {locales_es.minutes}
                </div>
                <div className="col-12">
                  <strong>{locales_es.startDate}:</strong> {startDate ?
                    <>
                      <span>{startDate.toFormat('d LLLL yyyy')}</span>&nbsp;
                      <span>{startDate.toFormat('HH:mm')}hs.</span>
                    </>
                    :
                    <span>-</span>
                  }
                </div>
                <div className="col-12">
                  <strong>{locales_es.endDate}:</strong> {endDate ?
                  <>
                    <span>{endDate.toFormat('d LLLL yyyy')}</span>&nbsp;
                    <span>{endDate.toFormat('HH:mm')}hs.</span>
                  </>
                  :
                  <span>-</span>
                }
                </div>
                <div className="col-12 text-center mt-3">
                  <button onClick={() => onViewTimetable(timetable.id)} type="button"
                          className="btn btn-elevate btn-pill btn-outline-info m-1">
                    {locales_es.view}
                  </button>
                </div>
              </div>
            )
          })
          }
        </div>

      </>
    )
  }
}
