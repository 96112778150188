import {PARSE_TEL_INPUT_COUNTRIES_CODES} from "../models/constants";

class Helpers {

	validateEmail(email) {
		// eslint-disable-next-line
		let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
		return reg.test(email);
	}

	/*
	* Takes an Integer and returns a String as a "24hs." format.
	* */
	getTwentyFourFormatAsString(int) {
		return String(int < 10 ? "0" + int : int);
	}

	/*
	* TIME HTML PARSING
	* Takes a event date and returns it as a parsed custom html format output
	* */
	parseEventTime(eventDate, isAmPm, hasToAddStrings, utcOn) {
		const date = new Date(eventDate);

		let result = '';

		const hours = this.getTwentyFourFormatAsString(utcOn ? date.getUTCHours() : date.getHours());
		const minutes = this.getTwentyFourFormatAsString(utcOn ? date.getUTCMinutes() : date.getMinutes());

		const parsedTime = this.parseTime(hours + ':' + minutes, isAmPm, hasToAddStrings);

		result += parsedTime.hours + '';
		result += ':' + parsedTime.minutes;

		return result;
	}

	/*
	* Takes a time string and returns it as a JSON object
	* */
	parseTime(timeToParse, isAmPm, hasToAddStrings) {

		if (!timeToParse) {
			return {};
		}

		// Ejemplo de un string de tiempo: "09:00:00"
		const hours = timeToParse.substr(0, 2); // 09
		const minutes = timeToParse.substr(3, 2); // 00
		if (isAmPm) {
			const ampm = hasToAddStrings ? hours > 11 ? ' PM' : ' AM' : '';
			return {
				'hours': (hours % 12 || 12), // converts to correct hours am/pm,
				'minutes': minutes + ampm
			};
		} else {
			const hs = hasToAddStrings ? 'hs.' : '';
			return {
				'hours': hours,
				'minutes': minutes + hs
			};
		}

	}

	/*
	*
	* Utility to get ErrorMessages from ApiService responses
	 */
	getErrorMsg(err) {
		if (
			err &&
			err.response &&
			err.response.status &&
			err.response.status === 500
		) {
			return 'Contáctate con el administrador por favor para que podamos ayudarte.'
		}

		let msg = '';

		msg =
			err && err.response && err.response.data && err.response.data.message
				? err.response.data.message
				: err.message;

		if (
			err &&
			err.errors &&
			err.errors.length
		) {
			err.errors.map(e => {
				/* eslint-disable no-unused-expressions */
				e !== msg ? (msg += '. ' + e) : null;
			});
		}

		return msg;
	}

	goBack() {
		window.history.back();
	}

	/*
	*
	* Method to detect mobile devices
	 */
	isMobile() {
		return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
	}

	getFakeEmail() {
		return `${new Date().getTime()}@medicloud.com.ar`;
	}

	isFake(email) {
		return /@medicloud\.com\.ar/.test(email);
	}

	userHasAccount(user) {
		return user && user.email && !this.isFake(user.email)
	}

	findDialCodeAndPhoneNumber = (phoneNumber) => {
		const result = PARSE_TEL_INPUT_COUNTRIES_CODES.find((country) => {
			/* eslint-disable no-useless-escape */
			const escapedDialCode = country.dialCode.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
			const regex = new RegExp(`^${escapedDialCode}`);
			return regex.test(phoneNumber);
		});

		if (result) {
			const phoneNumberWithoutDialCode = phoneNumber.replace(result.dialCode, '').replace(' ', '');
			return {
				countryCode: result.countryCode,
				dialCode: result.dialCode,
				phoneNumberWithoutDialCode: phoneNumberWithoutDialCode
			};
		} else {
			return {
				countryCode: null,
				dialCode: null,
				phoneNumberWithoutDialCode: phoneNumber
			};
		}
	};

	generateHash(obj) {
		const str = JSON.stringify(obj);
		let hash = 0;
		for (let i = 0; i < str.length; i++) {
			const char = str.charCodeAt(i);
			hash = ((hash << 5) - hash) + char;
			hash |= 0; // Convertir a entero de 32 bits
		}
		return hash;
	}

	validateEmptyFields(fields) {
		const errors = [];
		const stateKeys = Object.keys(fields);
		stateKeys.forEach(key => {
			if (fields[key] === '' || fields[key] === null || fields[key] === undefined) {
				errors.push(key);
			}
		});
		return errors;
	}

	validateEmptyArrays = (array) => {
		return array.length;
	}

}

//unregister();

export default Helpers;
