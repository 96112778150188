// Esta función crea un nuevo Image con la URL de la imagen original,
// luego usa el canvas para dibujar la imagen recortada.
const getCroppedImg = async (imageSrc, pixelCrop) => {
  const image = new Image();
  image.src = imageSrc;

  // Asegúrate de que la imagen esté cargada completamente para acceder a sus dimensiones
  await new Promise((resolve) => {
    image.onload = resolve;
  });

  const canvas = document.createElement('canvas');
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;
  const ctx = canvas.getContext('2d');

  // Dibuja la imagen recortada en el canvas
  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  );

  // Convierte el canvas a un string base64
  return canvas.toDataURL('image/jpeg');
}

export default getCroppedImg;
