import React, {Component} from 'react';
import Loading from "./../../components/loading";
import APIService from './../../modules/apiService';
import Subheader from "../../components/subheader";
import locales_es from "../../locales/es";
import {
    HREF_PAGE_ADD_MEDICAL_INSURANCE,
    HREF_PAGE_MY_INSURANCES,
    USER_TYPE_PATIENT,
} from "../../models/constants";
import Helpers from "../../modules/helpers";
import AuthService from "../../modules/authService";
import InsurancesList from '../../components/insurancesList';
import Lottie from "react-lottie";
import animationData from "../../lotties/insurances.json";
import Modal from "../../components/modal";

export default class MyMedicalInsurances extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            myMedicalInsurances: null,
            referer: window.URLSearchParams
              ? new window.URLSearchParams(this.props.location.search).get("referer") : null,
        };

        this.api = new APIService();
        this.helpers = new Helpers();
        this.auth = new AuthService();
    }

    componentDidMount() {
        if (this.auth.getLocalUserType() !== USER_TYPE_PATIENT) {
            window.location.href = '/';
            return;
        }
        this.getMyMedicalInsurances();
    }

    getMyMedicalInsurances() {
        this.setState({loading: true}, () => {
            this.api.getMyMedicalInsurances()
              .then((res) => {
                  this.setState({
                      myMedicalInsurances: res.data,
                  }, () => this.setState({loading: false}));
              }).catch(err => {
                this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
                this.setState({
                    myMedicalInsurances: [],
                }, () => this.setState({loading: false}));
            });
        });
    }

    removeMedicalInsurance(insuranceId) {
        this.props.showMainModal('¿Desea eliminar a este cobertura médica?', 'Esta acción no se puede deshacer.', null, [
            {
                label: 'Sí, eliminar',
                class: 'btn btn-danger btn-elevate btn-pill btn-sm',
                method: () => {
                    this.api.deleteMedicalInsurancePatient(insuranceId).then(res => {
                        this.props.showMainModal(locales_es.successModal.title, res.message);
                        this.getMyMedicalInsurances();
                    }).catch(err => {
                        this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
                    })
                }
            },
            {
                label: 'No, no eliminar',
                class: 'btn btn-primary btn-elevate btn-pill btn-sm'
            }
        ]);
    }

    render() {

        const {myMedicalInsurances, loading, referer} = this.state;

        const animationSize = 300;
        const animationOptions = {
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
            }
        };

        return (
            <>
                {loading && <Loading/>}
                {referer && referer === locales_es.newPatientURL ?
                  <Modal modalId="newPatientDashboard"
                         title={"¡Excelente! Le avisaremos al médico que ya tiene tus datos"}
                         visible={true}
                         hideCloseButton={true}
                         actions={[
                             {
                                 className: 'btn btn-brand btn-pill m-3 align-self-start',
                                 title: locales_es.addMedicalInsurance,
                                 onClick: () => this.props.history.push(`${HREF_PAGE_ADD_MEDICAL_INSURANCE}`)
                             },
                             {
                                 className: 'btn btn-secondary btn-pill',
                                 title: locales_es.skip,
                                 onClick: () => {}
                             }
                         ]}
                  >
                      <Lottie
                        options={animationOptions}
                        height={(animationSize / 1.5)}
                        width={(animationSize / 1.5)}
                      />
                      <h6 className="p-4 text-center">{locales_es.updateYourMedicalInsurancesToGetOnlinePrescriptions}</h6>
                  </Modal>
                  : null}
                <Subheader breadcrumbs={[
                    {
                        name: locales_es.myMedicalInsurances,
                        href: HREF_PAGE_MY_INSURANCES
                    }
                ]}/>
                <div className="kt-grid kt-grid--desktop kt-grid--ver kt-grid--ver-desktop kt-app">
                    <div className="kt-grid__item kt-grid__item--fluid kt-app__content">
                        {
                            myMedicalInsurances == null ? <Loading/>
                                : myMedicalInsurances && myMedicalInsurances.length ?
                                <InsurancesList medicalInsurances={myMedicalInsurances} removeMedicalInsurance={(id) => this.removeMedicalInsurance(id)} />
                                :
                                <div>
                                    <Lottie
                                        options={animationOptions}
                                        height={animationSize}
                                        width={animationSize}
                                    />
                                    <div className="text-center">{locales_es.noMedicalInsurancesAvailables} <br/><br/></div>
                                </div>
                        }

                        <div className="text-center mt-3">
                            <a href={`${HREF_PAGE_ADD_MEDICAL_INSURANCE}`}
                               className="btn btn-brand btn-sm btn-bold btn-upper">
                                <i className="fa fa-plus" /> {locales_es.addMedicalInsurance}
                            </a>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
