// MedicineStorageService.js
import Helpers from "./helpers";

class MedicineStorageService {
  constructor() {
    this.LS_MEDICINES = 'commonlyUsedMedicines';
    this.helpers = new Helpers();
  }

  loadMedicines() {
    return JSON.parse(localStorage.getItem(this.LS_MEDICINES)) || [];
  }

  saveMedicine(newMedicine) {
    let medicines = this.loadMedicines();
    const newMedicineHash = this.helpers.generateHash(newMedicine);

    if (!medicines.some(med => this.helpers.generateHash(med) === newMedicineHash)) {
      medicines.push(newMedicine);
      if (medicines.length > 6) {
        medicines = medicines.slice(1);
      }
      localStorage.setItem(this.LS_MEDICINES, JSON.stringify(medicines));
    }
  }

  removeMedicine(index) {
    let medicines = this.loadMedicines();
    medicines.splice(index, 1);
    localStorage.setItem(this.LS_MEDICINES, JSON.stringify(medicines));
  }

  clearStorage() {
    localStorage.removeItem(this.LS_MEDICINES);
  }
}

export default MedicineStorageService;
