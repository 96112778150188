import React, {Component} from "react";
import APIService from "../../modules/apiService";
import Helpers from "../../modules/helpers";
import Spinner from "../../components/spinner";
import locales_es from "../../locales/es";
import AuthService from "../../modules/authService";
import {
  HREF_PAGE_ADD_PRESCRIPTION,
  HREF_PAGE_DOCUMENT_VISOR,
  HREF_PAGE_HOME,
  HREF_PAGE_PRESCRIPTIONS, HREF_PAGE_VIEW_PRESCRIPTION_HASH,
  MEDICAL_PRESCRIPTION_TYPE, PAYMENT_STATUS_PENDING,
  USER_TYPE_MEDIC, USER_TYPE_PATIENT,
  USER_TYPE_SECRETARY,
} from "../../models/constants";
import DateTimeService from "../../modules/DateTimeService";
import Subheader from "../../components/subheader";
import ViewHelpers from "../../modules/viewHelpers";
import Lottie from "react-lottie";
import animationData from "../../lotties/no-prescriptions.json";
import pdfIcon from '../../assets/images/pdf.svg';
import {TablePaging} from "../../components/TablePaging";
import ProfileImageDefault from '../../images/profile_image_default.jpg'

// **************************************
// **************************************
// **************************************
// *************IMPORTANTE***************
// **************************************
// **************************************
// **************************************
// Esta vista es para sólo para PACIENTES
export default class MyPrescriptionsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prescriptions: null,
    };

    this.api = new APIService();
    this.helpers = new Helpers();
    this.auth = new AuthService();
    this.dateTimeService = new DateTimeService();
    this.viewHelpers = new ViewHelpers();
    this.perPageDefault = 5;
  }

  componentDidMount() {
    if (this.auth.getLocalUserType() !== USER_TYPE_PATIENT) {
      window.location.href = HREF_PAGE_HOME;
      return;
    }

    this.getMyPrescriptions();

    const root = document.getElementById('root');
    if (this.helpers.isMobile() && root) {
      root.addEventListener('scroll', () => this.handleScroll());
    }
  }

  componentWillUnmount() {
    const root = document.getElementById('root');
    if (this.helpers.isMobile() && root) {
      root.removeEventListener('scroll', () => this.handleScroll())
    }
  }

  handleScroll() {
    const root = document.getElementById('root');
    const {scrollTop, scrollHeight, clientHeight} = root;
    if ((scrollTop + clientHeight >= scrollHeight - 50) && this.state.patients !== null) {
      // you're at the bottom of the page
      if (!this.state.refreshing && this.state.pagingData && this.state.pagingData.next_page_url) {
        this.getMyPrescriptions(null, this.state.pagingData.next_page_url);
      }
    }
  }

  getMyPrescriptions(params, pageUrl) {
    this.setState({
      refreshing: true
    }, () => {
      this.api.getMyPrescriptionsOrPatientPrescriptions(params, pageUrl, this.props.patientId).then(res => {
        const pagingData = JSON.parse(JSON.stringify(res));
        delete pagingData.data;
        const _prescriptions = JSON.parse(JSON.stringify(this.state.prescriptions));
        this.setState({
          prescriptions: _prescriptions ? _prescriptions.concat(res.data) : res.data,
          apiPatients: _prescriptions ? _prescriptions.concat(res.data) : res.data,
          pagingData,
          refreshing: false,
        });
      }).catch(err => {
        this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
      });
    })
  }

  renderMedicine(item) {
    return (<div className={item.no_replacement ? 'woopi-medication-line-through' : ''}>
      {`${item.quantity} x ${item.is_generic ? locales_es.generic : (' ' + item.commercial_name)}`}
      ({item.drug}) {item.presentation}
    </div>)
  }

  loadPrescription(prescription) {
    if (prescription.payment_amount && prescription.payment_status === PAYMENT_STATUS_PENDING) {
      window.open(`${HREF_PAGE_VIEW_PRESCRIPTION_HASH}/${prescription.hash}`, '_blank');
    } else {
      this.props.history.push(HREF_PAGE_DOCUMENT_VISOR, {
        documents: [{
          pdfSource: {uri: prescription.full_file_url}, prescriptionId: prescription.id,
        },],
        prescriptionType: MEDICAL_PRESCRIPTION_TYPE,
        emails: prescription.patient && prescription.patient?.user && prescription.patient?.user.email ? [prescription.patient?.user.email] : null,
      });
    }
  }

  repeatPrescription(prescription) {
    this.props.history.push(HREF_PAGE_ADD_PRESCRIPTION, {
      patientId: prescription.patient_id,
      patientMedicalInsuranceId: prescription.medical_insurance_user_patient_id,
      prescriptionProfileId: prescription.prescription_profile_id,
      professionalRegistrationId: prescription.professional_registration_id,
      medicines: prescription.medicines.map(medicine => {
        medicine.presentation = {
          name: medicine.presentation
        };
        return medicine;
      }),
      diagnostic: prescription.diagnostic,
      indications: prescription.indications,
      comments: prescription.comments,
      isHiv: prescription.is_hiv,
    });
  }

  onChangeTablePaging = (params, pageUrl) => {
    this.setState({
      prescriptions: null
    }, () => {
      this.getMyPrescriptions(params, pageUrl);
    })
  }

  renderMedicationItem(prescription) {
    return (prescription.medicines.map(medicine => this.renderMedicine(medicine)))
  }

  render() {
    const {prescriptions, refreshing} = this.state;

    const animationSize = 150;
    const animationOptions = {
      loop: true, autoplay: true, animationData: animationData, rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };

    return (<>
      <Subheader breadcrumbs={[{
        name: locales_es.prescriptions, href: HREF_PAGE_PRESCRIPTIONS
      }]}/>

      <>

        {prescriptions === null ? <Spinner/> : prescriptions && prescriptions.length ?
          <div className="row">
            <div className="col-12">
              {this.helpers.isMobile() ?
                <>
                  <div className="row">
                    {prescriptions.map((prescription, index) => {
                      return (<div key={`pcard-${index}`} className="col-xl-4 col-lg-6">
                        <div className="kt-portlet">
                          <div className="kt-widget kt-widget--general-2">
                            <div className="kt-portlet__body kt-portlet__body--fit">
                              <span className="kt-widget__top">
                                <div className="kt-media kt-media--lg">
                                  <img src={prescription.patient?.full_profile_image || ProfileImageDefault} alt=""/>
                                </div>
                                <div className="kt-widget__wrapper">
                                  <div className="kt-widget__label">
                                    <span className="kt-link kt-link--success">{locales_es.createdPrescription}</span>
                                    <span className="kt-widget__title">
                                    {prescription.patient?.name || prescription.patient?.lastname ? `${prescription.patient?.name} ${prescription.patient?.lastname}` : locales_es.deletedPatient}
                                  </span>
                                  </div>
                                </div>
                              </span>
                              <div className="kt-widget__bottom d-block">
                                <div className="row mb-3">
                                  <div className="col">
                                    <div
                                      className="kt-widget__caption">{locales_es.creationDate}:
                                    </div>
                                    {prescription.created_at && <span className="kt-widget__value"
                                                                      dangerouslySetInnerHTML={{
                                                                        __html: this.dateTimeService.parseEventDate(prescription.created_at, true, 'full-string')
                                                                      }}/>}
                                  </div>
                                </div>
                                <div className="row mb-3">
                                  <div className="col-auto">
                                    <a onClick={(e) => {
                                      e.preventDefault();
                                      this.loadPrescription(prescription);
                                    }}>
                                      <img src={pdfIcon} style={{height: 40, border: 0,}}/>
                                    </a>
                                  </div>
                                  <div className="col">
                                    <div
                                      className="kt-widget__caption">{locales_es.prescriptionDate}:
                                    </div>
                                    {prescription.date && <span className="kt-widget__value"
                                                                dangerouslySetInnerHTML={{
                                                                  __html: this.dateTimeService.parseEventDate(this.dateTimeService.parseAPIStringToDate(prescription.date), true)
                                                                }}/>}
                                  </div>
                                </div>
                                <div className="kt-widget__progress mb-3 w-100">
                                  {this.renderMedicationItem(prescription)}
                                </div>
                                <br/>
                                {(this.auth.getLocalUserType() === USER_TYPE_MEDIC || this.auth.getLocalUserType() === USER_TYPE_SECRETARY) &&
                                  <div className="kt-widget__actions clear">
                                    <a onClick={(e) => {
                                      e.preventDefault();
                                      this.loadPrescription(prescription);
                                    }}
                                       className={`btn ${prescription.payment_status === PAYMENT_STATUS_PENDING ? 'btn-warning' : 'btn-success'} btn-sm btn-bold btn-upper text-white cursor-pointer m-1`}>{locales_es.seePrescription}</a>
                                    <a onClick={(e) => {
                                      e.preventDefault();
                                      this.repeatPrescription(prescription);
                                    }}
                                       className="btn btn-primary btn-sm btn-bold btn-upper text-white cursor-pointer">{locales_es.repeatPrescription}</a>
                                  </div>}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>)
                    })}
                    {refreshing && <Spinner/>}
                  </div>
                </>
                :
                <>
                  <div className="row">
                    <div className="col">
                      <div className="kt-portlet kt-portlet--height-fluid overflow-auto">
                        <table className="woopi-table">
                          <thead>
                          <tr className="text-center">
                            <th>
                              <span>{locales_es.nameAndLastname}</span>
                            </th>
                            <th>
                              <span>{locales_es.prescriptionDate}</span>
                            </th>
                            <th>
                              <span>{locales_es.medications}</span></th>
                            <th>
                              <span>{locales_es.actions}</span></th>
                          </tr>
                          </thead>
                          <tbody className="kt-datatable__body ps ps--active-y">
                          {prescriptions.map((prescription, index) => {
                            return (
                              <tr key={`p-${index}`} className="">
                                <td>
                                  <div className="kt-user-card-v2">
                                    <div className="kt-user-card-v2__pic">
                                      <img src={prescription.patient?.full_profile_image || ProfileImageDefault}
                                           alt={locales_es.profileImage}/>
                                    </div>
                                    <div className="kt-user-card-v2__details">
                                      <span
                                        className="kt-user-card-v2__name">{prescription.patient?.name || prescription.patient?.lastname ? `${prescription.patient?.name} ${prescription.patient?.lastname}` : locales_es.deletedPatient}</span>
                                      <span className="kt-user-card-v2__email">{locales_es.createdPrescription}</span>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <a className="cursor-pointer" onClick={(e) => {
                                    e.preventDefault();
                                    this.loadPrescription(prescription);
                                  }}>
                                    <img src={pdfIcon} style={{height: 30, float: 'left', marginRight: 10}}/>
                                    {prescription.date ?
                                      <span className="kt-font-bold kt-widget__value"
                                            dangerouslySetInnerHTML={{
                                              __html: this.dateTimeService.parseEventDate(this.dateTimeService.parseAPIStringToDate(prescription.date), true)
                                            }}/>
                                      : locales_es.notDefined}
                                  </a>
                                </td>
                                <td>
                                  {this.renderMedicationItem(prescription)}
                                </td>
                                <td className="text-center">
                                  <span onClick={(e) => {
                                    e.preventDefault();
                                    this.loadPrescription(prescription);
                                  }}
                                     className={`btn ${prescription.payment_status === PAYMENT_STATUS_PENDING ? 'btn-warning' : 'btn-success'} btn-sm btn-bold btn-upper text-white cursor-pointer m-1`}>{locales_es.seePrescription}</span>
                                </td>
                              </tr>)
                          })}
                          </tbody>
                        </table>
                        {this.state.pagingData && <TablePaging pagingData={this.state.pagingData}
                                                               query={this.state.query}
                                                               onChange={(params, pageUrl) => this.onChangeTablePaging(params, pageUrl)}/>}
                      </div>
                    </div>

                  </div>
                </>
              }

            </div>
          </div> :
          <div className="text-center">
            <Lottie
              options={animationOptions}
              height={animationSize}
              width={animationSize}
            />
            <h3>No tienes recetas aún.</h3>
          </div>}
      </>
    </>)
  }
}
