import React, {Component} from "react";
import PrescriptionProfiles from "../prescriptionProfiles";
import ProfessionalRegistrations from "../professionalRegistrations";
import SignatureSettings from "../signatureSettings";

export default class PrescriptionSettings extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (<>
      <div className="row mb-4">
        <div className="col-lg-6">
          <SignatureSettings history={this.props.history} showMainModal={this.props.showMainModal} userData={this.props.userData} />
        </div>
        <div className="col-lg-6 mt-4 mt-md-0">
          <ProfessionalRegistrations history={this.props.history} showMainModal={this.props.showMainModal} />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <PrescriptionProfiles history={this.props.history} showMainModal={this.props.showMainModal} />
        </div>
      </div>
    </>)
  }
}
