import React, {useEffect, useState} from 'react';
import './styles.css';
import InputSpinner from 'react-bootstrap-input-spinner';
import locales_es from '../../locales/es';

const MedicineItem = ({
                        medication,
                        onSelectPresentation,
                        onRemoveItem,
                        onSelectMedicine,
                        onQuantityChange,
                        onSwitchPressOnlyGeneric,
                        onSwitchPressNoReplacement,
                        onSwitchPressNeedsDuplicate
                      }) => {

  const [shake, setShake] = useState(false);

  const selectPresentation = (medication, presentation) => {
    onSelectPresentation && onSelectPresentation(medication, presentation);
  }

  useEffect(() => {
    const handleShakeEvent = () => {
      setShake(true);
      setTimeout(() => setShake(false), 2000); // Duración de la animación
    };

    document.addEventListener('triggerShake', handleShakeEvent);

    return () => {
      document.removeEventListener('triggerShake', handleShakeEvent);
    };
  }, []);


  return (<div key={medication.commercial_name + medication.drug} className="medication-grid card" onClick={() => {
    onSelectMedicine && onSelectMedicine(medication);
  }}>
    {onRemoveItem && <button onClick={(e) => {
      e.stopPropagation();
      onRemoveItem();
    }} type="button"
                             className="btn btn-sm btn-outline-danger btn-elevate btn-circle btn-icon float-right medication-remove-button">
      <i className="fa fa-times"></i></button>}
    <div className="medication-grid-card">
      <div className="card-body">
        <h5
          className={`text-muted card-title ${medication.is_generic ? 'woopi-medication-line-through' : ''} ${medication.no_replacement ? 'woopi-medication-underline' : ''}`}>{medication.commercial_name}</h5>
        <h6 className="card-subtitle mb-2"><strong>Fármaco:</strong> {medication.drug}</h6>
        {/*<p className="card-text">
              {medication.need_duplicate ?
                `Necesita duplicado: Sí, Razón: ${medication.need_duplicate_reason || 'No especificada'}`
                : 'Necesita duplicado: No'}
            </p>*/}
      </div>
    </div>
    <div className="medication-grid-card">
      <div className="card-body pt-0 pb-0">
        <div className="card-text">
          {medication.presentation ? <><strong>Presentación:</strong>
            <ul className="chevron-list">
              <li key={medication.presentation.toString()}>{medication.presentation.name}</li>
            </ul>
          </> : medication.presentations && medication.presentations.length ? <>Presentaciones:
            <ul className="chevron-list">
              {medication.presentations.map((presentation, index) => (
                <li key={index}><a href="#" className="kt-link kt-font-bold cursor-pointer" onClick={(e) => {
                  e.preventDefault();
                  selectPresentation(medication, presentation);
                }}>{presentation.name}</a></li>))}
            </ul>
          </> : null}
        </div>
      </div>
    </div>

    {/* Switch para "Recetar sólo genérico" */}
    {onSwitchPressOnlyGeneric && <div className="row pl-3 pr-3">
      <label className="col-9 col-form-label">{locales_es.prescriptOnlyGeneric}</label>
      <div className="col-3 text-right">
          <span className="kt-switch kt-switch--icon">
            <label>
              <input type="checkbox" name="" checked={medication.is_generic} onChange={onSwitchPressOnlyGeneric}/>
              <span></span>
            </label>
          </span>
      </div>
    </div>}

    {/* Switch para "No sustituir" */}
    {onSwitchPressNoReplacement && <div className="row pl-3 pr-3">
      <label className="col-9 col-form-label">{locales_es.noReplacement}</label>
      <div className="col-3 text-right">
          <span className="kt-switch kt-switch--icon">
            <label>
              <input type="checkbox" name="" checked={medication.no_replacement} onChange={onSwitchPressNoReplacement}/>
              <span></span>
            </label>
          </span>
      </div>
    </div>}

    {/* Switch para "Por duplicado" */}
    {onSwitchPressNeedsDuplicate && <div className="row pl-3 pr-3">
      <label className="col-9 col-form-label">{locales_es.needsDuplicate}</label>
      <div className="col-3 text-right">
          <span className="kt-switch kt-switch--icon">
            <label>
              <input type="checkbox" name="" checked={medication.is_duplicated} onChange={onSwitchPressNeedsDuplicate}/>
              <span></span>
            </label>
          </span>
      </div>
    </div>}
    {!onSelectPresentation && !onSelectMedicine ? <div className="medication-grid-card justify-content-center">
      {onQuantityChange ? <div className={`woopi-input-spinner ${shake ? 'shake-effect' : ''}`}><InputSpinner
        type={'real'}
        max={10}
        min={1}
        step={1}
        value={medication.quantity}
        onChange={(num) => onQuantityChange(num)}
        variant={'success'}
        size="sm"
        style={{textAlign: 'center'}}
      /></div> : `Cantidad: ${medication.quantity}`}
    </div> : null}
  </div>);
};

export default MedicineItem;
