import React, {Component} from 'react';
import Spinner from "../spinner";
import locales_es from "../../locales/es";
import APIService from "../../modules/apiService";
import Helpers from "../../modules/helpers";
import AppointmentScheduled from "../appointmentScheduled";
import BankTransferData from "../bankTransferData";
import ExternalCheckoutData from "../externalCheckoutData";
import {CURRENCY_ID_ARS, CURRENCY_ID_USD} from "../../models/constants";

export default class PaymentOptionsModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      paymentMethods: null,
      bankTransferData: null,
      externalCheckoutData: null,
      medic: null,
    };

    this.api = new APIService();
    this.helpers = new Helpers();
  }

  componentDidMount() {
    if (this.props.selectedAppointment && this.props.selectedAppointment.id) {
      this.loadPaymentInfo()
    } else {
      this.setState({
        paymentMethods: false,
      })
    }
  }

  loadPaymentInfo() {
    this.api.getPaymentMethodsByAppointmentId(this.props.selectedAppointment.id).then(res => {
      if (res.data.length) {
        this.setState({
          paymentMethods: res.data,
          medic: this.props.selectedAppointment?.medic,
        })
        return;
      }
      this.showNoPaymentMethods();
    }).catch(err => {
      this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
    })
  }

  showNoPaymentMethods() {
    this.setState({
      paymentMethods: false,
    })
    this.props.showMainModal(locales_es.errorModal.title, locales_es.genericNoPaymentMethodsConfigured);
    this.props.acceptAction && this.props.acceptAction();
  }

  render() {

    const {paymentMethods, medic, bankTransferData, externalCheckoutData} = this.state;

    const {acceptAction, selectedAppointment} = this.props;

    const onBankTransferClick = (currencyId) => {
      this.setState({
        bankTransferData: true
      }, () => {
        if (currencyId === CURRENCY_ID_ARS) {
          this.api.getBankTransfer(this.state.medic.id).then(res => {
            this.setState({
              bankTransferData: {...res.data, currencyId}
            })
          }).catch(err => {
            this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
          })
        }
        if (currencyId === CURRENCY_ID_USD) {
          this.api.getBankTransferUSD(this.state.medic.id).then(res => {
            this.setState({
              bankTransferData: {...res.data, currencyId}
            })
          }).catch(err => {
            this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
          })
        }
      })
    };

    const onBankTransferClickCancel = () => {
      this.setState({
        bankTransferData: false,
      })
    };

    const onExternalCheckoutClick = (selectedGateway) => {
      this.setState({
        externalCheckoutData: selectedGateway
      })
    };

    const onExternalCheckoutClickCancel = () => {
      this.setState({
        externalCheckoutData: false,
      })
    };

    return (
      paymentMethods === null && !medic ? <Spinner/>
        : paymentMethods && paymentMethods.length && medic ?
        <>
          <AppointmentScheduled
            paymentMethods={paymentMethods}
            onContinue={() => acceptAction && acceptAction()}
            medic={medic}
            showMainModal={this.props.showMainModal}
            onBankTransferClick={onBankTransferClick}
            onExternalCheckoutClick={onExternalCheckoutClick}
          />

          {bankTransferData &&
          <BankTransferData bankTransferData={bankTransferData}
                            selectedAppointment={selectedAppointment}
                            onClickAcceptAction={() => acceptAction && acceptAction()}
                            onClickCancel={onBankTransferClickCancel}
          />}

          {externalCheckoutData &&
          <ExternalCheckoutData selectedGateway={externalCheckoutData}
                                selectedAppointment={selectedAppointment}
                                showMainModal={this.props.showMainModal}
                                onClickCancel={onExternalCheckoutClickCancel}
          />}

        </>
        : null
    )
  }
}
