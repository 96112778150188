import React, {Component} from 'react';
import APIService from "../../modules/apiService";
import Helpers from "../../modules/helpers";
import Subheader from "../../components/subheader";
import locales_es from "../../locales/es";
import {
  HREF_PAGE_MEDIC_ADD_TIMETABLE,
  HREF_PAGE_MEDICS,
  USER_TYPE_MEDIC,
  USER_TYPE_PATIENT,
  USER_TYPE_SECRETARY
} from "../../models/constants";
import AuthService from "../../modules/authService";
import Spinner from "../../components/spinner";
import MedicTimetables from "../../components/medicTimetables";
import EditMedicTimetable from "../../components/editMedicTimetables";

export default class MedicEditTimetablesPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      medicId: Number(props.match.params.id),
      timetableId: Number(props.match.params.timetableId),
      medic: null,
      userType: null
    };

    this.api = new APIService();
    this.helpers = new Helpers();
    this.auth = new AuthService();
  }

  componentWillMount() {
    this.setLocalUserType();
  }

  setLocalUserType() {
    const localUser = this.auth.getUserData();
    if (localUser && localUser.user) {
      this.setState({
        userType: localUser.user.user_type
      }, () => this.checkUserStatus());
    }
  }

  checkUserStatus() {
    const isLoggedIn = this.auth.isLoggedUser();
    if (!isLoggedIn || this.state.userType === USER_TYPE_PATIENT) {
      this.successLoginRedirect();
    }
  }

  componentDidMount() {
    this.api.getMedicById(this.state.medicId).then(res => {
      this.setState({
        medic: res.data
      });
    }).catch(err => {
      this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
    })
  }

  goToAddTimetable() {
    window.location.href = `${HREF_PAGE_MEDIC_ADD_TIMETABLE}/${this.state.medic.id}`;
  }

  render() {
    const {medic, timetableId, userType} = this.state;

    return (
      <>
        <Subheader breadcrumbs={[
          {
            name: locales_es.specialists,
            href: HREF_PAGE_MEDICS
          },
          {
            name: locales_es.specialistProfile,
            href: HREF_PAGE_MEDICS
          },
          medic ? {
            name: medic.name + ' ' + medic.lastname
          } : {}
        ]}/>
        <div className="kt-grid__item kt-grid__item--fluid kt-app__content">

          <div className="tab-content">
            <div className="tab-pane fade show active" id="kt_tabs_1_1" role="tabpanel">

              <div className="row">
                <div className="col-12">

                  <div className="kt-portlet kt-portlet--height-fluid">
                    <div className="kt-portlet__head justify-content-center justify-content-md-between">
                      <div className="kt-portlet__head-label d-xs-none">
                        <h3
                          className="kt-portlet__head-title">{timetableId ? locales_es.editTimetable : locales_es.timetables}</h3>
                      </div>
                      {!timetableId ?
                        <div className="pt-4 pb-4 mb-4">
                          <button type="button"
                                  onClick={() => this.goToAddTimetable()}
                                  className="btn btn-brand btn-elevate btn-pill mr-3">{locales_es.addLapseTime}</button>
                        </div>
                        : null}
                    </div>
                    <div
                      className="kt-portlet__body kt-portlet__body--fluid kt-portlet__body--fit">
                      <div className="kt-widget-2">
                        <div className="kt-widget-2__content kt-portlet__space-x">
                          {!medic ? <Spinner/> :
                            (userType === USER_TYPE_SECRETARY || userType === USER_TYPE_MEDIC) ?
                              timetableId ?
                                <EditMedicTimetable showMainModal={this.props.showMainModal}
                                                    userType={userType}
                                                    medic={medic}
                                                    timetableId={timetableId}/>
                                :
                                <MedicTimetables showMainModal={this.props.showMainModal}
                                                 medic={medic}
                                                 userType={userType}/>
                              :
                              null
                          }
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </div>


        </div>
      </>
    )
  }
}
