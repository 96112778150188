import React, {Component} from "react";
import Subheader from "../subheader";
import locales_es from "../../locales/es";
import {
  HREF_PAGE_DASHBOARD, HREF_PAGE_HOME,
} from "../../models/constants";
import APIService from "../../modules/apiService";
import Helpers from "../../modules/helpers";
import DateTimeService from "../../modules/DateTimeService";
import AuthService from "../../modules/authService";
import MedicAgenda from "../medicAgenda";
import Announcements from "../../components/announcements";
import ConfigService from "../../modules/configService";
import AppointmentsCalendar from "../appointmentsCalendar";

export default class DashboardMedic extends Component {

  constructor(props) {
    super(props);

    this.state = {
      nextAppointments: null, consultingRoomOptions: []
    };

    this.api = new APIService();
    this.helpers = new Helpers();
    this.dateTimeService = new DateTimeService();
    this.auth = new AuthService();
    this.configService = new ConfigService();
  }

  componentDidMount() {
    if (!this.auth.isLoggedUser()) {
      this.redirect();
      return;
    }
    this.api.getMedicById(this.auth.getUserData().user.id).then(res => {
      this.setState({
        medic: res.data
      }, () => {
        this.getConsultingRoomOptions();
      });
    }).catch(err => {
      this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
    });
  }

  redirect() {
    window.location.href = HREF_PAGE_HOME;
  }

  getConsultingRoomOptions() {
    this.configService.getLocalClinicData().then(clinic => {
      this.api.getConsultingRooms({clinic_id: clinic.id}).then(res => {
        this.setState({
          consultingRoomOptions: res.data
        });
      }).catch(err => {
        this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
      })
    }).catch(err => {
      console.log(err);
    });
  }

  render() {

    const {medic} = this.state;
    return (<>
        <Subheader breadcrumbs={[{
          name: locales_es.dashboard, href: HREF_PAGE_DASHBOARD
        }]}/>
        <div className="tab-content">
          <div className="tab-pane fade show active" id="kt_tabs_1_1" role="tabpanel">
            <div className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
              <div className="kt-grid__item kt-grid__item--fluid">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="kt-portlet" id="kt_portlet">
                      <div className="kt-portlet__head kt-portlet__head--lg">
                        <div className="kt-portlet__head-label">
                          <span className="kt-portlet__head-icon">
                            <i className="flaticon-calendar"/>
                          </span>
                          <h3 className="kt-portlet__head-title">
                            {locales_es.appointments}
                          </h3>
                        </div>
                      </div>
                      <div className="kt-portlet__body">
                        {this.state.medic && <AppointmentsCalendar location={this.props.location}
                                                                   medic={this.state.medic}
                                                                   timezone={medic.time_zone}
                                                                   showMainModal={this.props.showMainModal}/>}
                        {medic && <MedicAgenda medic={medic}
                                               userType={medic.user_type}
                        />}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Announcements userType={medic && medic.user_type}/>
      </>)
  }
}
