import React, {useRef, useState} from 'react';
import SignatureCanvas from 'react-signature-canvas';
import locales_es from '../../locales/es';
import './styles.css';

const SignaturePad = ({onSave, onTerminate}) => {
  const size = 400;
  const [penColor, setPenColor] = useState('blue');

  const sigCanvas = useRef({});

  const saveSignature = () => {
    const signature = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
    onSave(signature);
    onTerminate();
  };

  const clearSignature = () => {
    sigCanvas.current.clear();
  };

  return (
    <>
      <div className="text-center mt-5" style={{position: 'relative'}}>
        <h3 className="text-white p-3">Dibuje su firma en el siguiente recuadro</h3>
        <div className="penColorPicker">
          <button className={`penColorPickerBtn ${penColor === 'blue' ? 'selected' : ''}`}
                  style={{backgroundColor: 'blue'}} onClick={() => {
                  setPenColor('blue');
                  clearSignature();
          }}/>
          <button className={`penColorPickerBtn ${penColor === 'black' ? 'selected' : ''}`}
                  style={{backgroundColor: 'black'}} onClick={() => {
                  setPenColor('black');
                  clearSignature();
          }}/>
        </div>
        <SignatureCanvas
          ref={sigCanvas}
          penColor={penColor}
          canvasProps={{width: size, height: size, className: 'sigCanvas'}}
        />
      </div>
      <div className="text-center">
        <button type="button" className="btn btn btn-warning m-1" onClick={clearSignature}>{locales_es.clean}</button>
        <button className="btn btn-brand m-1" onClick={saveSignature}>{locales_es.save}</button>
        <button className="btn btn-danger m-1" onClick={onTerminate}>Cancelar</button>
      </div>
    </>
  );
};

export default SignaturePad;
