import React, {Component} from "react";
import APIService from "../../modules/apiService";
import Helpers from "../../modules/helpers";
import locales_es from "../../locales/es";
import AuthService from "../../modules/authService";
import {
  HREF_PAGE_HOME,
  USER_TYPE_MEDIC,
} from "../../models/constants";
import DateTimeService from "../../modules/DateTimeService";
import ViewHelpers from "../../modules/viewHelpers";
import Form from "../../components/form";
import AddPrescriptionProfileImages from "../../components/addPrescriptionProfileImages";
import Spinner from "../../components/spinner";

export default class AddPrescriptionProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prescriptionProfileId: props.match.params.id,
      name: null,
      profession: null,
      address: null,
      logoImage: null,
    };

    this.api = new APIService();
    this.helpers = new Helpers();
    this.auth = new AuthService();
    this.dateTimeService = new DateTimeService();
    this.viewHelpers = new ViewHelpers();
    this.perPageDefault = 5;

    this.formId = 'frm-submit';
    this.submitButtonId = 'btn-submit';
  }

  componentDidMount() {
    if (this.auth.getLocalUserType() !== USER_TYPE_MEDIC) {
      window.location.href = HREF_PAGE_HOME;
      return;
    }

    if (this.state.prescriptionProfileId) {
      this.api.getPrescriptionProfile(this.state.prescriptionProfileId).then(res => {
        this.setState({
          name: res.data.name,
          profession: res.data.profession,
          address: res.data.address,
          logoImage: res.data.full_logo_image || '',
          apiLogoImage: res.data.full_logo_image || '',
        })
      }).catch(err => {
        this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
      })
    } else {
      this.setState({
        logoImage: '',
        apiLogoImage: '',
      })
    }
  }

  handleChange = state => ev => {
    this.setState({[state]: ev.target.value});
  };

  handleDateChange = state => value => {
    this.setState({[state]: value});
  };

  handleReactSelectChange = state => value => {
    this.setState({[state]: value});
  };

  onCropComplete = (imageOrNull) => {
    this.setState({
      logoImage: imageOrNull
    })
  }

  validateForm() {
    let errors = 0;
    if (!this.state.name
      || !this.state.profession
      || !this.state.address
    ) {
      errors += 1;
      this.props.showMainModal(locales_es.errorModal.title, locales_es.errorModal.completeAllFormFields);
    }

    return !errors;
  }

  checkUploadImageAndFinish() {
    if (this.state.logoImage && this.state.logoImage !== this.state.apiLogoImage) {
      this.api.postLogoImageBase64(this.state.prescriptionProfileId, this.state.logoImage).then(() => {
        // this.props.showMainModal(locales_es.successModal.title, res.message);
        this.confirmTestProfile();
      }).catch(err => {
        this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
      });
    } else if (!this.state.logoImage && this.state.logoImage !== this.state.apiLogoImage) {
      this.api.deleteLogoImage(this.state.prescriptionProfileId).then(() => {
        // this.props.showMainModal(locales_es.successModal.title, res.message);
        this.confirmTestProfile();
      }).catch(err => {
        this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
      });

    } else {
      this.confirmTestProfile();
    }
  }

  finish() {
    this.props.history.goBack();
  }

  testProfile() {
    this.api.getPrescriptionTestProfile(this.state.prescriptionProfileId).then(res => {
      // window.open(res.data.url, '_blank')
      this.props.showMainModal(locales_es.successModal.title, locales_es.successTestProfileSubtitle, null, [{
        label: locales_es.seePrescription,
        method: () => window.open(res.data.url, '_blank')
      }]);
      this.finish();
    }).catch(err => {
      this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
    })
  }

  confirmTestProfile() {
    this.props.showMainModal('¿Querés probar cómo quedó tu receta?', 'Podés generar una receta de prueba para ver cómo queda :)', null, [
      {
        label: 'Sí, continuar',
        class: 'btn btn-primary btn-elevate btn-pill btn-sm',
        method: () => {
          setTimeout(() => {
            this.testProfile();
          }, 1500);
        }
      },
      {
        label: 'No, gracias',
        class: 'btn btn-warning btn-elevate btn-pill btn-sm',
        method: () => this.finish()
      }
    ]);
  }

  send() {
    if (!this.state.logoImage) {
      this.props.showMainModal('No subiste un encabezado', '¿Estás seguro de continuar sin un encabezado para tu receta?', null, [
        {
          label: 'Sí, continuar',
          class: 'btn btn-danger btn-elevate btn-pill btn-sm',
          method: () => {
            setTimeout(() => this.confirmSend(), 1500);
          }
        },
        {
          label: 'No, cancelar',
          class: 'btn btn-primary btn-elevate btn-pill btn-sm'
        }
      ]);
    } else {
      this.confirmSend();
    }
  }

  confirmSend() {
    if (this.validateForm()) {
      const objData = {
        name: this.state.name,
        profession: this.state.profession,
        address: this.state.address
      };
      if (this.state.prescriptionProfileId) {
        this.api.putPrescriptionProfile(this.state.prescriptionProfileId, objData).then(() => {
          this.checkUploadImageAndFinish();
          // this.props.showMainModal(locales_es.successModal.title, res.message);
        }).catch(err => {
          this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
        })
      } else {
        this.api.postPrescriptionProfile(objData).then(res => {
          this.setState({
            prescriptionProfileId: res.data.id
          }, () => this.checkUploadImageAndFinish())
          // this.props.showMainModal(locales_es.successModal.title, res.message);
        }).catch(err => {
          this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
        })
      }
    }
  }

  submitForm() {
    const btn = document.getElementById(this.submitForm);
    if (btn) {
      btn.click();
    }
  }

  render() {
    const {prescriptionProfileId} = this.state;
    const inputs = [
      {
        label: locales_es.prescriptionProfileName,
        placeholder: locales_es.prescriptionProfileNamePlaceholder,
        id: 1,
        state: 'name',
        value: this.state.name,
        type: 'text',
        required: true,
        wrapperCustomClassName: 'form-group col-12 pl-md-0',
      },
      {
        label: locales_es.prescriptionProfileProfession,
        placeholder: locales_es.prescriptionProfileProfessionPlaceholder,
        id: 2,
        state: 'profession',
        value: this.state.profession,
        type: 'text',
        required: true,
        wrapperCustomClassName: 'form-group col-12 pl-md-0',
      },
      {
        label: locales_es.prescriptionProfileAddress,
        placeholder: locales_es.prescriptionProfileAddressPlaceholder,
        id: 3,
        state: 'address',
        value: this.state.address,
        type: 'text',
        required: true,
        wrapperCustomClassName: 'form-group col-12 float-left pl-md-0',
      },
    ];

    return (<>
      <div className="kt-container">
        <div className="kt-grid kt-grid--desktop kt-grid--ver kt-grid--ver-desktop kt-app">
          <div className="kt-grid__item kt-grid__item--fluid kt-app__content">
            <div className="row bg-white">
              {/* Datos básicos de la receta */}
              <div className="col-12 col-lg-6 p-lg-5 pt-5">
                <Form
                  id={this.formId}
                  styles="kt-form"
                  inputs={inputs}
                  handleChange={this.handleChange}
                  handleDateChange={this.handleDateChange}
                  handleReactSelectChange={this.handleReactSelectChange}
                  // onSubmit={() => this.send()}
                  showTerms={false}
                  wrapper={true}
                >
                  <div className="kt-portlet__head">
                    <div className="kt-portlet__head-label">
                      <h3 className="kt-portlet__head-title">{locales_es.addPrescriptionProfile}</h3>
                    </div>
                  </div>
                </Form>
              </div>

              {/* Imagen de encabezado de la receta */}
              <div className="col-12 col-lg-6 p-lg-5">
                {this.state.logoImage !== null ? <AddPrescriptionProfileImages onCropComplete={(imageOrNull) => this.onCropComplete(imageOrNull)} image={this.state.logoImage}/> : <Spinner />}
              </div>
              <div className="w-100 justify-content-center text-center bg-white mb-3">
                <button type="submit"
                        id={this.submitButtonId}
                        form={this.formId}
                        onClick={() => this.send()}
                        className="btn btn-brand btn-elevate btn-pill m-3 align-self-start">{prescriptionProfileId ? locales_es.save : locales_es.send}</button>
                <a onClick={(e) => {
                  e.preventDefault();
                  this.props.history.goBack()
                }}
                   style={{marginRight: '10px'}}
                   className={"cursor-pointer kt-link kt-link--brand m-3 " + this.props.secondaryButtonStyle}>
                  {locales_es.cancel}
                </a>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>)
  }
}
