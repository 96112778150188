import React, {Component} from "react";
import APIService from "../../modules/apiService";
import Helpers from "../../modules/helpers";
import Spinner from "../../components/spinner";
import locales_es from "../../locales/es";
import AuthService from "../../modules/authService";
import {
  HREF_PAGE_DOCUMENT_VISOR,
  HREF_PAGE_HOME,
  HREF_PAGE_SETTINGS,
  PRESCRIPTION_CERTIFICATE_TYPE,
  PRESCRIPTION_ERROR_INVALID_CREDENTIAL,
  USER_TYPE_MEDIC,
} from "../../models/constants";
import DateTimeService from "../../modules/DateTimeService";
import ViewHelpers from "../../modules/viewHelpers";
import NoSignature from "../../lotties/no-signature";
import NoProfessionalRegistrations from "../../lotties/no-professional-registrations";
import NoPrescriptionProfiles from "../../lotties/no-prescription-profiles";
import Form from "../../components/form";
import PrescriptionProfile from "../../components/prescriptionProfile";
import DatePrescriptionHandler from "../../components/datePrescriptionHandler";
import PrescriptionProfileService from "../../modules/prescriptionProfileService";
import Loading from "../../components/loading";
import FullscreenProgress from "../../components/FullscreenProgress";
import FullscreenGreenCheck from "../../components/FullscreenGreenCheck";

export default class AddCertificate extends Component {
  constructor(props) {
    super(props);

    this.api = new APIService();
    this.helpers = new Helpers();
    this.auth = new AuthService();
    this.dateTimeService = new DateTimeService();
    this.viewHelpers = new ViewHelpers();
    this.prescriptionProfileService = new PrescriptionProfileService();
    this.perPageDefault = 10;

    this.state = {
      loading: false,
      loadingText: '',
      alreadySent: false, // saber si la receta tiene datos cargados y pedir confirmación al usuario si quiere salir
      progress: 0,
      congrats: false,
      errors: [],
      patientId: this.props.patientId || this.props.location?.state?.patientId || props.match.params.patientId || null,
      prescriptionProfileId: this.props.prescriptionProfileId || this.props.location?.state?.prescriptionProfileId || this.prescriptionProfileService.getProfileId(),
      missing: null,
      professionalRegistrations: null,
      professionalRegistrationId: this.props.professionalRegistrationId || this.props.location?.state?.professionalRegistrationId || null,
      patientMedicalInsuranceId: this.props.patientMedicalInsuranceId || this.props.location?.state?.patientMedicalInsuranceId || null,
      medicines: this.props.medicines || this.props.location?.state?.medicines || [],
      text: this.props.text || this.props.location?.state?.text || '',
      indications: this.props.indications || this.props.location?.state?.indications || '',
    };

  }


  componentDidMount() {
    if (this.auth.getLocalUserType() !== USER_TYPE_MEDIC) {
      window.location.href = HREF_PAGE_HOME;
      return;
    }
    this.checkIfPrescriptionsEnabled();
    window.addEventListener('beforeunload', this.handleBeforeUnload);
    this.checkPreloadedForRepeatedPrescription();
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  }

  handleBeforeUnload = (e) => {
    if (!this.state.alreadySent) {
      const message = 'Tienes cambios sin guardar. ¿Estás seguro de que quieres salir?';
      e.returnValue = message; // Compatible con algunos navegadores antiguos
      return message; // Chrome requiere que esto se devuelva explícitamente
    }
  };

  checkPreloadedForRepeatedPrescription() {
    if (this.state.patientId) {
      this.api.getPatient(this.state.patientId).then(res => {
        this.selectPatient(res.data)
      }).catch(err => {
        this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
      })
    }
  }

  checkIfPrescriptionsEnabled() {
    this.getSignature();
  }

  getSignature() {
    this.api.getUserMe().then(res => {
      if (res.data.user?.full_signature) {
        this.setState({
          signature: res.data.user?.full_signature
        }, () => {
          this.getProfessionalRegistrationJurisdictions();
        });
      } else {
        this.setState({
          missing: 'signature'
        });
      }
    }).catch(err => {
      this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
    })
  }

  getMyProfessionalRegistrations() {
    this.api.getMyProfessionalRegistrations().then(res => {
      if (res.data.length) {
        const professionalRegistrations = res.data;
        this.setState({
          professionalRegistrations: professionalRegistrations.map(registration => {
            const registerType = this.state.professionalRegistrationsTypes.filter(type => type.id === registration.professional_registration_type_id);
            const jurisdiction = this.state.professionalRegistrationsJurisdictions.filter(type => type.id === registration.professional_registration_jurisdiction_id);
            registration.name = `${registerType[0].name} - ${jurisdiction[0].name} - Nº ${registration.number}`;
            registration.value = registration.id;
            return registration;
          }),
          professionalRegistrationId: this.state.professionalRegistrationId || res.data[0].id,
        }, () => {
          this.getMyPrescriptionProfiles();
        })
      } else {
        this.setState({
          missing: 'professionalRegistrations'
        })
      }
    }).catch(err => {
      this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
    });
  }

  getMyPrescriptionProfiles() {
    this.api.getMyPrescriptionProfiles().then(res => {
      if (res.data.length) {
        this.setState({
          prescriptionProfiles: res.data,
          prescriptionProfileId: this.state.prescriptionProfileId || res.data[0].id,
          missing: false,
        })
      } else {
        this.setState({
          missing: 'prescriptionProfiles'
        })
      }
    }).catch(err => {
      this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
    });
  }

  getProfessionalRegistrationJurisdictions() {
    this.api.getProfessionalRegistrationJurisdictions().then(res => {
      this.setState({
        professionalRegistrationsJurisdictions: res.data,
      }, () => {
        this.getProfessionalRegistrationsTypes();
      })
    }).catch(err => {
      this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
    });
  }

  getProfessionalRegistrationsTypes() {
    this.api.getProfessionalRegistrationsTypes().then(res => {
      this.setState({
        professionalRegistrationsTypes: res.data,
      }, () => {
        this.getMyProfessionalRegistrations();
      })
    }).catch(err => {
      this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
    });
  }

  handleChange = state => ev => {
    if (state === "prescriptionProfileId") {
      this.prescriptionProfileService.setProfileId(ev.target.value);
    }
    this.setState({[state]: ev.target.value});
  };

  handleDateChange = state => value => {
    this.setState({[state]: value});
  };

  handleReactSelectChange = state => value => {
    this.setState({[state]: value});
  };

  onChangeDates(dates) {
    this.setState({
      dates,
    })
  }

  goToSettings(e) {
    if (e) {
      e.preventDefault();
    }
    this.props.history.push(HREF_PAGE_SETTINGS);
  }

  onSearchSubmit(ev) {
    if (ev && ev.preventDefault) {
      ev.preventDefault();
    }
    const form = document.getElementById('searchForm');
    const query = form.children[0].value;

    this.setState({
      query, patients: null
    }, () => {
      if (!query) {
        this.setState({
          query,
        })
        return;
      }

      setTimeout(() => {
        if (this.state.query.length === query.length) {
          this.api.searchPatients({q: query, per_page: this.state.per_page ?? this.perPageDefault}).then(res => {
            const pagingData = JSON.parse(JSON.stringify(res));
            delete pagingData.data;
            const _patients = JSON.parse(JSON.stringify(this.state.patients));
            this.setState({
              patients: _patients ? _patients.concat(res.data) : res.data,
              pagingData,
              refreshing: false,
            });
          }).catch(err => {
            this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
          })
        } else {
          // setSearchResults([]);
        }
      }, 500); // 800
    })
  }

  onSearchClear(ev) {
    if (ev && ev.preventDefault) {
      ev.preventDefault();
    }
    const form = document.getElementById('searchForm');
    form.children[0].value = '';
  }

  selectPatient(patient) {
    this.setState({
      patientData: patient, query: '', patients: [],
      patientId: patient.id,
    }, () => {
      this.getPatientMedicalInsurances();
    })
  }

  getPatientMedicalInsurances() {
    this.api.getMedicalInsuranceByPatient(this.state.patientData.id).then(res => {
      const medicalInsurances = [{id: null, name: locales_es.noMedicalInsurance}, ...res.data.map(insurance => {
        return ({
          id: insurance.id, name: `${insurance.medical_insurance.name} - ${insurance.plan} - N°${insurance.number}`
        })
      })];
      this.setState({
        patientMedicalInsurances: medicalInsurances,
        patientMedicalInsuranceId: this.state.patientMedicalInsuranceId || medicalInsurances[0].id,
      })
    }).catch(err => {
      this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
    })
  }

  clearPatientData() {
    this.setState({
      patientData: null, patientMedicalInsurances: null, patientMedicalInsuranceId: null,
    })
  }

  setErrors(errors) {
    this.setState({
      errors
    })
  }

  onBlur() {
    this.setErrors([])
  }

  onSend() {
    const errors = this.helpers.validateEmptyFields({
      prescriptionProfileId: this.state.prescriptionProfileId,
      patientId: this.state.patientId,
      text: this.state.text,
      // indications,
    });
    if (errors.length) {
      this.setErrors(errors);
      this.props.showMainModal(locales_es.infoModal.title, locales_es.infoModal.checkData);
    } else {
      this.confirmSend();
    }
  }

  setLoading(bool) {
    this.setState({
      loading: bool
    })
  }

  setAlreadySent(bool) {
    this.setState({
      alreadySent: bool
    })
  }

  setCongrats(bool) {
    this.setState({
      congrats: bool
    })
  }

  setProgress(float) {
    this.setState({
      progress: float
    })
  }

  setLoadingText(str) {
    this.setState({
      loadingText: str
    })
  }

  setPrescriptionError(str) {
    this.setState({
      prescriptionError: str
    })
  }

  showProgress(total, current) {
    this.setProgress(((current * 100) / total / 100).toFixed(1));
  }

  confirmSend = async mode => {
    const hasShowProgress = this.state.dates.length === 1;
    hasShowProgress ? this.setLoading(true) : this.setProgress(0.001);
    const plural = this.state.dates.length > 1;
    // props.navigation.setOptions({headerShown: false});
    this.setLoadingText(plural ? locales_es.creatingPrescriptions + '...' : locales_es.creatingPrescription + '...',);
    const documents = [];
    for (const date of this.state.dates) {
      const result = await this.postAPIPrescription(date, mode);
      documents.push({
        pdfSource: {uri: result.data.full_file_url}, certificateId: result.data.id,
      });
      this.showProgress(this.state.dates.length, documents.length);
      if (documents.length === this.state.dates.length) {
        this.setProgress(0);
        this.setAlreadySent(true);
        this.setLoading(false);
        this.setLoadingText('');
        this.setCongrats(true);
        this.setLoadingText(plural ? locales_es.createdCertificates : locales_es.createdCertificate);

        setTimeout(() => {
          this.props.history.push(HREF_PAGE_DOCUMENT_VISOR, {
            documents,
            prescriptionType: PRESCRIPTION_CERTIFICATE_TYPE,
            emails:
              result.data.patient && result.data.patient.user && result.data.patient.user.email
                ? [result.data.patient.user.email]
                : null,
          });
          this.setCongrats(false);
        }, 6000);
      }
    }
  };

  postAPIPrescription = (date) => {
    return new Promise((resolve, reject) => {
      const objData = {
        professional_registration_id: this.state.professionalRegistrationId,
        prescription_profile_id: this.state.prescriptionProfileId,
        patient_id: this.state.patientId,
        medical_insurance_user_patient_id: this.state.patientMedicalInsuranceId,
        date: this.dateTimeService.parseDateToAPIString(new Date(date)),
        text: this.state.text,
        indications: this.state.indications,
      };
      this.api
        .postCertificate(objData)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          // props.navigation.setOptions({headerShown: true});
          this.setLoading(false);
          this.setLoadingText('');
          this.setProgress(0);
          this.setLoading(false);
          this.setLoadingText('');
          if (err.response?.status === 406 && err.response?.data?.error_code === PRESCRIPTION_ERROR_INVALID_CREDENTIAL) {
            this.setPrescriptionError(err.response?.data?.message || true);
          } else {
            this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err),);
          }
          reject(err);
        });
    });
  }

  render() {
    const {
      loading,
      loadingText,
      progress,
      congrats,
      errors,
      missing,
      prescriptionProfiles,
      prescriptionProfileId,
      patientId,
      patientData,
      patients,
      patientMedicalInsurances,
    } = this.state;

    const inputs = [
      {
        label: locales_es.prescriptionProfile,
        placeholder: locales_es.prescriptionProfile,
        id: 1,
        state: 'prescriptionProfileId',
        value: this.state.prescriptionProfileId,
        type: 'select',
        required: true,
        options: this.state.prescriptionProfiles,
        wrapperCustomClassName: 'form-group col-12 pl-md-0',
      },
      {
        label: locales_es.professionalRegistration,
        placeholder: locales_es.professionalRegistration,
        id: 2,
        state: 'professionalRegistrationId',
        value: this.state.professionalRegistrationId,
        type: 'select',
        required: true,
        options: this.state.professionalRegistrations,
        wrapperCustomClassName: 'form-group col-12 pl-md-0',
      },
    ];

    const inputsPatientMedicalInsurances = [{
      label: locales_es.patientMedicalInsurances,
      placeholder: locales_es.medicalInsurances,
      id: 1,
      state: 'patientMedicalInsuranceId',
      value: this.state.patientMedicalInsuranceId,
      type: 'select',
      required: true,
      options: this.state.patientMedicalInsurances,
      wrapperCustomClassName: 'form-group col-12 pl-md-0',
    },];

    const inputsGlobals = [{
      label: locales_es.freeText,
      placeholder: locales_es.addPrescriptionFreeTextPlaceholder,
      id: 1,
      state: 'text',
      value: this.state.text,
      type: 'textarea',
      required: true,
      onBlur: () => this.onBlur(),
      wrapperCustomClassName: 'form-group col-12 pl-md-0',
      customClassName: `${errors.includes('text') ? 'is-invalid' : ''}`,
    }, {
      label: locales_es.indications,
      placeholder: locales_es.addPrescriptionIndications,
      id: 2,
      state: 'indications',
      value: this.state.indications,
      type: 'textarea',
      required: false,
      onBlur: () => this.onBlur(),
      wrapperCustomClassName: 'form-group col-12 pl-md-0',
    }];

    return (<>
      <div className="kt-container">
        {progress ? (
          <FullscreenProgress progress={progress} text={loadingText}/>
        ) : null}
        {congrats && (
          <FullscreenGreenCheck
            text={locales_es.createdPrescriptionSuccessfully}
          />
        )}
        {loading ? <Loading text={loadingText}/> : null}
        <div className="kt-grid kt-grid--desktop kt-grid--ver kt-grid--ver-desktop kt-app">
          <div className="kt-portlet">
            {missing === null ? <Spinner/> : missing === 'signature' ? <><NoSignature/>
              <div className="text-center p-4"><h4 className="mt-3">{locales_es.noSignature}</h4>
                <p className="mt-4">
                  <div className="card-body text-center">
                    <button onClick={(e) => this.goToSettings(e)}
                            className={`btn btn-focus`}>Configurar la firma
                    </button>
                  </div>
                </p>
              </div>
            </> : missing === 'professionalRegistrations' ? <><NoProfessionalRegistrations/>
              <div className="text-center p-4"><h4 className="mt-3">{locales_es.noProfessionalRegistrations}</h4>
                <p className="mt-4">
                  <div className="card-body text-center">
                    <button onClick={(e) => this.goToSettings(e)}
                            className={`btn btn-info btn-upper btn-bold kt-align-center`}>{locales_es.addProfessionalRegistrationJurisdiction}
                    </button>
                  </div>
                </p>
              </div>
            </> : missing === 'prescriptionProfiles' ? <><NoPrescriptionProfiles/>
                <div className="text-center p-4"><h4 className="mt-3">{locales_es.noPrescriptionProfiles}</h4>
                  <p className="mt-4">
                    <div className="card-body text-center">
                      <button onClick={(e) => this.goToSettings(e)}
                              className={`btn btn-info btn-upper btn-bold kt-align-center`}>{locales_es.addPrescriptionProfile}
                      </button>
                    </div>
                  </p>
                </div>
              </>
              :
              <>
                <div className="row bg-white">
                  <div className="col">
                    <div className="kt-portlet__head">
                      <div className="kt-portlet__head-label">
                        <h3 className="kt-portlet__head-title">{locales_es.addAMedicalCertificate}</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row bg-white">
                  <div className="col-12 col-lg-6 pt-2">
                    <Form
                      styles="kt-form"
                      inputs={inputs}
                      handleChange={this.handleChange}
                      handleDateChange={this.handleDateChange}
                      handleReactSelectChange={this.handleReactSelectChange}
                      showTerms={false}
                      wrapper={true}
                    />
                    <div className="pl-4 pr-4 pb-4">
                      {prescriptionProfileId && prescriptionProfiles.length && prescriptionProfiles.map(profile => Number(profile.id) === Number(prescriptionProfileId) ?
                        <PrescriptionProfile readOnly
                                             profile={profile}
                                             showMainModal={this.props.showMainModal}/> : null)}
                    </div>
                  </div>
                  <div className="col-12 col-lg-6 pt-2">
                    <DatePrescriptionHandler showMainModal={this.props.showMainModal}
                                             onChangeDates={(dates) => this.onChangeDates(dates)}
                                             singleDate={true}
                    />
                  </div>
                </div>
                <div className="row bg-white">
                  <div className="col col-lg-6 p-4">
                    <label>{patientId ? locales_es.patient : `${locales_es.choosePatient}...`}</label>
                    <div className="kt-portlet kt-widget-15 mt-2 p-4 cursor-pointer">
                      <div className="kt-widget-15__body">
                        {patientData ? <>
                          <div className="kt-widget-15__author">
                            <div className="kt-widget-15__photo">
                              <img src={patientData.full_profile_image}/>
                            </div>
                            <div className="kt-widget-15__detail">
                              <a href="#"
                                 onClick={(e) => e.preventDefault()}
                                 className="kt-widget-15__name">{patientData.name} {patientData.lastname}</a>
                              <div className="kt-widget-15__desc">
                                {locales_es.patient}
                              </div>
                            </div>
                          </div>
                          <div className="kt-widget-15__wrapper">
                            <div className="kt-widget-15__info justify-content-center">
                              <button onClick={() => this.clearPatientData()} type="button"
                                      className="btn btn-sm btn-focus btn-elevate btn-pill btn-danger m-1">
                                <i className="fa fa-times"/> {locales_es.removePatient}
                              </button>
                            </div>
                          </div>
                        </> : <form onSubmit={(e) => this.onSearchSubmit(e)}
                                    className="kt-input-icon kt-input-icon--right" id="searchForm">
                          <input className={`form-control ${errors.includes('patientId') ? 'is-invalid' : ''}`}
                                 type="search"
                                 onChange={(e) => this.onSearchSubmit(e)}
                                 onBlur={() => this.onBlur()}
                                 placeholder={this.helpers.isMobile() ? locales_es.searchPatients : locales_es.searchByPatientsNameOrLastname}/>
                          {errors.includes('patientId') ? null : this.state.query
                            ? <span onClick={(e) => {
                              this.onSearchClear(e);
                              this.onSearchSubmit(e)
                            }}
                                    className="kt-input-icon__icon kt-input-icon__icon--right">
                              <span><i className="la la-close"/></span>
                            </span>
                            : <span onClick={(e) => this.onSearchSubmit(e)}
                                    className="kt-input-icon__icon kt-input-icon__icon--right">
                                    <span><i className="la la-search"/></span>
                            </span>
                          }
                        </form>}
                      </div>
                      {this.state.query ? patients === null ? <div className="row">
                        <div className="col text-center">
                          <Spinner/>
                        </div>
                      </div> : patients && patients.length ? <div className="kt-widget-15__body justify-content-around">
                        {patients.map(patient => <div onClick={() => this.selectPatient(patient)}
                                                      className="kt-widget-15__author w-100 m-3 p-1">
                          <div className="kt-widget-15__photo">
                            <img src={patient.full_profile_image}
                                 alt="" title=""/>
                          </div>
                          <div className="kt-widget-15__detail">
                            <span className="kt-widget-15__name">{patient.name} {patient.lastname}</span>
                            <div className="kt-widget-15__desc">
                              {locales_es.patient}
                            </div>
                          </div>
                        </div>)}
                      </div> : <div className="row">
                        <div className="col text-center p-3">No se encontraron pacientes</div>
                      </div> : null}
                    </div>
                  </div>
                  {patientData && <div className="col col-lg-6 p-4">
                    {patientMedicalInsurances === null ?
                      <Spinner/> : patientMedicalInsurances && patientMedicalInsurances.length ? <Form
                        styles="kt-form"
                        inputs={inputsPatientMedicalInsurances}
                        handleChange={this.handleChange}
                        handleDateChange={this.handleDateChange}
                        handleReactSelectChange={this.handleReactSelectChange}
                        showTerms={false}
                        wrapper={true}
                      /> : null}
                  </div>}
                </div>

                {/* RESTO DE LOS CAMPOS */}
                <div className="row bg-white">
                  <div className="col">
                    <Form
                      styles="kt-form"
                      inputs={inputsGlobals}
                      handleChange={this.handleChange}
                      handleDateChange={this.handleDateChange}
                      handleReactSelectChange={this.handleReactSelectChange}
                      showTerms={false}
                      wrapper={true}
                    />
                  </div>
                </div>

                {/* SEND BUTTON */}
                <div className="row bg-white p-5">
                  <div className="col text-center">
                    <button onClick={() => this.onSend()}
                            className="btn btn-brand btn-sm btn-bold btn-upper">{locales_es.createCertificate}</button>
                  </div>
                </div>
              </>}
          </div>
        </div>
      </div>
    </>)
  }
}
