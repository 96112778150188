import React, {Component} from "react";
import Subheader from "../../components/subheader";
import locales_es from "../../locales/es";
import {HREF_PAGE_HELP, HREF_PAGE_HOME} from "../../models/constants";
import Helpers from "../../modules/helpers";
import APIService from "../../modules/apiService";
import AuthService from "../../modules/authService";
import Spinner from "../../components/spinner";

export default class HelpPage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      categories: null,
      category: null,
      articles: null,
    };

    this.helpers = new Helpers();
    this.api = new APIService();
    this.auth = new AuthService();
  }

  componentDidMount() {
    if (!this.auth.isLoggedUser()) {
      this.redirect();
      return;
    }
    this.api.getGuideCategories().then(res => {
        if (res.data && res.data.length) {
          const categories = res.data;
          res.data.forEach((category, index) => {
            this.api.getArticlesByGuideCategory(category.id).then(res => {
              categories[index].articles = res.data;
              this.setState({
                categories: categories
              });
            }).catch(err => {
              this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
            });
          });
        } else {
          this.setState({
            categories: []
          });
        }
    }).catch(err => {
      this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
    });
  }

  redirect() {
    window.location.href = HREF_PAGE_HOME;
  }

  render() {
    const {categories} = this.state;
    return (
      <>
        <Subheader breadcrumbs={[{
          name: locales_es.help, href: HREF_PAGE_HELP
        }]}/>
        <div className="kt-container  kt-grid__item kt-grid__item--fluid">
          {categories === null ? <Spinner/> :
            categories && categories.length ?
              categories.map(category => <div className="kt-portlet kt-portlet--height-fluid kt-widget-17">
                <div className="row">
                  <div className="col-12">
                    <div className="kt-portlet mb-0">
                      <div className="kt-portlet__head">
                        <div className="kt-portlet__head-label">
                          {category === null ? <Spinner/> : category && category.name ?
                            <h3 className="kt-portlet__head-title">{category.name}</h3> :
                            <h4 className="text-center">No se encontró una categoría de ayuda.</h4>}
                        </div>
                      </div>
                      <div className="kt-portlet__body">
                        {category.articles === null ? <Spinner/> :
                          category.articles && category.articles.length ?
                            category.articles.map(article =>
                              <div className="accordion accordion-outline" id={`accordionExample${article.id}`}>
                                <div className="card">
                                  <div className="card-header" id="headingOne6">
                                    <div className="card-title collapsed" data-toggle="collapse"
                                         data-target={`#collapseOne${article.id}`}
                                         aria-expanded="false" aria-controls={`collapseOne${article.id}`}>
                                      <i className="flaticon-folder-1"></i> {article.title}
                                    </div>
                                  </div>
                                  <div id={`collapseOne${article.id}`} className="card-body-wrapper collapse"
                                       aria-labelledby="headingOne6" data-parent={`#accordionExample${article.id}`}>
                                    <div className="card-body">
                                  <span dangerouslySetInnerHTML={
                                    {
                                      __html: article.content
                                    }
                                  }/>
                                      {article.video_url ?
                                        <div className="mt-3">
                                          <a href={article.video_url} target="_blank" type="button"
                                             className="btn btn-outline-brand btn-elevate btn-pill">
                                            <i class="la la-youtube-play"></i> {locales_es.watchVideo}
                                          </a>
                                        </div>
                                        : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                            :
                            <h4>No se encontraron artículos en esta categoría.</h4>
                        }


                      </div>
                    </div>
                  </div>
                </div>
              </div>)
              : <h1>No se encontraron categorías de ayuda.</h1>
          }
        </div>
      </>
    )
  }
}
