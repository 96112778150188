// src/utils/timeUtils.js

export const getStoredTime = (key, defaultValue, asString = false) => {
  const storedValue = localStorage.getItem(key);
  if (storedValue) {
    if (asString) {
      return storedValue;
    }
    const [hours, minutes] = storedValue.split(':').map(Number);
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  } else {
    if (asString) {
      return defaultValue;
    }
    const [hours, minutes] = defaultValue.split(':').map(Number);
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  }
};
