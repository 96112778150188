import React, {Component} from "react";
import {
  HREF_PAGE_HOME,
  STATUS_FAILED,
  STATUS_SUCCESS,
  USER_TYPE_SECRETARY
} from "../../models/constants";
import AuthService from "../../modules/authService";
import APIService from "../../modules/apiService";
import Helpers from "../../modules/helpers";
import locales_es from "../../locales/es";
import "./styles.css";
import Loading from "../../components/loading";
import ModalAnimationResult from "../../components/modalAnimationResult";
import Form from "../../components/form";
import ViewHelpers from "../../modules/viewHelpers";
import ConfigService from "../../modules/configService";

export default class ClinicSettingsPage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedTab: this.getPreselectedTab(),
      loading: true,
      postServiceId: null, // usamos esto para saber si es la primera vez que el usuario configura esto
      // porque si es la primera vez, hay que hacer POST, y se puede hacer DELETE, pero sino, hay que hacer PUT
      // Lo inicio en null por si necesito saber más adelante si esto se pisó, o quedó en null
      enabledPostService: false,
      // appointment_status: 'completed', // Solo esto por ahora. Lo comento porque no lo estamos usando.
      // delivery_medium: 'email', // Solo esto por ahora. Lo comento porque no lo estamos usando.
      hours_after_appointment: 72,
      subject: '',
      message: '',
    };

    this.auth = new AuthService();
    this.api = new APIService();
    this.helpers = new Helpers();
    this.viewHelpers = new ViewHelpers();
    this.configService = new ConfigService();

    if (window.URLSearchParams && new window.URLSearchParams(this.props.location.search).get("result")) {
      setTimeout(() => {
        this.setState({
          result: new window.URLSearchParams(this.props.location.search).get("result")
        })
      }, 1500);
    }
  }

  componentWillMount() {
    this.checkUserAllowed();
  }

  componentDidMount() {
    if (this.state.selectedTab) {
      const anchor = document.getElementById(`a-tab${this.state.selectedTab}`);
      if (anchor) {
        anchor.click();
      }
    }
  }

  async checkUserAllowed() {
    const allowed = await this.viewHelpers.isUserAllowed(USER_TYPE_SECRETARY);
    allowed ? this.load() : this.redirectNotLoggedIn();
  }

  getPreselectedTab() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const urlTab = urlParams.get('tab');
    if (urlTab) {
      return urlTab;
    }

    const storedTab = localStorage.getItem('tab');
    if (storedTab) {
      return storedTab;
    }
  }

  saveTabToLocalStorage(tabValue) {
    localStorage.setItem('tab', tabValue);
  }

  handleTabClick = (event) => {
    // Previene el comportamiento predeterminado del enlace
    event.preventDefault();

    // Obtiene el valor del href y extrae el número del tab
    const tabNumber = event.currentTarget.getAttribute('href').split('#tab')[1];

    // Llama a saveTabToLocalStorage con el número del tab
    this.saveTabToLocalStorage(tabNumber);
  }

  setLoading(bool) {
    this.setState({
      loading: bool
    })
  }

  load() {
    this.setLoading(true);
    this.configService.getLocalClinicData().then((config) => {
      this.setState({
        clinicId: config.id
      }, () => {
        this.api.getPostsServicesMessages(this.state.clinicId).then(res => {
          if (res && res.data) {
            this.setState({
              enabledPostService: true,
              postServiceId: res.data.id,
              hours_after_appointment: res.data.hours_after_appointment,
              subject: res.data.subject,
              message: res.data.message,
            })
          } else {
            this.setState({
              postServiceId: null, // por si viene de un refresh después de un DELETE
            })
          }
          this.setLoading(false);
        }).catch(err => {
          this.setLoading(false);
          this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
        })
      })
    }).catch(err => {
      console.log(err)
    })
  }

  redirectNotLoggedIn() {
    window.location.href = HREF_PAGE_HOME;
  }

  handleChange = state => ev => {
    this.setState({[state]: ev.target.value});
  };

  handleReactSelectChange = state => value => {
    this.setState({[state]: value});
  };

  savePostService() {
    const objData = {
      clinic_id: this.state.clinicId,
      hours_after_appointment: this.state.hours_after_appointment,
      subject: this.state.subject,
      message: this.state.message,
    }
    this.setLoading(true);
    if (this.state.postServiceId) {
      this.api.putPostsServicesMessages(this.state.postServiceId, objData).then(res => {
        this.props.showMainModal(locales_es.successModal.title, res.message);
        this.load();
      }).catch(err => {
        this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
        this.setLoading(false);
      })
    } else {
      this.api.postPostsServicesMessages(objData).then(res => {
        this.props.showMainModal(locales_es.successModal.title, res.message);
        this.load();
      }).catch(err => {
        this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
        this.setLoading(false);
      })
    }
  }

  deletePostService() {
    this.setLoading(true);
    this.api.deletePostsServicesMessages(this.state.postServiceId).then(res => {
      this.props.showMainModal(locales_es.successModal.title, res.message);
      this.load();
    }).catch(err => {
      this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
      this.setLoading(false);
    })
  }

  render() {
    const {
      loading,
      result,
      enabledPostService,
    } = this.state;

    const postServiceInputs = [
      {
        label: locales_es.postServiceTimelapseText,
        placeholder: locales_es.postServiceTimelapseTextPlaceholder,
        id: 1,
        state: 'hours_after_appointment',
        value: this.state.hours_after_appointment,
        type: 'number',
        required: true,
        min: 1,
        wrapperCustomClassName: 'float-left',
      },
      {
        label: locales_es.postServiceSubjectText,
        placeholder: locales_es.postServiceSubjectTextPlaceholder,
        id: 2,
        state: 'subject',
        value: this.state.subject,
        type: 'text',
        required: true,
        wrapperCustomClassName: 'form-group clear',
      },
      {
        label: locales_es.postServiceMessageText,
        placeholder: locales_es.postServiceMessageTextPlaceholder,
        id: 3,
        state: 'message',
        value: this.state.message,
        type: 'textarea',
        required: true,
        wrapperCustomClassName: 'form-group clear',
      },
    ];

    const toggleEnabledPostService = () => {
      const toggle = !this.state.enabledPostService;
      this.setState({
        enabledPostService: toggle,
      }, () => {
        if (this.state.postServiceId) {
          if (toggle === true) {
            this.savePostService();
          }
          if (toggle === false) {
            this.deletePostService();
          }
        }
      });
    }

    return (
      <div className="kt-portlet kt-portlet--height-fluid kt-widget-17">
        {loading ? <Loading/> : null}
        <div className="row">
          <div className="col-12">

            <div className="kt-portlet kt-portlet--tabs m-0">
              <div className="kt-portlet__head">
                <div className="kt-portlet__head-label d-none d-lg-flex">
                  <h3 className="kt-portlet__head-title">
                    {locales_es.clinicSettings}
                  </h3>
                </div>
                <div className="kt-portlet__head-toolbar">
                  <ul className="nav nav-tabs nav-tabs-line nav-tabs-line-brand nav-tabs-bold"
                      role="tablist">
                    <li className="nav-item">
                      <a className="nav-link show active" data-toggle="tab" onClick={this.handleTabClick}
                         href="#tab1" id="a-tab1" role="tab" aria-selected="true">
                        {locales_es.mailing}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="kt-portlet__body">
                <div className="tab-content">
                  <div className="tab-pane fade active show" id="tab1" role="tabpanel">

                    <div className="row mt-3">
                      <div className="col">
                        <label className="kt-checkbox">
                          <input type="checkbox"
                                 onChange={toggleEnabledPostService}
                                 checked={enabledPostService}/>
                          <span/> Habilitar mensajes automáticos para turnos atendidos
                        </label>
                      </div>
                    </div>

                    {enabledPostService &&
                      <div className="kt-portlet">
                        <div className="row mt-5 pt-3">
                          <div className="col text-center">
                            <h3>{locales_es.enablePostServiceTitle}</h3>
                            <p>{locales_es.enablePostServiceDescription}</p>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col text-center">
                            <Form
                              styles="kt-form"
                              inputs={postServiceInputs}
                              handleChange={this.handleChange}
                              wrapper={true}
                              onSubmit={() => this.savePostService()}
                            />
                          </div>
                        </div>
                      </div>
                    }

                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        {result && (result === STATUS_SUCCESS || result === STATUS_FAILED) ?
          <ModalAnimationResult
            acceptAction={() => this.setState({result: null})}
            result={result}
            titleSuccess={'¡La operación ha sido exitosa!'}
            titleFailed={'Ha ocurrido un error. Inténtalo de nuevo más tarde.'}
          />
          : null
        }
      </div>

    )
  }
}
