import React, { useState } from 'react';
import locales_es from "../../locales/es";

export const TablePaging = ({ pagingData, onChange, query }) => {
  const pagingOptions = [30, 50, 100];
  const [pagingOption, setPagingOption] = useState(pagingData.per_page || 10);

  const renderPages = () => {
    const pages = [];
    const totalPages = pagingData.last_page;
    const currentPage = pagingData.current_page;
    const maxPagesToShow = 5; // Maximum number of pages to display
    let startPage, endPage;

    if (totalPages <= maxPagesToShow) {
      // If total pages are less than or equal to the max pages to show, display all pages
      startPage = 1;
      endPage = totalPages;
    } else {
      // If total pages are more than the max pages to show
      const maxPagesBeforeCurrentPage = Math.floor(maxPagesToShow / 2);
      const maxPagesAfterCurrentPage = Math.ceil(maxPagesToShow / 2) - 1;
      if (currentPage <= maxPagesBeforeCurrentPage) {
        // If the current page is near the start
        startPage = 1;
        endPage = maxPagesToShow;
      } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
        // If the current page is near the end
        startPage = totalPages - maxPagesToShow + 1;
        endPage = totalPages;
      } else {
        // If the current page is somewhere in the middle
        startPage = currentPage - maxPagesBeforeCurrentPage;
        endPage = currentPage + maxPagesAfterCurrentPage;
      }
    }

    // Add first page and ellipsis if startPage is not 1
    if (startPage > 1) {
      pages.push(<li key={1}><a
        onClick={() => onChange({ 'page': 1, per_page: pagingOption, q: query }, query ? pagingData.path : null)}
        className={`kt-datatable__pager-link kt-datatable__pager-link-number ${currentPage === 1 ? 'kt-datatable__pager-link--active' : ''}`}>{1}</a></li>);
      if (startPage > 2) {
        pages.push(<li key="start-ellipsis"><span className="ellipsis">...</span></li>);
      }
    }

    // Add pages in the middle
    for (let i = startPage; i <= endPage; i++) {
      pages.push(<li key={i}><a
        onClick={() => onChange({ 'page': i, per_page: pagingOption, q: query }, query ? pagingData.path : null)}
        className={`kt-datatable__pager-link kt-datatable__pager-link-number ${currentPage === i ? 'kt-datatable__pager-link--active' : ''}`}>{i}</a></li>);
    }

    // Add last page and ellipsis if endPage is not the last page
    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pages.push(<li key="end-ellipsis"><span className="ellipsis">...</span></li>);
      }
      pages.push(<li key={totalPages}><a
        onClick={() => onChange({ 'page': totalPages, per_page: pagingOption, q: query }, query ? pagingData.path : null)}
        className={`kt-datatable__pager-link kt-datatable__pager-link-number ${currentPage === totalPages ? 'kt-datatable__pager-link--active' : ''}`}>{totalPages}</a></li>);
    }

    return pages;
  }

  return (
    <div className="kt-datatable kt-datatable--default">
      <div className="kt-datatable__pager kt-datatable--paging-loaded">
        {pagingData.last_page !== 1 ?
          <ul className="kt-datatable__pager-nav">
            <li>
              <a title={locales_es.first}
                onClick={() => {
                  onChange({ per_page: pagingOption }, pagingData.first_page_url)
                }}
                className={`kt-datatable__pager-link kt-datatable__pager-link--first ${pagingData.current_page === 1 ? 'kt-datatable__pager-link--disabled' : ''}`}>
                <i className="flaticon2-fast-back"></i>
              </a>
            </li>
            <li><a title={locales_es.previous}
              onClick={() => {
                if (pagingData.prev_page_url) {
                  onChange({ per_page: pagingOption }, pagingData.prev_page_url)
                }
              }}
              className={`kt-datatable__pager-link kt-datatable__pager-link--prev ${pagingData.prev_page_url < 1 ? 'kt-datatable__pager-link--disabled' : ''}`}>
              <i className="flaticon2-back"></i></a></li>
            {renderPages()}
            <li><a title={locales_es.next}
              onClick={() => {
                if (pagingData.next_page_url) {
                  onChange({ per_page: pagingOption }, pagingData.next_page_url)
                }
              }}
              className={`kt-datatable__pager-link kt-datatable__pager-link--next ${pagingData.next_page_url ? '' : 'kt-datatable__pager-link--disabled'}`}>
              <i className="flaticon2-next"></i></a></li>
            <li><a title={locales_es.last}
              onClick={() => {
                if (pagingData.last_page_url) {
                  onChange({ per_page: pagingOption }, pagingData.last_page_url)
                }
              }}
              className={`kt-datatable__pager-link kt-datatable__pager-link--last ${pagingData.current_page === pagingData.last_page_url ? 'kt-datatable__pager-link--disabled' : ''}`}>
              <i className="flaticon2-fast-next"></i></a></li>
          </ul> : null}
        <div className="kt-datatable__pager-info">
          <div className="dropdown bootstrap-select kt-datatable__pager-size dropup" style={{ width: 'auto' }}>
            <button type="button" className="btn dropdown-toggle btn-light" data-toggle="dropdown"
              role="combobox" aria-owns="bs-select-2" aria-haspopup="listbox" aria-expanded="false"
              title="Select page size">
              <div className="filter-option">
                <div className="filter-option-inner">
                  <div className="filter-option-inner-inner">{pagingOption}</div>
                </div>
              </div>
            </button>
            <div className="dropdown-menu">
              <div className="inner show" role="listbox" id="bs-select-2" tabIndex="-1"
                aria-activedescendant="bs-select-2-4">
                <ul className="dropdown-menu inner show" role="presentation">
                  {pagingOptions.map((option) => <li key={option}>
                    <a role="option" className="dropdown-item" id="bs-select-2-0" onClick={() => {
                      setPagingOption(option);
                      onChange({ 'per_page': option, q: query }, query ? pagingData.path : null)
                    }}><span
                      className="text">{option}</span></a></li>)}
                </ul>
              </div>
            </div>
          </div>
          <span
            className="kt-datatable__pager-detail">{locales_es.showing} {pagingData.from} - {pagingData.to} {locales_es.of} {pagingData.total}</span>
        </div>
      </div>
    </div>
  );
}
