import React, { Component } from 'react';
import AuthService from "../../modules/authService";
import Spinner from "../../components/spinner";
import { HREF_PAGE_HOME, USER_TYPE_SECRETARY } from "../../models/constants";
import ViewHelpers from "../../modules/viewHelpers";
import './styles.css';
import ConfigService from "../../modules/configService";
import Logo from '../../images/logo.png';
import Select from 'react-select';
import Bell from '../../assets/sounds/airport-call.mp3';
import locales_es from "../../locales/es";
import APIService from "../../modules/apiService";
import Helpers from "../../modules/helpers";
import DateTimeService from "../../modules/DateTimeService";
import Form from "../../components/form";

export default class AppointmentCallerPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      venueLogo: null,
      venueName: null,
      data: null,
      currentTime: this.getCurrentTime(),
      voices: [],
      selectedVoice: localStorage.getItem('selectedVoice') || '',
      rate: localStorage.getItem('rate') || 1,
      pitch: localStorage.getItem('pitch') || 1,
      showControls: true,
      isPaused: false,
      consultingRoomOptions: [{ id: 0 }],
      readPatients: JSON.parse(localStorage.getItem('readPatients')) || [],
      consulting_rooms_ids: [],
      specialty_ids: [],
    };
    this.api = new APIService();
    this.helpers = new Helpers();
    this.auth = new AuthService();
    this.viewHelpers = new ViewHelpers();
    this.configService = new ConfigService();
    this.dateTimeService = new DateTimeService();
  }

  componentWillMount() {
    this.checkUserStatus();
  }

  async checkUserStatus() {
    const isAllowedUser = await this.viewHelpers.isUserAllowed(USER_TYPE_SECRETARY);
    if (!isAllowedUser) {
      window.location.href = HREF_PAGE_HOME;
    }
  }

  componentDidMount() {
    this.setClinic();
    this.init();
  }

  setClinic() {
    this.configService.getLocalClinicData().then(res => {
      this.setState({
        venueLogo: res['full_image'],
        venueName: res['name'],
        clinicId: res['id'],
      }, () => {
        this.getConsultingRooms();
      });
    }).catch(err => {
      console.log(err);
    });
  }

  getConsultingRooms() {
    this.api.getConsultingRooms({ clinic_id: this.state.clinicId }).then(res => {
      if (res && res.data && res.data.length) {
        const consultingRoomOptions = [
          { value: 'all', label: 'Todos los consultorios' },
          ...res.data.map(room => ({ value: room.id, label: room.name }))
        ];
        this.setState({ consultingRoomOptions }, () => this.getSpecialties());
      }
    }).catch(err => {
      // this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
      console.error(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
    });
  }

  getSpecialties() {
    this.api.getSpecialties({ clinic_id: this.state.clinicId }).then(res => {
      if (res && res.data && res.data.length) {
        const specialtyOptions = [
          { value: 'all', label: 'Todas las especialidades' },
          ...res.data.map(specialty => ({ value: specialty.id, label: specialty.name }))
        ];
        this.setState({ specialtyOptions });
      }
    }).catch(err => {
      // this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
      console.error(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
    });
  }

  componentWillUnmount() {
    clearInterval(this.clockInterval);
    clearInterval(this.callerInterval);
  }

  init() {
    this.startClock();
    this.loadVoices();
  }

  initQueue() {
    this.loadWaitingRoom();
    this.callerInterval = setInterval(() => this.loadWaitingRoom(), 15000);
  }

  getCurrentTime() {
    const now = new Date();
    return now.toTimeString().split(' ')[0];
  }

  startClock() {
    this.clockInterval = setInterval(() => {
      this.setState({ currentTime: this.getCurrentTime() });
    }, 1000);
  }

  loadVoices() {
    const synth = window.speechSynthesis;
    let voices = synth.getVoices();
    if (voices.length === 0) {
      synth.onvoiceschanged = () => {
        voices = synth.getVoices();
        this.setState({ voices });
      };
    } else {
      this.setState({ voices });
    }
  }

  handleVoiceChange = (selectedOption) => {
    const selectedVoice = selectedOption ? selectedOption.value : '';
    this.setState({ selectedVoice });
    localStorage.setItem('selectedVoice', selectedVoice);
  }

  handleRateChange = (event) => {
    const rate = event.target.value;
    this.setState({ rate });
    localStorage.setItem('rate', rate);
  }

  handlePitchChange = (event) => {
    const pitch = event.target.value;
    this.setState({ pitch });
    localStorage.setItem('pitch', pitch);
  }

  toggleControls = () => {
    this.setState(prevState => ({ showControls: !prevState.showControls }));
  }

  togglePause = () => {
    this.setState(prevState => {
      const isPaused = !prevState.isPaused;
      if (isPaused) {
        clearInterval(this.callerInterval);
      } else {
        this.callerInterval = setInterval(() => this.loadWaitingRoom(), 15000);
      }
      return { isPaused };
    });
  }

  clearCache = () => {
    localStorage.removeItem('readPatients');
    this.setState({ readPatients: [] });
  }

  async speakText(utterance, audio) {
    return new Promise((resolve) => {
      audio.play();
      audio.onended = () => {
        window.speechSynthesis.speak(utterance);
        utterance.onend = resolve;
      };
    });
  }

  loadWaitingRoom = () => {
    const { consulting_rooms_ids, specialty_ids } = this.state;
    const now = new Date();
    const tomorrow = new Date(now);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const params = {
      clinic_id: this.state.clinicId,
      consulting_rooms_ids: consulting_rooms_ids.length ? consulting_rooms_ids : undefined,
      specialty_ids: specialty_ids.length ? specialty_ids : undefined,
      date_from: this.dateTimeService.parseDateToAPIString(now),
      date_to: this.dateTimeService.parseDateToAPIString(tomorrow),
    };

    this.api.getAppointmentWaitingRoom(params).then((res) => {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const filteredData = res.data.filter(appointment => {
        const appointmentDate = new Date(appointment.created_at);
        return appointmentDate >= today;
      });

      this.setState({ data: filteredData }, async () => {
        const { data, voices, selectedVoice, rate, pitch, readPatients } = this.state;
        const updatedReadPatients = [...readPatients];
        const audio = document.getElementById('bell-audio');

        for (let appointment of data) {
          if (!updatedReadPatients.includes(appointment.id)) {
            const utterance = new SpeechSynthesisUtterance(`${appointment.patient.name} ${appointment.patient.lastname}, ${appointment.consulting_room?.name}`);
            const selectedVoiceObject = voices.find(voice => voice.name === selectedVoice);
            if (selectedVoiceObject) {
              utterance.voice = selectedVoiceObject;
            }
            utterance.lang = 'es-AR';
            utterance.rate = rate;
            utterance.pitch = pitch;

            await this.speakText(utterance, audio);

            updatedReadPatients.push(appointment.id);
          }
        }

        localStorage.setItem('readPatients', JSON.stringify(updatedReadPatients));
        this.setState({ readPatients: updatedReadPatients });
      });
    }).catch((err) => {
      // this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
      console.error(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
    });
  }

  handleChange = state => ev => {
    this.setState({ [state]: ev.target.value });
  };

  handleDateChange = state => value => {
    this.setState({ [state]: value });
  };

  handleReactSelectChange = state => value => {
    // Asegúrate de que value es un array
    const selectedValues = Array.isArray(value) ? value.map(item => item.value) : [];

    if (state === 'specialtyId') {
      this.setState({ specialty_ids: selectedValues.includes('all') ? [] : selectedValues });
    } else if (state === 'consulting_room_id') {
      this.setState({ consulting_rooms_ids: selectedValues.includes('all') ? [] : selectedValues });
    }

    this.setState({ [state]: value });
  };


  render() {
    const {
      data,
      venueLogo,
      venueName,
      currentTime,
      voices,
      selectedVoice,
      rate,
      pitch,
      showControls,
      isPaused,
      specialtyOptions,
      consultingRoomOptions
    } = this.state;
    const voiceOptions = voices.map(voice => ({
      value: voice.name,
      label: `${voice.name} (${voice.lang})`
    }));
    const selectedVoiceOption = voiceOptions.find(option => option.value === selectedVoice);

    const inputs = [
      {
        label: locales_es.specialty,
        placeholder: locales_es.specialty,
        id: 12,
        state: 'specialtyId',
        value: this.state.specialtyId,
        type: 'react-select',
        required: true,
        options: specialtyOptions,
        wrapperCustomClassName: 'form-group col-md-6 float-left pl-md-0',
        isMulti: true,
      },
      {
        label: locales_es.consultingRoom,
        placeholder: locales_es.consultingRoom,
        id: 2,
        state: 'consulting_room_id',
        value: this.state.consulting_room_id,
        type: 'react-select',
        required: true,
        options: consultingRoomOptions,
        wrapperCustomClassName: 'form-group col-md-6 float-left pr-md-0',
        isMulti: true,
      },
    ];

    const selectedSpecialties = this.state.specialty_ids.map(id => specialtyOptions.find(option => option.value === id)?.label).filter(Boolean).join(', ');
    const selectedConsultingRooms = this.state.consulting_rooms_ids.map(id => consultingRoomOptions.find(option => option.value === id)?.label).filter(Boolean).join(', ');

    return (
      <>
        <div className="woopi-patient-queue">
          <audio id="bell-audio" src={Bell} autoPlay={false} loop={false} />
          <div className="woopi-patient-queue__header">
            {venueLogo === null ? <Spinner /> : venueLogo ? <img src={venueLogo} alt={venueName} /> : null}
            {showControls ? (
              <Form
                styles="kt-form"
                inputs={inputs}
                handleChange={this.handleChange}
                handleDateChange={this.handleDateChange}
                handleReactSelectChange={this.handleReactSelectChange}
                showTerms={false}
                wrapper={false}
                wrapperClassName="w-50"
              />
            ) : (
              <>
                {selectedSpecialties && <h2 className="selected-specialties m-1">{locales_es.specialties}: {selectedSpecialties}</h2>}
                {selectedConsultingRooms && <h2 className="selected-consulting-rooms m-1">{locales_es.consultingRooms}: {selectedConsultingRooms}</h2>}
              </>
            )}
            <button onClick={this.toggleControls}
                    className="woopi-patient-queue__toggle-button btn btn-secondary btn-elevate btn-circle btn-icon">
              {showControls ? <i className="fa fa-chevron-right" /> : <i className="fa fa-chevron-left" />}
            </button>
          </div>
          {showControls && (
            <div className="woopi-patient-queue__controls p-2">
              <div className="row">
                <div className="col text-center">
                  <button onClick={() => {
                    this.initQueue();
                    this.setState({ start: true })
                  }}
                          className="btn btn-lg btn-primary btn-elevate m-5">
                    Iniciar llamador
                  </button>
                </div>
              </div>
              <Select
                className="woopi-patient-queue__voice-selector mb-2"
                value={selectedVoiceOption}
                onChange={this.handleVoiceChange}
                options={voiceOptions}
                isClearable
                placeholder="Seleccione una voz"
              />
              <button onClick={() => {
                this.setState({ data: null })
                this.loadWaitingRoom();
              }}
                      className="woopi-patient-queue__play-button btn btn-outline-brand btn-elevate btn-pill"><i
                className="la la-play"></i> Probar
              </button>
              <label>
                Velocidad:
                <input
                  type="range"
                  min="0.1"
                  max="2"
                  step="0.05"
                  value={rate}
                  onChange={this.handleRateChange}
                />
                {rate}
              </label>
              <label>
                Tono:
                <input
                  type="range"
                  min="0"
                  max="2"
                  step="0.05"
                  value={pitch}
                  onChange={this.handlePitchChange}
                />
                {pitch}
              </label>
              <button onClick={this.togglePause}
                      className="woopi-patient-queue__pause-button btn btn-outline-warning btn-elevate btn-pill">
                {isPaused ? 'Reanudar' : 'Pausar'}
              </button>
              <button onClick={this.clearCache}
                      className="woopi-patient-queue__clear-cache-button btn btn-outline-danger btn-elevate btn-pill">
                Limpiar Caché
              </button>
            </div>
          )}
          {data === null ? null
            : data && data.length ?
              <>
                <div className="woopi-patient-queue__patient-info">
                  <h1>{data[0].patient.name} {data[0].patient.lastname}</h1>
                  <h2>{data[0].consulting_room?.name}</h2>
                </div>
                {data.length > 1 ?
                  <div className="woopi-patient-queue__table-container">
                    <table className="woopi-patient-queue__turnos-table">
                      <thead>
                      <tr>
                        <th className="woopi-patient-queue__time">Hora</th>
                        <th>Últimos Llamados</th>
                        <th className="woopi-patient-queue__consultorio">Consultorio</th>
                      </tr>
                      </thead>
                      <tbody>
                      {data.map((appointment, index) => {
                        if (index > 0 && index < 7) {
                          return (
                            <tr key={appointment.id}>
                              <td className="woopi-patient-queue__time"><span dangerouslySetInnerHTML={{
                                __html: this.dateTimeService.parseEventTime(appointment.created_at)
                              }} /></td>
                              <td>{appointment.patient.name} {appointment.patient.lastname}</td>
                              <td
                                className="woopi-patient-queue__consultorio">{appointment.consulting_room?.name}</td>
                            </tr>
                          )
                        }
                      })}
                      </tbody>
                    </table>
                  </div>
                  : null
                }
              </>
              :
              <>
                <div className="woopi-patient-queue__patient-info">
                  <h2>No hay pacientes en espera</h2>
                </div>
              </>
          }
          <div className="woopi-patient-queue__footer">
            <div id="current-time" className="woopi-patient-queue__current-time">{currentTime}</div>
            <img src={Logo} alt="Logo Plataforma" />
            <div className="kt-footer__copyright">v{process.env.REACT_APP_VERSION}</div>
          </div>
        </div>
      </>
    );
  }
}
