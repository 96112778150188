import React, { Component } from 'react';
import locales_es from './../../locales/es.js';
import 'react-circular-progressbar/dist/styles.css';
import { CircularProgressbar } from 'react-circular-progressbar';

export default class FullscreenProgress extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { progress } = this.props;

    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: 1000,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
        }}>
        <div
          style={{
            width: '25%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <CircularProgressbar
            value={progress * 100}
            text={`${Math.round(progress * 100)}%`}
            styles={{
              path: {
                stroke: 'rgba(28, 172, 129, 0.9)',
              },
              text: {
                fill: 'rgba(28, 172, 129, 0.5)',
                fontSize: '26px',
                textAlign: 'center',
              },
            }}
          />
        </div>
        <div
          style={{
            color: 'black',
            marginTop: '20px',
            fontSize: '32px',
            textAlign: 'center',
            width: '80%',
          }}>
          {this.props.text || locales_es.successModal.title}
        </div>
      </div>
    );
  }
}
