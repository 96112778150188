import React, {Component} from 'react';
import locales_es from "../../locales/es";
import Spinner from "../spinner";
import {PAYMENT_METHOD_AMOUNT_TYPE_FIXED, PAYMENT_METHOD_AMOUNT_TYPE_PERCENT} from "../../models/constants";
import Linkify from 'react-linkify';

export default class BankTransferData extends Component {

  render() {
    const {bankTransferData, onClickCancel, onClickAcceptAction, selectedAppointment} = this.props;

    const calculatePercent = () => {
      return ((Number(selectedAppointment.before_payment_amount).toFixed(2) * 100) / Number(selectedAppointment.consultation_price).toFixed(2));
    }

    return (
      <>
        <div className="woopi-overlay">
          {bankTransferData === true ? <Spinner /> :
            <div className="woopi-overlay-content">
              <div className="row justify-content-center">
                <div className="col-lg-9 text-center">
                  <span className="btn btn-focus btn-elevate btn-circle btn-icon mr-3">
                    <i className="flaticon-piggy-bank"></i>
                  </span>
                  <h4 className="m-2">{locales_es.bankTransferDataModal.title}</h4>
                  <div className="kt-timeline__item-text">
                    <strong>{locales_es.bankTransferDataModal.subtitle}</strong>.
                    <br />
                    <br />
                    <div>
                      <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                        <a target="blank" href={decoratedHref} key={key}>
                          {decoratedText}
                        </a>
                      )}>{bankTransferData.data}</Linkify>
                    </div>
                    <div className="m-2">
                    {selectedAppointment.before_payment_type === PAYMENT_METHOD_AMOUNT_TYPE_FIXED ?
                    `Debes transferir ${bankTransferData.currencyId || locales_es.currency_sign} ${selectedAppointment.before_payment_amount}, en concepto de seña o pago parcial o total del turno.` : ''}
                    {selectedAppointment.before_payment_type === PAYMENT_METHOD_AMOUNT_TYPE_PERCENT ?
                      `Debes transferir ${bankTransferData.currencyId || locales_es.currency_sign} ${selectedAppointment.before_payment_amount}, que es el ${calculatePercent()}% del valor del turno, en concepto de seña o pago parcial o total del turno.` : ''}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center mt-3">
                <div className="col text-center">
                  <button type="button" className={'btn btn-elevate btn-pill m-1 btn-success'}
                          onClick={onClickAcceptAction}
                  >{locales_es.continue}
                  </button>
                </div>
                <div className="col text-center">
                  <button type="button" className={'btn btn-elevate btn-pill m-1 btn-danger'}
                          onClick={onClickCancel}
                  >{locales_es.cancel}
                  </button>
                </div>
              </div>
            </div>
          }

        </div>
      </>
    )
  }
}
