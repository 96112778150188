import React, {useEffect, useState} from 'react';
import {Wrapper} from '@googlemaps/react-wrapper';
import {GoogleMaps} from '../map';
import locales_es from '../../locales/es';
import Spinner from "../spinner";

const AddressSearch = ({defaultAddress, label, placeholder, onAddressSelect, defaultLocationMarker}) => {
  const SEARCH_INPUT = 'places-search';
  const DEFAULT_CENTER = {lat: -34.615796, lng: -58.5156988};
  const DEFAULT_ZOOM = 16;
  const DEFAULT_MAP_CONFIG = {
    center: DEFAULT_CENTER, zoom: DEFAULT_ZOOM, setBounds: true,
  };

  const [mapLoaded, setMapLoaded] = useState(null);
  const [locationMarker, setLocationMarker] = useState(defaultLocationMarker || null);
  const [mapConfig] = useState(DEFAULT_MAP_CONFIG);

  const onGoogleMapsLoaded = () => {
    setMapLoaded(true);
    setTimeout(() => {
      const input = document.getElementById(SEARCH_INPUT);
      const options = {
        componentRestrictions: {country: "ar"}, // fields: ["address_components", "geometry", "icon", "name"],
      };
      const autocomplete = new window.google.maps.places.Autocomplete(input, options);
      window.google.maps.event.addListener(autocomplete, "place_changed", function () {

        // Segment results into usable parts.
        const place = autocomplete.getPlace();
        if (place && place.geometry && place.geometry.location) {
          const geo = {
            lat: place.geometry.location.lat(), lng: place.geometry.location.lng(),
          };
          setLocationMarker(geo);
        }
      });
    }, 3000);
  }

  const clearLocationMarker = () => {
    const input = document.getElementById(SEARCH_INPUT);
    input.value = '';
    setLocationMarker(null);
  }

  useEffect(() => {
    const input = document.getElementById(SEARCH_INPUT);
    const obj = input?.value ? {address: input.value, ...locationMarker} : null
    onAddressSelect && onAddressSelect(obj);
  }, [locationMarker]);

  useEffect(() => {
    setLocationMarker(defaultLocationMarker);
    const input = document.getElementById(SEARCH_INPUT);
    if (input) {
      input.value = defaultAddress ? defaultAddress : '';
    }
  }, [defaultLocationMarker]);

  return (
    <Wrapper libraries={["core", "places"]} apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
             callback={onGoogleMapsLoaded}>
      {mapLoaded ?
        <>
          <label>{label || locales_es.address}</label>
          <div className="kt-input-icon kt-input-icon--right">
            <input className="form-control" type="text" id={SEARCH_INPUT} placeholder={placeholder || locales_es.enterYourAddress}/>
            {locationMarker ?
              <span onClick={clearLocationMarker}
                    className="kt-input-icon__icon kt-input-icon__icon--right cursor-pointer"><span><i
                className="la la-remove"></i></span></span>
              :
              <div className="m-1 mt-3 mb-5">
                <i><strong>Si la atención es online, puede dejar este campo vacío.</strong> Si la atención que brindarás
                  es presencial, encuentra la dirección en el buscador y revisa la ubicación en el mapa. Esta será la
                  ubicación que se le mostrará al paciente.</i>
              </div>
            }
          </div>
          {locationMarker && <GoogleMaps mapConfig={mapConfig} locationMarker={locationMarker}/>}
        </> : <Spinner/>}
    </Wrapper>
  );
};

export default AddressSearch;
