import React from 'react';
import ViewHelpers from "../../modules/viewHelpers";

const ContactButtons = ({ patient, text = '', size, buttons = [] }) => {
  const SIZE_SMALL = 'small';
  const viewHelpers = new ViewHelpers();

  let cellphone = viewHelpers.userDataHasData(patient, 'cellphone');
  if (cellphone) {
    cellphone = cellphone.replace(/^\+|\s+/g, '');
  }

  const whatsappLink = text ? `https://wa.me/${cellphone}?text=${encodeURIComponent(text)}` : `https://wa.me/${cellphone}`;

  const renderPhoneButton = () => (
    <a
      href={`tel:${cellphone}`}
      target="_blank"
      rel="noopener noreferrer"
      className={`btn woopi-btn-font-hover-light btn-outline-info btn-elevate btn-circle btn-icon m-1 ${size === SIZE_SMALL ? 'btn-sm' : ''}`}
    >
      <span className="flaticon2-phone btn-font-info"/>
    </a>
  );

  const renderWhatsAppButton = () => (
    <a
      href={whatsappLink}
      target="_blank"
      rel="noopener noreferrer"
      className={`btn woopi-btn-font-hover-light btn-outline-success btn-elevate btn-circle btn-icon m-1 ${size === SIZE_SMALL ? 'btn-sm' : ''}`}
    >
      <span className="flaticon-whatsapp btn-font-success"/>
    </a>
  );

  return (
    cellphone ? (
      <>
        {(buttons.length === 0 || buttons.includes('phone')) && renderPhoneButton()}
        {(buttons.length === 0 || buttons.includes('wa')) && renderWhatsAppButton()}
      </>
    ) : null
  );
};

export default ContactButtons;
