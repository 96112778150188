import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import defaultBgImage from '../../images/card-bg.jpg';

const duracion = 500; // Duración de la transición en milisegundos

const TransitionImage = ({ bgColor }) => {
  const [showImage, setShowImage] = useState(!bgColor); // Controla la visibilidad de la imagen
  const [bgColorStyle, setBgColorStyle] = useState(bgColor); // Estilo del color de fondo
  const [imageOpacity, setImageOpacity] = useState(1); // Opacidad de la imagen

  useEffect(() => {
    // Si bgColor es proporcionado, desvanecemos la imagen y cambiamos el color de fondo
    if (bgColor) {
      setImageOpacity(0); // Inicia con la imagen invisible
      setTimeout(() => setBgColorStyle(bgColor), duracion); // Cambia el color después de la animación
    } else {
      // Si bgColor no es proporcionado, mostramos la imagen y quitamos el color de fondo
      setShowImage(true);
      setBgColorStyle('transparent'); // Inicia con el fondo transparente
      setTimeout(() => setImageOpacity(1), 10); // Esperamos un instante antes de comenzar la animación
    }
  }, [bgColor]);

  return (
    <CSSTransition
      in={showImage}
      timeout={duracion}
      onEnter={() => setShowImage(true)}
      onExited={() => setShowImage(false)}
    >
      <div
        className="card-item__cover"
        style={{
          backgroundColor: bgColorStyle,
          transition: `background-color ${duracion}ms ease-in-out`,
        }}
      >
        <img
          src={defaultBgImage}
          alt=""
          style={{
            opacity: imageOpacity,
            transition: `opacity ${duracion}ms ease-in-out`,
            position: 'absolute', // Posiciona la imagen sobre el fondo
            width: '100%', // Asegúrate de que la imagen cubra el div
            height: '100%', // Asegúrate de que la imagen cubra el div
            objectFit: 'cover' // Asegúrate de que la imagen se escale correctamente
          }}
          className="card-item__bg"
        />
      </div>
    </CSSTransition>
  );
};

export default TransitionImage;
