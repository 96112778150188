import React, { Component } from 'react';
import Lottie from 'react-lottie';
import animationData from './../../lotties/success-check.json';
import './styles.css';

export default class FullscreenGreenCheck extends Component {
  render() {
    const animationOptions = {
      loop: false,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };

    return (
      <div className="fullscreen-container">
        <div className="greencheck-animation-container">
          <Lottie options={animationOptions} />
        </div>
        <span className="text-container">
          {this.props.text || 'Tu texto aquí'}
        </span>
      </div>
    );
  }
}
