import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FC_SLOT_DURATION, FC_SLOT_MAX_TIME, FC_SLOT_MIN_TIME } from "../../models/constants";
import locales_es from '../../locales/es';
import { langCode } from '../../locales/es';
import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import { getStoredTime } from '../../utils/timeUtils'; // Importar la función

registerLocale(langCode, es);

const TimePicker = forwardRef((props, ref) => {
  const { onSlotMinTime, onSlotMaxTime, onSlotDurationChange, enabledFields = [] } = props;

  const defaultStartTime = getStoredTime('slotMinTime', FC_SLOT_MIN_TIME);
  const defaultEndTime = getStoredTime('slotMaxTime', FC_SLOT_MAX_TIME);
  const defaultSlotDuration = localStorage.getItem('slotDuration') || FC_SLOT_DURATION;

  const [startTime, setStartTime] = useState(new Date(defaultStartTime) || new Date());
  const [endTime, setEndTime] = useState(new Date(defaultEndTime) || new Date());
  const [slotDuration, setSlotDuration] = useState(defaultSlotDuration);

  useEffect(() => {
    if (!isNaN(startTime)) {
      localStorage.setItem('slotMinTime', startTime.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' }));
    }
  }, [startTime]);

  useEffect(() => {
    if (!isNaN(endTime)) {
      localStorage.setItem('slotMaxTime', endTime.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' }));
    }
  }, [endTime]);

  useEffect(() => {
    localStorage.setItem('slotDuration', slotDuration);
  }, [slotDuration]);

  const handleStartTimeChange = (date) => {
    setStartTime(date);
    if (onSlotMinTime) {
      const formattedTime = date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
      onSlotMinTime(formattedTime);
    }
  };

  const handleEndTimeChange = (date) => {
    setEndTime(date);
    if (onSlotMaxTime) {
      const formattedTime = date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
      onSlotMaxTime(formattedTime);
    }
  };

  const handleSlotDurationChange = (event) => {
    const duration = event.target.value;
    setSlotDuration(duration);
    if (onSlotDurationChange) {
      onSlotDurationChange(duration);
    }
  };

  // Método para limpiar todos los datos almacenados
  useImperativeHandle(ref, () => ({
    clearStoredTimes: () => {
      localStorage.removeItem('slotMinTime');
      localStorage.removeItem('slotMaxTime');
      localStorage.removeItem('slotDuration');
      window.location.reload();
    }
  }));

  return (
    <div style={{ zIndex: 10 }}>
      <div className="row mt-3 mb-1">
        {enabledFields.includes('startTime') && (
          <div className="input-group date col-4">
            <label>{locales_es.fullCalendarStartTime}</label>
            <DatePicker
              className="form-control datePicker"
              selected={startTime}
              onChange={handleStartTimeChange}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption={locales_es.time}
              timeFormat="HH:mm"
              dateFormat="HH:mm"
              minTime={new Date().setHours(0, 0)}
              maxTime={new Date().setHours(23, 59)}
              placeholderText={locales_es.selectStartTime}
              locale={langCode}
            />
          </div>
        )}
        {enabledFields.includes('endTime') && (
          <div className="input-group date col-4">
            <label>{locales_es.fullCalendarEndTime}</label>
            <DatePicker
              className="form-control datePicker"
              selected={endTime}
              onChange={handleEndTimeChange}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption={locales_es.time}
              timeFormat="HH:mm"
              dateFormat="HH:mm"
              minTime={new Date().setHours(0, 0)}
              maxTime={new Date().setHours(23, 59)}
              placeholderText={locales_es.selectEndTime}
              locale={langCode}
            />
          </div>
        )}
        {enabledFields.includes('slotDuration') && (
          <div className="input-group date col-4">
            <label className="clearfix">{locales_es.fullCalendarSlotDuration}</label>
            <select value={slotDuration} onChange={handleSlotDurationChange} className="form-control w-100">
              <option value="00:05:00">5 minutos</option>
              <option value="00:10:00">10 minutos</option>
              <option value="00:15:00">15 minutos</option>
              <option value="00:30:00">30 minutos</option>
              <option value="00:45:00">45 minutos</option>
              <option value="01:00:00">60 minutos</option>
            </select>
          </div>
        )}
      </div>
    </div>
  );
});

export default TimePicker;
