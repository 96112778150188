import React, { useEffect, useCallback, useState } from 'react';

const InfiniteScroll = ({ next, hasMore, loader, endMessage, children }) => {
  const [isFetching, setIsFetching] = useState(false);

  const handleScroll = useCallback(() => {
    const container = document.getElementById('root');
    if (container && hasMore && !isFetching) {
      const { scrollTop, scrollHeight, clientHeight } = container;
      if (scrollTop + clientHeight >= scrollHeight - 50) {
        setIsFetching(true);
        next().finally(() => {
          setIsFetching(false);
        });
      }
    }
  }, [hasMore, next, isFetching]);

  useEffect(() => {
    const container = document.getElementById('root');
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);

  return (
    <div>
      {children}
      {isFetching ? loader : hasMore ? null : endMessage}
    </div>
  );
};

export default InfiniteScroll;
