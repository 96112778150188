import React, {Component} from "react";
import APIService from "../../modules/apiService";
import Helpers from "../../modules/helpers";
import Spinner from "../../components/spinner";
import locales_es from "../../locales/es";
import AuthService from "../../modules/authService";
import {
  HREF_PAGE_HOME,
  USER_TYPE_MEDIC,
} from "../../models/constants";
import DateTimeService from "../../modules/DateTimeService";
import ViewHelpers from "../../modules/viewHelpers";
import Form from "../../components/form";

export default class AddProfessionalRegistrationPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      professionalRegistrationId: props.match.params.id,
      professionalRegistrationsJurisdictions: null,
      professionalRegistrationsTypes: null,
      professionalRegistrationTypeId: null,
      professionalRegistrationJurisdictionId: null,
      number: null,
    };

    this.api = new APIService();
    this.helpers = new Helpers();
    this.auth = new AuthService();
    this.dateTimeService = new DateTimeService();
    this.viewHelpers = new ViewHelpers();
    this.perPageDefault = 5;
  }

  componentDidMount() {
    if (this.auth.getLocalUserType() !== USER_TYPE_MEDIC) {
      window.location.href = HREF_PAGE_HOME;
      return;
    }

    if (this.state.professionalRegistrationId) {
      this.api.getProfessionalRegistration(this.state.professionalRegistrationId).then(res => {
        this.setState({
          professionalRegistrationTypeId: res.data.professional_registration_type_id,
          professionalRegistrationJurisdictionId: res.data.professional_registration_jurisdiction_id,
          number: res.data.number,
        })
      }).catch(err => {
        this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
      })
    }

    this.api.getProfessionalRegistrationsTypes().then(res => {
      this.setState({
        professionalRegistrationsTypes: res.data,
      }, () => {
        this.setState({professionalRegistrationTypeId: res.data[0].id})
      })
    }).catch(err => {
      this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
    });

    this.api.getProfessionalRegistrationJurisdictions().then(res => {
      this.setState({
        professionalRegistrationsJurisdictions: res.data,
      }, () => {
        this.setState({professionalRegistrationJurisdictionId: res.data[0].id})
      })
    }).catch(err => {
      this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
    })

  }

  componentWillUnmount() {
    window.removeEventListener('scroll', () => this.handleScroll())
  }

  handleChange = state => ev => {
    this.setState({[state]: ev.target.value});
  };

  handleDateChange = state => value => {
    this.setState({[state]: value});
  };

  handleReactSelectChange = state => value => {
    this.setState({[state]: value});
  };

  validateForm() {
    let errors = 0;
    if (!this.state.professionalRegistrationTypeId
      || !this.state.professionalRegistrationJurisdictionId
      || !this.state.number
    ) {
      errors += 1;
      this.props.showMainModal(locales_es.errorModal.title, locales_es.errorModal.completeAllFormFields);
    }

    return !errors;
  }

  send() {
    if (this.validateForm()) {
      const objData = {
        professional_registration_type_id: this.state.professionalRegistrationTypeId,
        professional_registration_jurisdiction_id: this.state.professionalRegistrationJurisdictionId,
        number: this.state.number
      };
      if (this.state.professionalRegistrationId) {
        this.api.putProfessionalRegistration(this.state.professionalRegistrationId, objData).then(res => {
          this.props.history.goBack();
          this.props.showMainModal(locales_es.successModal.title, res.message);
        }).catch(err => {
          this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
        })
      } else {
        this.api.postProfessionalRegistration(objData).then(res => {
          this.props.history.goBack();
          this.props.showMainModal(locales_es.successModal.title, res.message);
        }).catch(err => {
          this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
        })
      }
    }
  }

  render() {
    const {professionalRegistrationsJurisdictions, professionalRegistrationsTypes} = this.state;

    const inputs = [
      {
        label: locales_es.professionalRegistrationType,
        placeholder: locales_es.professionalRegistrationType,
        id: 1,
        state: 'professionalRegistrationTypeId',
        value: this.state.professionalRegistrationTypeId,
        type: 'select',
        required: true,
        options: this.state.professionalRegistrationsTypes,
        wrapperCustomClassName: 'form-group col-12 col-md-4 float-left pl-md-0',
      },
      {
        label: locales_es.professionalRegistrationJurisdictions,
        placeholder: locales_es.professionalRegistrationJurisdiction,
        id: 2,
        state: 'professionalRegistrationJurisdictionId',
        value: this.state.professionalRegistrationJurisdictionId,
        type: 'select',
        required: true,
        options: this.state.professionalRegistrationsJurisdictions,
        wrapperCustomClassName: 'form-group col-12 col-md-4 float-left pl-md-0',
      },
      {
        label: locales_es.number,
        placeholder: locales_es.number,
        id: 3,
        state: 'number',
        value: this.state.number,
        type: 'text',
        required: true,
        wrapperCustomClassName: 'form-group col-12 col-md-4 float-left pl-md-0',
      },
    ];

    return (<>
      <div className="kt-container">

        {(professionalRegistrationsJurisdictions === null || professionalRegistrationsTypes === null) ? <Spinner/> :
          professionalRegistrationsTypes && professionalRegistrationsTypes.length ?
            <div className="kt-grid kt-grid--desktop kt-grid--ver kt-grid--ver-desktop kt-app">
              <div className="kt-grid__item kt-grid__item--fluid kt-app__content">
                <Form
                  styles="kt-form"
                  inputs={inputs}
                  handleChange={this.handleChange}
                  handleDateChange={this.handleDateChange}
                  handleReactSelectChange={this.handleReactSelectChange}
                  onSubmit={() => this.send()}
                  onSubmitButtonText={locales_es.send}
                  secondaryButtonText={locales_es.cancel}
                  onClickSecondaryButton={() => {
                    this.props.history.goBack()
                  }}
                  showTerms={false}
                  wrapper={true}
                >
                  <div className="kt-portlet__head">
                    <div className="kt-portlet__head-label">
                      <h3 className="kt-portlet__head-title">{this.state.professionalRegistrationId ? location.editProfessionalRegistration : locales_es.addProfessionalRegistration}</h3>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
            :
            <h1>{locales_es.anErrorHasOcurred}</h1>
        }

      </div>
    </>)
  }
}
