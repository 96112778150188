import React from 'react';
import MedicineItem from '../medicineItem';
import locales_es from "../../locales/es";
import MedicineStorageService from "../../modules/MedicineStorageService";

class MedicineStorage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { storageMedicines: [] };
    this.medicineStorageService = new MedicineStorageService();
  }

  componentDidMount() {
    const loadedMedicines = this.medicineStorageService.loadMedicines();
    this.setState({ storageMedicines: loadedMedicines });
  }

  clearStorage = () => {
    this.medicineStorageService.clearStorage();
    this.setState({ storageMedicines: [] });
  };

  removeMedicine = (index) => {
    this.medicineStorageService.removeMedicine(index);
    this.setState({ storageMedicines: this.medicineStorageService.loadMedicines() });
  };

  onSelectPresentation = (medicine, presentation) => {
    this.props.onSelectPresentation && this.props.onSelectPresentation(medicine, presentation);
  };

  render() {
    return (
      <>
        {this.state.storageMedicines.length ? (
          <div className="kt-portlet kt-portlet--solid-metal">
            <div className="kt-portlet__head">
              <div className="kt-portlet__head-label">
                <h3 className="kt-portlet__head-title">
                  <i className="la la-download"></i> Últimos medicamentos usados...
                </h3>
              </div>
            </div>
            <div className="kt-portlet__body">
              {this.state.storageMedicines.map((medication, index) => (
                <MedicineItem
                  key={index}
                  medication={medication}
                  onRemoveItem={() => this.removeMedicine(index)}
                  onSelectPresentation={(medication, presentation) => this.onSelectPresentation(medication, presentation)}
                />
              ))}
              <div className="text-center p-2">
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    this.clearStorage();
                  }}
                  className="kt-link kt-font-bold"
                >
                  {locales_es.cleanResults}
                </a>
              </div>
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

export default MedicineStorage;
