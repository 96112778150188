import React, {useState, useEffect} from 'react';
import {
  CSSTransition,
  TransitionGroup,
} from 'react-transition-group';
import './styles.css';
import {MED_ICON_BASE_64} from "../../../../models/constants";
import TransitionImage from '../../../../components/TransitionImage/TransitionImage';
import CardItemCover from "../../../../components/CardItemCover/CardItemCover";

const Card = ({
                plan,
                cardNumber,
                isCardFlipped,
                currentFocusedElm,
                onCardElementClick,
                cardNumberRef,
                planRef,
                logo,
                medicalInsuranceName,
                bgColor,
                textColor,
              }) => {
  const [style, setStyle] = useState(null);

  const outlineElementStyle = (element) => {
    return element
      ? {
        width: `${element.offsetWidth}px`,
        height: `${element.offsetHeight}px`,
        transform: `translateX(${element.offsetLeft}px) translateY(${element.offsetTop}px)`
      }
      : null;
  };

  useEffect(() => {
    if (currentFocusedElm) {
      const style = outlineElementStyle(currentFocusedElm.current);
      setStyle(style);
    }
  }, [currentFocusedElm]);

  const maskCardNumber = (cardNumber) => {
    let cardNumberArr = cardNumber.split('');
    /*
    cardNumberArr.forEach((val, index) => {
        if (index > 4 && index < 14) {
            if (cardNumberArr[index] !== ' ') {
                cardNumberArr[index] = '*';
            }
        }
    });*/

    return cardNumberArr;
  };

  return (
    <div className={'card-item ' + (isCardFlipped ? '-active' : '')}>
      <div className="card-item__side -front">
        <div
          className={`card-item__focus ${
            currentFocusedElm ? `-active` : ``
          }`}
          style={style}
        />
        <CardItemCover {...{bgColor, textColor}} />

        <div className="card-item__wrapper">
          <div className="card-item__top">
            <div className="card-item__type">
              <TransitionImage src={logo || MED_ICON_BASE_64} className="card-item__typeImg" />
            </div>
          </div>

          <label
            className="card-item__number"
            style={{color: textColor}}
            ref={cardNumberRef}
            onClick={() => onCardElementClick('cardNumber')}
          >
            <TransitionGroup
              className="slide-fade-up"
              component="div"
            >
              {cardNumber ? (
                maskCardNumber(cardNumber).map((val, index) => (
                  <CSSTransition
                    classNames="slide-fade-up"
                    timeout={250}
                    key={index}
                  >
                    <div className="card-item__numberItem">
                      {val}
                    </div>
                  </CSSTransition>
                ))
              ) : (
                <CSSTransition
                  classNames="slide-fade-up"
                  timeout={250}
                >
                  <div className="card-item__numberItem">
                    #
                  </div>
                </CSSTransition>
              )}
            </TransitionGroup>
          </label>
          <div className="card-item__content">
            <label
              className="card-item__info"
              style={{color: textColor}}
              onClick={() => onCardElementClick('plan')}
              ref={planRef}
            >
              {medicalInsuranceName && <div className="card-item__holder">{medicalInsuranceName}</div>}
              <div className="card-item__name">
                <TransitionGroup
                  component="div"
                  className="slide-fade-up"
                >
                  {plan === 'FULL NAME' ? (
                    <CSSTransition
                      classNames="slide-fade-up"
                      timeout={250}
                    >
                      <div>FULL NAME</div>
                    </CSSTransition>
                  ) : (
                    plan
                      .split('')
                      .map((val, index) => (
                        <CSSTransition
                          timeout={250}
                          classNames="slide-fade-right"
                          key={index}
                        >
                          <span className="card-item__nameItem">
                              {val}
                          </span>
                        </CSSTransition>
                      ))
                  )}
                </TransitionGroup>
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
