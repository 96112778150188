import React, {Component} from 'react';
import locales_es from "../../locales/es";
import {
  PAYMENT_METHOD_AMOUNT_TYPE_FIXED,
  PAYMENT_METHOD_AMOUNT_TYPE_PERCENT, USER_TYPE_SECRETARY,
} from "../../models/constants";
import AuthService from "../../modules/authService";
import APIService from "../../modules/apiService";
import Spinner from "../spinner";

export default class PricesTable extends Component {

  constructor(props) {
    super(props);

    this.state = {
      userData: null,
      enabledBeforePayment: null,
    }

    this.authService = new AuthService();
    this.api = new APIService();
  }


  componentDidMount() {
    this.authService.getRemoteUserData()
      .then(res => {
        this.setState({userData: res.data.user});
      }).catch(err => {
      console.log(err);
    });

    if (this.props.medic) {
      this.api.getMedicById(this.props.medic.id).then(res => {
        if (res && res.data) {
          this.setState({
            enabledBeforePayment: res.data.enable_before_payment
          })
        }
      }).catch(err => {
        console.log(err);
      });
    }
  }

  render() {
    const {prices, onRemove, onEdit} = this.props;
    const {userData, enabledBeforePayment} = this.state;

    return (
      <div className="kt-section">
        <div className="kt-section__content">
          <table className="table table-head-noborder table-responsive">
            <thead>
            <tr>
              <th>{locales_es.price}</th>
              <th>{locales_es.title}</th>
              <th className="d-none d-md-table-cell">{locales_es.description}</th>
              {enabledBeforePayment === null ? null : enabledBeforePayment ? <th>{locales_es.charge}</th> : null}
              <th>{locales_es.actions}</th>
            </tr>
            </thead>
            <tbody>
            {prices && prices.length ?
              prices.map((p, i) =>
                <tr key={'price-table-row-' + i}>
                  <th className="align-middle">{locales_es.currency_sign}{p.price} ({(p.currency_id).toUpperCase()})
                  </th>
                  <td className="align-middle">{p.title}</td>
                  <td className="align-middle d-none d-md-table-cell">{p.description}</td>
                  {enabledBeforePayment === null ? null : enabledBeforePayment ?
                    <td
                      className="align-middle">{locales_es.before_payment_type[p.before_payment_type]} {p.before_payment_amount || 'Sin configurar'}{p.before_payment_type === PAYMENT_METHOD_AMOUNT_TYPE_FIXED ? (' (' + p.currency_id + ') ') : p.before_payment_type === PAYMENT_METHOD_AMOUNT_TYPE_PERCENT ? '%' : ''}</td>
                    : null}
                  <td className="align-middle">
                    <button onClick={() => onEdit(p, i)} type="button"
                            className="btn btn-sm btn-focus btn-elevate btn-pill btn-brand m-1">
                      <i className="flaticon-edit p-0"/>
                    </button>
                    <button onClick={() => onRemove(p, i)} type="button"
                            className="btn btn-sm btn-focus btn-elevate btn-pill btn-danger m-1">
                      <i className="flaticon-delete p-0"/>
                    </button>
                  </td>
                </tr>
              )
              : null}
            </tbody>
          </table>
          {userData && userData.user_type === USER_TYPE_SECRETARY ?
            enabledBeforePayment === null ? <Spinner/> :
              enabledBeforePayment ?
                <div class="alert alert-success" role="alert">
                  <div class="alert-text">El médico ha activado el cobro online</div>
                </div>
                :
                <div class="alert alert-warning" role="alert">
                  <div class="alert-text">El médico no ha activado el cobro online. Si quieres activarlo, pídale que lo
                    active desde su cuenta en la pantalla Configuración > Medios de Pago.
                  </div>
                </div>
            : null}
        </div>
      </div>
    )
  }
}
