import React, {useState, useRef, useCallback, useEffect} from 'react';
import CForm from './components/form';
import Card from './components/card';
import locales_es from "../../locales/es";
import APIService from "../../modules/apiService";
import Select from 'react-select'
import {HREF_PAGE_MY_INSURANCES, USER_TYPE_PATIENT} from "../../models/constants";
// import Input from "../../components/formInput";
import Helpers from "../../modules/helpers";
import AuthService from "../../modules/authService";

const initialState = {
  cardNumber: '#### #### #### ####',
  plan: 'Su plan médico',
  isCardFlipped: false
};

const AddMedicalInsurace = (props) => {
  const api = new APIService();
  const auth = new AuthService();
  const helpers = new Helpers();
  const [state, setState] = useState(initialState);
  const [currentFocusedElm, setCurrentFocusedElm] = useState(null);
  const [medicalInsurances, setMedicalInsurances] = useState([{value: 0, label: locales_es.loading}]);
  const [medicalInsurance, setMedicalInsurance] = useState(null);
  const medicalInsuranceId = props.match.params.medical_insurance_id;
  const patientId = props.match.params.patient_id;


  const updateStateValues = useCallback(
    (keyName, value) => {
      setState({
        ...state,
        [keyName]: value || initialState[keyName]
      });
    },
    [state]
  );

  // References for the Form Inputs used to focus corresponding inputs.
  let formFieldsRefObj = {
    cardNumber: useRef(),
    plan: useRef(),
    cardDate: useRef(),
  };

  let focusFormFieldByKey = useCallback((key) => {
    formFieldsRefObj[key].current.focus();
  });

  // This are the references for the Card DIV elements.
  let cardElementsRef = {
    cardNumber: useRef(),
    plan: useRef(),
    cardDate: useRef()
  };

  let onCardFormInputFocus = (_event, inputName) => {
    const refByName = cardElementsRef[inputName];
    setCurrentFocusedElm(refByName);
  };

  let onCardInputBlur = useCallback(() => {
    setCurrentFocusedElm(null);
  }, []);

  const onSend = () => {
    const medicalInsuranceNumber = formFieldsRefObj.cardNumber.current.value;
    const medicalInsurancePlan = formFieldsRefObj.plan.current.value;
    if(!medicalInsurance || !medicalInsuranceNumber || !medicalInsurancePlan) {
      props.showMainModal(locales_es.errorModal.title, locales_es.errorModal.completeAllFormFields);
      return;
    }

    const objData = {
      "patient_id": patientId,
      "medical_insurance_id": medicalInsurance.id,
      "plan": medicalInsurancePlan,
      "number": medicalInsuranceNumber,
    };

    const handleGoBackAndReload = () => {
      props.history.goBack();
      document.dispatchEvent(new Event('reload'));
    };

    if (medicalInsuranceId) {
      api.putMedicalInsurancePatient(medicalInsuranceId, objData).then(res => {
        auth.getLocalUserType() !== USER_TYPE_PATIENT ? handleGoBackAndReload() : props.history.push(`${HREF_PAGE_MY_INSURANCES}`);
        auth.getLocalUserType() === USER_TYPE_PATIENT && props.showMainModal(locales_es.successModal.title, res.message);
      }).catch(err => {
        props.showMainModal(locales_es.errorModal.title, helpers.getErrorMsg(err));
      })
    } else if (patientId) {
      api.postMedicalInsurancesPatients(objData).then(res => {
        props.showMainModal(locales_es.successModal.title, res.message);
        handleGoBackAndReload();
      }).catch(err => {
        props.showMainModal(locales_es.errorModal.title, helpers.getErrorMsg(err));
      })
    } else {
      delete objData.patient_id; // safe delete
      api.postMyMedicalInsurancesPatients(objData).then(res => {
        props.showMainModal(locales_es.successModal.title, res.message);
        props.history.push(`${HREF_PAGE_MY_INSURANCES}`);
      }).catch(err => {
        props.showMainModal(locales_es.errorModal.title, helpers.getErrorMsg(err));
      })
    }
  };

  useEffect(() => {
    api.getMedicalInsurances().then(res => {
      setMedicalInsurances(res.data.map(insurance => {
        return {
          id: insurance.id,
          value: insurance.id,
          label: insurance.name,
          logo: insurance.full_logo_image,
          bgColor: insurance.background_color,
          textColor: insurance.text_color,
        }
      }));

      if (medicalInsuranceId) {
        api.getMedicalInsuranceByInsuranceId(medicalInsuranceId).then(res => {
          setMedicalInsurance({
            id: res.data.medical_insurance?.id,
            value: res.data.medical_insurance?.id,
            label: res.data.medical_insurance?.name,
            logo: res.data.medical_insurance?.full_logo_image,
            bgColor: res.data.medical_insurance?.background_color,
            textColor: res.data.medical_insurance?.text_color,
          });
          setState({
            cardNumber: res.data.number,
            plan: res.data.plan,
            isCardFlipped: false
          });
          formFieldsRefObj.cardNumber.current.value = res.data.number;
          formFieldsRefObj.plan.current.value = res.data.plan;
        }).catch(err => {
          props.showMainModal(locales_es.errorModal.title, helpers.getErrorMsg(err));
        })
      }

    }).catch(err => {
      props.showMainModal(locales_es.errorModal.title, helpers.getErrorMsg(err));
    })
  }, []);

  return (
    <div className="card-wrapper">
      <div className="card-form">
        <CForm
          onUpdateState={updateStateValues}
          cardNumberRef={formFieldsRefObj.cardNumber}
          planRef={formFieldsRefObj.plan}
          cardDateRef={formFieldsRefObj.cardDate}
          onCardInputFocus={onCardFormInputFocus}
          onCardInputBlur={onCardInputBlur}
        >
          <>
            <Card
              cardNumber={state.cardNumber}
              plan={state.plan}
              isCardFlipped={state.isCardFlipped}
              currentFocusedElm={currentFocusedElm}
              onCardElementClick={focusFormFieldByKey}
              cardNumberRef={cardElementsRef.cardNumber}
              planRef={cardElementsRef.plan}
              logo={medicalInsurance?.logo}
              medicalInsuranceName={medicalInsurance?.label}
              bgColor={medicalInsurance?.bgColor}
              textColor={medicalInsurance?.textColor}
            />
            <div className="card-input mt-5">
              <label className="card-input__label">{locales_es.medicalInsurance}</label><br/>
              <Select
                placeholder={locales_es.selectMedicalInsurance}
                options={medicalInsurances}
                classNamePrefix="select"
                value={medicalInsurance}
                isLoading={false}
                isClearable={false}
                isSearchable={true}
                onChange={(state) => {
                  setMedicalInsurance(state);
                  const event = new CustomEvent('checkPreloadedInputs', {bubbles: true});
                  document.dispatchEvent(event);
                }}
              />
            </div>
            {/*<div className="card-input">
              <Input
                className="card-input__input"
                value={medicalInsurancePlan}
                label={locales_es.medicalInsurancePlan}
                placeholder={locales_es.medicalInsurancePlan}
                type="text"
                handleChange={(ev) => {
                  setMedicalInsurancePlan(ev.target.value);
                  console.log(ev.target.value);
                }}
              />
            </div>*/}
          </>
        </CForm>
        <div className="text-center mt-5">
          <a onClick={onSend} className="btn btn-brand btn-sm btn-bold btn-upper text-white">
            {locales_es.save}
          </a>
        </div>
      </div>
    </div>
  );
};

export default AddMedicalInsurace;
