import React, {Component} from 'react';
import locales_es from "../../locales/es";
import {
  HREF_PAGE_ADD_MEDICAL_INSURANCE,
} from "../../models/constants";
import AuthService from "../../modules/authService";
import {MED_ICON_BASE_64} from "../../models/constants";

export default class InsurancesList extends Component {

  constructor(props) {
    super(props);
    this.auth = new AuthService();
  }

  renderMedicalInsurances() {
    return (
      this.props.medicalInsurances.map(insurance => {
        const editInsuranceURL = `${HREF_PAGE_ADD_MEDICAL_INSURANCE}/${insurance.id}`;
        return (
          <a href={editInsuranceURL} key={`insurance-list-${insurance.id}`}
             className="col-12 col-sm-6 col-md-4 cursor-pointer">
            <div className="kt-portlet">
              <div className="kt-portlet__body"
                   style={{
                     backgroundColor: insurance.medical_insurance?.background_color ?? null,
                   }}>
                <div className="kt-widget kt-widget--general-4" style={insurance.disabled && {opacity: 0.5}}>
                  {insurance.disabled &&
                    <span class="badge badge-pill badge-secondary mb-3 float-right">{locales_es.deactivated}</span>}
                  <div className="kt-widget__head">
                    <div className="kt-media kt-media--lg" style={{
                      backgroundColor: insurance.medical_insurance?.full_logo_image ? 'transparent' : '#2F2E38',
                      padding: 5
                    }}>
                      <img src={insurance.medical_insurance?.full_logo_image || MED_ICON_BASE_64} alt="image" style={
                        {
                          maxWidth: 'initial',
                          width: 'auto',
                          maxHeight: 20,
                          filter: 'blur'
                        }
                      }/>
                    </div>
                    {this.props.removeMedicalInsurance &&
                      <button type="button"
                              onClick={(e) => {
                                e.preventDefault();
                                this.props.removeMedicalInsurance && this.props.removeMedicalInsurance(insurance.id);
                              }}
                              className="btn btn-sm btn-pill btn-danger"><i class="flaticon-delete m-0 p-0"></i>
                      </button>
                    }
                  </div>
                  <a href={editInsuranceURL} className="kt-widget__title"
                     style={{marginBottom: 0, color: insurance.medical_insurance?.text_color,}}>
                    {insurance.medical_insurance?.name || locales_es.noInsurancePlanName}
                  </a><br/>
                  <span className="kt-widget__desc"
                        style={{color: insurance.medical_insurance?.text_color}}>{locales_es.medicalInsurancePlan}: {insurance.plan}</span>
                  <br/>
                  {insurance.number &&
                    <div className="kt-widget__desc" style={{color: insurance.medical_insurance?.text_color}}>
                      {locales_es.medicalInsuranceNumber}: {insurance.number}
                    </div>
                  }
                </div>
              </div>
            </div>
          </a>
        )
      })
    )
  }

  render() {
    return (
      <div className="kt-grid__item kt-grid__item--fluid kt-app__content">
        <div className="row">
          {this.renderMedicalInsurances()}
        </div>
      </div>
    )
  }
}
